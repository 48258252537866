import React from "react";
import s from "./BenefitNewSkill.module.scss";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const BenefitNewSkill = () => {
  const { t } = useTranslation(["home"]);
  const navigate = useNavigate();
  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <div className={s.title}>{t("home:BENEFIT_NEW_SKILLS")}</div>
        <div className={s.text}>{t("home:EDUCATION_MEANING")}</div>
        <Box>
          <Button
            sx={{ padding: "8px 22px", borderRadius: "4px" }}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => navigate("/freeCourseCatalogue")}
          >
            <Typography
              sx={{
                fontWeight: 400,
                lineHeight: "28px",
              }}
            >
              {t("home:GO_CATALOG")}
            </Typography>
          </Button>
        </Box>
      </div>
      <div className={s.block}>
        <div className={[s.text, s.helper].join(" ")}>
          {t("home:INCREASED_COMPETITIVENESS")}
        </div>
        <div className={[s.text1, s.helper].join(" ")}>
          {t("home:SELF_DEVELOPEMENT")}
        </div>
        <div className={[s.text2, s.helper].join(" ")}>
          {t("home:PERSONAL_SATISFACTION")}
        </div>
        <div className={[s.text3, s.helper].join(" ")}>
          {" "}
          {t("home:ACHIEVEMENTS_VICTORY")}
        </div>
        <div className={[s.text4, s.helper].join(" ")}>
          {t("home:DEVELOPMENT_INTELLINGENCE")}
        </div>
        <div className={[s.text5, s.helper].join(" ")}>
          {t("home:EXPAND_HORIZON")}
        </div>
        <div className={[s.text6, s.helper].join(" ")}>
          {t("home:HIGH_INCOME")}
        </div>
        <div className={[s.text7, s.helper].join(" ")}>
          {" "}
          {t("home:SELF_CONFIDENCE")}
        </div>
        <div className={[s.text8, s.helper].join(" ")}>
          {t("home:CAREER_PROSPECTS")}
        </div>
      </div>
    </div>
  );
};

export default BenefitNewSkill;
