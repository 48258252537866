import * as yup from "yup";
const integerValidation = (value?: string | number) => {
  const regex = /^\d+$/; // Only digits allowed, no decimal points
  if (value || value === 0) return regex.test(value.toString());
  else return false;
};

export const validationSchemaCommonEdu = (t: any) =>
  yup.object({
    langId: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    // capacity: yup
    //   .number()
    //   .nullable()
    //   .integer(t("common:validation.INTEGER_ONLY"))
    //   .positive(t("common:validation.ENTER_POSITIVE_NUMBER"))
    //   .required(t("common:validation.MANDATORY_FIELD")),
    contentAccessExpiration: yup
      .string()
      .nullable()
      .when("canStudentViewAfterEnd", {
        is: true,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
  });

export const validationSchemaTaskAndAssesmentsEdu = (t: any) =>
  yup.object({
    gradingAccuracy: yup
      .number()
      .nullable()
      .min(0, t("common:validation.ENTER_POSITIVE_NUMBER"))
      .test("integer", t("common:validation.INTEGER_ONLY"), (value) => {
        if (value || value === 0) return integerValidation(value);
        else return false;
      }),
    maxFileSizeCanUploadStudent: yup
      .number()
      .integer(t("common:validation.INTEGER_ONLY"))
      .positive(t("common:validation.ENTER_POSITIVE_NUMBER")),
    zeroMarkForIncompleteTasks: yup.boolean(),
    markForIncompleteTask: yup.number().when("zeroMarkForIncompleteTasks", {
      is: false,
      then: yup
        .number()
        .integer(t("common:validation.INTEGER_ONLY"))
        .nullable()
        .min(0, t("common:validation.ENTER_POSITIVE_NUMBER"))
        .required(t("common:validation.MANDATORY_FIELD")),
      otherwise: yup.number().nullable(),
    }),
  });

export const validationSchemaCommonFree = (t: any) =>
  yup.object({
    langId: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    formatType: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    durationType: yup
      .string()
      .nullable()
      .required(t("common:validation.MANDATORY_FIELD")),
    contentAccessExpiration: yup
      .string()
      .nullable()
      .when("canStudentViewAfterEnd", {
        is: true,
        then: yup
          .string()
          .nullable()
          .required(t("common:validation.MANDATORY_FIELD")),
      }),
    hours: yup
      .number()
      .integer(t("common:validation.INTEGER_ONLY"))
      .nullable()
      .test("hours", t("common:validation.MANDATORY_FIELD"), function (hours) {
        const { isProvidedCertificate, credits } = this.parent;
        return (
          (isProvidedCertificate && (hours || credits)) ||
          !isProvidedCertificate
        );
      }),
    credits: yup
      .number()
      .integer(t("common:validation.INTEGER_ONLY"))
      .nullable()
      .test(
        "credits",
        t("common:validation.MANDATORY_FIELD"),
        function (credits) {
          const { isProvidedCertificate, hours } = this.parent;
          return (
            (isProvidedCertificate && (hours || credits)) ||
            !isProvidedCertificate
          );
        }
      ),
  });

export const validationSchemaTaskAndAssesmentsFree = (t: any) => {
  yup.object({
    maxFileSizeCanUploadStudent: yup
      .number()
      .integer(t("common:validation.INTEGER_ONLY"))
      .positive(t("common:validation.ENTER_POSITIVE_NUMBER")),
  });
};
