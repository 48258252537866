import { useLocation, Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { MenuList, MenuItem, Box, useTheme } from "@mui/material";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import { useTranslation } from "react-i18next";
// import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import { StateSchema } from "@store/StateSchema";
import { Role } from "@interfaces/index";

const MY_COURSES_ROLE_LIST = ["EDUCATOR", "LISTENER", "MANAGER"];
const ADMIN_LIST = ["ADMIN", "LOCAL_ADMIN"];

const SideMenu = () => {
  const location = useLocation();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["sideMenu"]);
  const profile = useSelector(
    (store: StateSchema) => store.account.profileInfo
  );
  const { role, orgId } = profile.authorityInfo;

  return (
    <MenuList
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { xl: 1.2, lg: 1.2, md: 1.2, sm: 0.8, xs: 0.8 },
        pt: 8,
        px: { xl: 3, lg: 3, md: 3, sm: 2, xs: 2 },
        background: common.fontWhite,
        position: "sticky",
        top: 0,
        borderRight: `1px solid ${common.borderSecondary}`,
        height: "100%",
      }}
    >
      <RouterLink to={"/profile"} style={{ textDecoration: "none" }}>
        <MenuItem
          sx={{
            background:
              location.pathname === "/profile" ? common.primaryColor : "#FFF",
            borderRadius: "10px",
            color:
              location.pathname === "/profile"
                ? common.fontWhite
                : common.fontPrimary,
            fontWeight: 500,
            "&:hover": {
              color: common.primaryColor,
              backgroundColor: common.secondaryColor,
            },
          }}
        >
          <AccountCircleOutlinedIcon sx={{ mr: 0.8 }} />
          {`${t("PROFILE")}`}
        </MenuItem>
      </RouterLink>
      {role === Role.LOCAL_ADMIN && (
        <RouterLink to={`/aboutOrg`} style={{ textDecoration: "none" }}>
          <MenuItem
            sx={{
              background: location.pathname.includes("aboutOrg")
                ? common.primaryColor
                : "#FFF",
              borderRadius: "10px",
              color: location.pathname.includes("aboutOrg")
                ? common.fontWhite
                : common.fontPrimary,
              fontWeight: 500,
              "&:hover": {
                color: common.primaryColor,
                backgroundColor: common.secondaryColor,
              },
            }}
          >
            <BusinessRoundedIcon sx={{ mr: 0.8 }} />
            {`${t("ABOUT_ORGANIZATION")}`}
          </MenuItem>
        </RouterLink>
      )}
      {role === Role.LOCAL_ADMIN && (
        <RouterLink to={`/reports`} style={{ textDecoration: "none" }}>
          <MenuItem
            sx={{
              background: location.pathname.includes("reports")
                ? common.primaryColor
                : "#FFF",
              borderRadius: "10px",
              color: location.pathname.includes("reports")
                ? common.fontWhite
                : common.fontPrimary,
              fontWeight: 500,
              "&:hover": {
                color: common.primaryColor,
                backgroundColor: common.secondaryColor,
              },
            }}
          >
            <DataSaverOffOutlinedIcon sx={{ mr: 0.8 }} />
            {`${t("REPORTS")}`}
          </MenuItem>
        </RouterLink>
      )}
      {role && ADMIN_LIST.includes(role) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xl: 1.2, lg: 1.2, md: 1.2, sm: 0.8, xs: 0.8 },
          }}
        >
          <RouterLink to={"/users"} style={{ textDecoration: "none" }}>
            <MenuItem
              sx={{
                background: location.pathname.includes("users")
                  ? common.primaryColor
                  : "#FFF",
                borderRadius: "10px",
                color: location.pathname.includes("users")
                  ? common.fontWhite
                  : common.fontPrimary,
                fontWeight: 500,
                "&:hover": {
                  color: common.primaryColor,
                  backgroundColor: common.secondaryColor,
                },
              }}
            >
              <PeopleAltOutlinedIcon sx={{ mr: 0.8 }} />
              {t("USERS")}
            </MenuItem>
          </RouterLink>
          {role === Role.LOCAL_ADMIN && (
            <RouterLink to={`/settings`} style={{ textDecoration: "none" }}>
              <MenuItem
                sx={{
                  background: location.pathname.includes("settings")
                    ? common.primaryColor
                    : "#FFF",
                  borderRadius: "10px",
                  color: location.pathname.includes("settings")
                    ? common.fontWhite
                    : common.fontPrimary,
                  fontWeight: 500,
                  "&:hover": {
                    color: common.primaryColor,
                    backgroundColor: common.secondaryColor,
                  },
                }}
              >
                <SettingsOutlinedIcon sx={{ mr: 0.8 }} />
                {`${t("SETTINGS")}`}
              </MenuItem>
            </RouterLink>
          )}
          <RouterLink to={`/courses`} style={{ textDecoration: "none" }}>
            <MenuItem
              sx={{
                background:
                  location.pathname.includes("courses") ||
                    location.pathname.includes("myCourses")
                    ? common.primaryColor
                    : "#FFF",
                borderRadius: "10px",
                color:
                  location.pathname.includes("courses") ||
                    location.pathname.includes("myCourses")
                    ? common.fontWhite
                    : common.fontPrimary,
                fontWeight: 500,
                "&:hover": {
                  color: common.primaryColor,
                  backgroundColor: common.secondaryColor,
                },
              }}
            >
              <AutoStoriesOutlinedIcon sx={{ mr: 0.8 }} />
              {`${t("COURSES")}`}
            </MenuItem>
          </RouterLink>
        </Box>
      )}
      {role === Role.SUPER_ADMIN && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xl: 1.2, lg: 1.2, md: 1.2, sm: 0.8, xs: 0.8 },
          }}
        >
          <RouterLink
            to={"/generalSettings"}
            style={{ textDecoration: "none" }}
          >
            <MenuItem
              sx={{
                background:
                  location.pathname === "/generalSettings"
                    ? common.primaryColor
                    : "#FFF",
                borderRadius: "10px",
                color:
                  location.pathname === "/generalSettings"
                    ? common.fontWhite
                    : common.fontPrimary,
                fontWeight: 500,
                "&:hover": {
                  color: common.primaryColor,
                  backgroundColor: common.secondaryColor,
                },
              }}
            >
              <InfoOutlinedIcon sx={{ mr: 0.8 }} />
              {t("COMMON_SETTINGS")}
            </MenuItem>
          </RouterLink>
          <RouterLink
            to={"/assessmentSystem"}
            style={{ textDecoration: "none" }}
          >
            <MenuItem
              sx={{
                background:
                  location.pathname === "/assessmentSystem"
                    ? common.primaryColor
                    : "#FFF",
                borderRadius: "10px",
                color:
                  location.pathname === "/assessmentSystem"
                    ? common.fontWhite
                    : common.fontPrimary,
                fontWeight: 500,
                "&:hover": {
                  color: common.primaryColor,
                  backgroundColor: common.secondaryColor,
                },
              }}
            >
              <InfoOutlinedIcon sx={{ mr: 0.8 }} />
              {t("ASSESSMENT_SYSTEM")}
            </MenuItem>
          </RouterLink>
          <RouterLink to={"/actionsJournal"} style={{ textDecoration: "none" }}>
            <MenuItem
              sx={{
                background:
                  location.pathname === "/actionsJournal"
                    ? common.primaryColor
                    : "#FFF",
                borderRadius: "10px",
                color:
                  location.pathname === "/actionsJournal"
                    ? common.fontWhite
                    : common.fontPrimary,
                fontWeight: 500,
                "&:hover": {
                  color: common.primaryColor,
                  backgroundColor: common.secondaryColor,
                },
              }}
            >
              <InfoOutlinedIcon sx={{ mr: 0.8 }} />
              {t("ACTIONS_LOG")}
            </MenuItem>
          </RouterLink>
          <RouterLink to={"/rolesAndRights"} style={{ textDecoration: "none" }}>
            <MenuItem
              sx={{
                background:
                  location.pathname === "/rolesAndRights"
                    ? common.primaryColor
                    : "#FFF",
                borderRadius: "10px",
                color:
                  location.pathname === "/rolesAndRights"
                    ? common.fontWhite
                    : common.fontPrimary,
                fontWeight: 500,
                "&:hover": {
                  color: common.primaryColor,
                  backgroundColor: common.secondaryColor,
                },
              }}
            >
              <InfoOutlinedIcon sx={{ mr: 0.8 }} />
              {t("ROLES_AND_RIGHTS")}
            </MenuItem>
          </RouterLink>
          <RouterLink to={"/dict"} style={{ textDecoration: "none" }}>
            <MenuItem
              sx={{
                background: location.pathname.includes("dict")
                  ? common.primaryColor
                  : "#FFF",
                borderRadius: "10px",
                color: location.pathname.includes("dict")
                  ? common.fontWhite
                  : common.fontPrimary,
                fontWeight: 500,
                "&:hover": {
                  color: common.primaryColor,
                  backgroundColor: common.secondaryColor,
                },
              }}
            >
              <InfoOutlinedIcon sx={{ mr: 0.8 }} />
              {t("DICTIONARY")}
            </MenuItem>
          </RouterLink>
          <RouterLink to={"/integrations"} style={{ textDecoration: "none" }}>
            <MenuItem
              sx={{
                background:
                  location.pathname === "/integrations"
                    ? common.primaryColor
                    : "#FFF",
                borderRadius: "10px",
                color:
                  location.pathname === "/integrations"
                    ? common.fontWhite
                    : common.fontPrimary,
                fontWeight: 500,
                "&:hover": {
                  color: common.primaryColor,
                  backgroundColor: common.secondaryColor,
                },
              }}
            >
              <InfoOutlinedIcon sx={{ mr: 0.8 }} />
              {t("sideMenu:INTEGRATIONS")}
            </MenuItem>
          </RouterLink>
          <RouterLink to={"/license"} style={{ textDecoration: "none" }}>
            <MenuItem
              sx={{
                background: location.pathname.includes("license")
                  ? common.primaryColor
                  : "#FFF",
                borderRadius: "10px",
                color: location.pathname.includes("license")
                  ? common.fontWhite
                  : common.fontPrimary,
                fontWeight: 500,
                "&:hover": {
                  color: common.primaryColor,
                  backgroundColor: common.secondaryColor,
                },
              }}
            >
              <PendingActionsOutlinedIcon sx={{ mr: 0.8 }} />
              {`${t("LICENSES")}`}
            </MenuItem>
          </RouterLink>
        </Box>
      )}
      {role && MY_COURSES_ROLE_LIST.includes(role) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xl: 1.2, lg: 1.2, md: 1.2, sm: 0.8, xs: 0.8 },
          }}
        >
          <RouterLink to={"/myCourses"} style={{ textDecoration: "none" }}>
            <MenuItem
              sx={{
                background: location.pathname.includes("myCourses")
                  ? common.primaryColor
                  : "#FFF",
                borderRadius: "10px",
                color: location.pathname.includes("myCourses")
                  ? common.fontWhite
                  : common.fontPrimary,
                fontWeight: 500,
                "&:hover": {
                  color: common.primaryColor,
                  backgroundColor: common.secondaryColor,
                },
              }}
            >
              <SchoolOutlinedIcon sx={{ mr: 0.8 }} />
              {`${t("MY_COURSES")}`}
            </MenuItem>
          </RouterLink>
          <RouterLink to={"/eventCalendar"} style={{ textDecoration: "none" }}>
            <MenuItem
              sx={{
                background: location.pathname.includes("eventCalendar")
                  ? common.primaryColor
                  : "#FFF",
                borderRadius: "10px",
                color: location.pathname.includes("eventCalendar")
                  ? common.fontWhite
                  : common.fontPrimary,
                fontWeight: 500,
                "&:hover": {
                  color: common.primaryColor,
                  backgroundColor: common.secondaryColor,
                },
              }}
            >
              <CalendarMonthRoundedIcon sx={{ mr: 0.8 }} />
              {`${t("EVENT_CALENDAR")}`}
            </MenuItem>
          </RouterLink>
        </Box>
      )}
      {(role === Role.LOCAL_ADMIN || role === Role.EDUCATOR) && (
        <RouterLink to={"/bankOfTests"} style={{ textDecoration: "none" }}>
          <MenuItem
            sx={{
              background: location.pathname.includes("bankOfTests")
                ? common.primaryColor
                : "#FFF",
              borderRadius: "10px",
              color: location.pathname.includes("bankOfTests")
                ? common.fontWhite
                : common.fontPrimary,
              fontWeight: 500,
              "&:hover": {
                color: common.primaryColor,
                backgroundColor: common.secondaryColor,
              },
            }}
          >
            <FormatListBulletedIcon sx={{ mr: 0.8 }} />
            {`${t("BANK_OF_TESTS")}`}
          </MenuItem>
        </RouterLink>
      )}
      {role === Role.LISTENER && orgId !== 0 && (
        <RouterLink to={"/courseCatalogue"} style={{ textDecoration: "none" }}>
          <MenuItem
            sx={{
              background: location.pathname.includes("courseCatalogue")
                ? common.primaryColor
                : "#FFF",
              borderRadius: "10px",
              color: location.pathname.includes("courseCatalogue")
                ? common.fontWhite
                : common.fontPrimary,
              fontWeight: 500,
              "&:hover": {
                color: common.primaryColor,
                backgroundColor: common.secondaryColor,
              },
            }}
          >
            <AutoStoriesOutlinedIcon sx={{ mr: 0.8 }} />
            {`${t("COURSE_CATALOG")}`}
          </MenuItem>
        </RouterLink>
      )}
      {role === Role.LISTENER && (
        <RouterLink to={"/achievements"} style={{ textDecoration: "none" }}>
          <MenuItem
            sx={{
              background: (location.pathname.includes("achievements") || location.pathname.includes("certificate"))
                ? common.primaryColor
                : "#FFF",
              borderRadius: "10px",
              color: (location.pathname.includes("achievements") || location.pathname.includes("certificate"))
                ? common.fontWhite
                : common.fontPrimary,
              fontWeight: 500,
              "&:hover": {
                color: common.primaryColor,
                backgroundColor: common.secondaryColor,
              },
            }}
          >
            <NewspaperOutlinedIcon sx={{ mr: 0.8 }} />
            {`${t("ACHIEVEMENTS")}`}
          </MenuItem>
        </RouterLink>
      )}
    </MenuList>
  );
};

export default SideMenu;
