import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Checkbox } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useTheme } from "@mui/material/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import { useTranslation } from "react-i18next";

import { serviceTask } from "@services/task";
import { downloadFile, getFileType } from "@utils/functions";
import { CourseContext } from "@context/CourseContext";
import UploadButton from "@ui/UploadButton";
import { AuthorRole, IMaterial } from "@interfaces/index";
import { materialInitialDto } from "../models";
import { PDFIcon } from '@assets/svg_files/Icons';
import FileViewerModal from "@components/modals/FileViewerModal";
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";

interface Props {
  materials: Array<IMaterial>;
  setMaterials: (list: Array<IMaterial>) => void;
  setMessage: any;
  setMessageOpen: any;
  setMessageType: any;
  disabled?: boolean;
}

const MaterialList = ({ materials, setMaterials, setMessage, setMessageOpen, setMessageType, disabled }: Props) => {
  const { taskId } = useParams();
  const { common } = useTheme().palette;
  const { t } = useTranslation(['common', 'course']);
  const [itemId, setItemId] = useState<string | number | null | undefined>(null);
  const [material, setMaterial] = useState<IMaterial>(materialInitialDto);
  const { courseData } = useContext(CourseContext);
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const isCoAuthor = courseData?.authors.filter((author) => author.authorRole === AuthorRole.COAUTHOR).some((author) => author.userId === courseData?.userId)


  const saveMaterial = (uploadedFileId: string | null, name?: string) => {
    let data = materialInitialDto;
    data.taskId = taskId;
    data.fileId = uploadedFileId;
    data.name = name || null;

    if (data.name && data.name.length > 64) {
      setMessage(t("course:messages.MAX_LENGTH"));
      setMessageOpen(true);
      setMessageType("error");
      setTimeout(() => {
        setMessageOpen(false);
        setMessage("");
      }, 10000);
      return;
    }
    updateMaterialList(data);
  }

  const updateMaterialList = (material: IMaterial) => {
    serviceTask.putTaskMaterial(material).then(res => {
      if (res.status === 200) {
        if (material.id) {
          const index = [...materials].findIndex((x) => x.id === material.id);
          setMaterials([
            ...materials.slice(0, index),
            material,
            ...materials.slice(index + 1),
          ]);
        } else {
          if (materials) {
            setMaterials([...materials, { ...material, ...res.data }]);
          } else {
            setMaterials([{ ...material, ...res.data }])
          }
        }
      }
    })
  }

  const deleteMaterial = () => {
    return serviceTask.deleteTaskMaterial(itemId || "")
      .then(res => {
        if (res.status === 200) {
          setMaterials([
            ...materials.filter((x) => x.id !== itemId)
          ]);
        }
      })
  }

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteMaterial,
    modalTitle: t(`common:DELETE_FILE_CONFIRMATION_TEXT`)
  })

  const [openFileModal, setOpenFileModal] = useState<boolean>(false);
  const handleToggleFileModal = (modalState: boolean, material?: IMaterial) => {
    if (material) setMaterial(material);
    setOpenFileModal(modalState);
  }

  return (
    <>
      {DeleteModal}
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TabContext value="fileTab">
          <TabPanel value="fileTab" sx={{ p: 0, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <UploadButton
                label={t("common:actions.ADD_FILE")}
                backgroundColor={common.primaryColor}
                fontColor={common.fontWhite}
                width="172px"
                borderRadius="6px"
                height="28px"
                fontWeight={400}
                disabled={taskId === "new" || !(isAuthor || isCoAuthor) || disabled}
                typeCode="003"
                attachAndSaveFile={saveMaterial}
                sizeLimit={courseData?.maxFileSizeCanTutorUploadPerTask}
              />
            </Box>
            {materials?.length > 0 &&
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                {materials.map((material: IMaterial) => {
                  const text = material.name || "";
                  const shortText = text.length > 30 ? text.substring(0, 27) + "..." : text;
                  return (
                    <Box
                      key={material.id}
                      sx={{ display: 'flex', width: '100%', gap: 2 }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer', width: '30%' }}>
                        {material.name && getFileType(material.name) === 'pdf' && <PDFIcon />}
                        {material.name && getFileType(material.name) === 'image' && <PhotoOutlinedIcon />}
                        {material.name && getFileType(material.name) === 'audio' && <PlayCircleOutlinedIcon />}
                        <Typography
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleToggleFileModal(true, material)}
                        >{shortText}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <FileDownloadOutlinedIcon sx={{ color: '#262626', display: 'flex', cursor: "pointer" }}
                          onClick={() => downloadFile(material.fileId)}
                        />
                        {!disabled && <DeleteOutlineIcon
                          sx={{ color: common.errorColor, display: 'flex', cursor: "pointer" }}
                          onClick={() => { setItemId(material.id); openDeleteConfirmModal(material.id || "") }}
                        />}
                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                          <Checkbox
                            checked={!material.downloadable || false}
                            disabled={disabled}
                            onChange={() => updateMaterialList({ ...material, downloadable: !material.downloadable })}
                            sx={{
                              "&, &.Mui-checked": {
                                color: common.primaryColor,
                              },
                              color: common.primaryColor
                            }}
                          />
                          <Typography>{t('course:FILE_DOWNLOAD_BAN')}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            }
          </TabPanel>
        </TabContext>
      </Box>
      {openFileModal &&
        <FileViewerModal
          openModal={openFileModal}
          handleToggleModal={handleToggleFileModal}
          file={material}
        />
      }
    </>
  );
};

export default MaterialList;