import { Routes, Route, useLocation } from "react-router-dom";
import { Box, useTheme, useMediaQuery } from "@mui/material";

import { commonRoutes, privateRoutes, publicRoutes } from "../routes";
import SideMenu from "./SideMenu";
import BreadcrumbsProvider from "@providers/Breadcrumbs";
import CourseProvider from "@providers/CourseProvider";
import { NotificationSocket } from "@pages/notification/NotificationSocket";
import ScrollToTop from "./ScrollToTop";

interface RouteConfig {
  key: string;
  path: string;
  element: React.ElementType;
}

const renderRoutes = (routes: RouteConfig[]) =>
  routes.map(({ key, path, element: Element }) => (
    <Route
      key={key}
      path={path}
      element={
        <CourseProvider>
          <BreadcrumbsProvider>
            <Element />
          </BreadcrumbsProvider>
        </CourseProvider>
      }
    />
  ));

const Container = ({ role }) => {
  const location = useLocation();
  const { common } = useTheme().palette;
  const headerHeight =
    Number(localStorage.getItem("headerHeight")) + "px" || "60px";
  const footerHeight =
    Number(localStorage.getItem("footerHeight")) + "px" || "129px";
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={{ display: "flex" }}>
      {location.pathname !== "/" &&
        !location.pathname.includes("freeCourseCatalogue") &&
        !location.pathname.includes("task-assessment") &&
        !location.pathname.includes("terms-of-use") &&
        matches &&
        role && (
          <Box sx={{ backgroundColor: common.fontWhite }}>
            <SideMenu />
          </Box>
        )}
      <Box
        sx={{
          width: "100%",
          display: "grid",
          pt: 8,
          pb: location.pathname === "/" ? 0 : 3,
          px: location.pathname === "/" ? 0 : 3,
          backgroundColor: location.pathname === "/" ? common.white : common.rootBgPrimary,
          minHeight: `calc(100vh - (${headerHeight} + ${footerHeight}) + 60px)`,
          "@media (max-width:462px)": {
            padding: "18px 16px",
          },
        }}
      >
        <Routes>
          {renderRoutes(commonRoutes)}
        </Routes>
        {role ? (
          <>
            <Routes>{renderRoutes(privateRoutes)}</Routes>
            <NotificationSocket />
          </>
        ) : (
          <Routes>{renderRoutes(publicRoutes)}</Routes>
        )}
        <ScrollToTop />
      </Box>
    </Box>
  );
};

export default Container;