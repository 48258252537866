import { useContext, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import { AlertColor, MenuItem } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';

import { serviceCourse } from '@services/course';
import { CourseContext } from "@context/CourseContext";
import { FlowStatus, IStudyClass, CourseStatus, AuthorRole } from "@interfaces/index";
import useDeleteConfirmModal from '@hooks/useDeleteConfirmModal';
import { formatDateWithHourMinute } from '@utils/functions';

interface Props {
  flowList: Array<IStudyClass>;
  setFlowList: (list: Array<IStudyClass>) => void;
  handleToggleModal: (modalState: boolean, message?: string, flowId?: string | null) => void;
  setMessage: (message: string) => void;
  setMessageOpen: (modalState: boolean) => void;
  setMessageType: (type: AlertColor) => void;
}

const List = ({ flowList, setFlowList, handleToggleModal, setMessage, setMessageOpen, setMessageType }: Props) => {
  const { courseData } = useContext(CourseContext);
  const { t } = useTranslation(["course", "user", "dictionary", "common", "enum"]);
  const { common } = useTheme().palette;
  const [currentFlowId, setCurrentFlowId] = useState<string | null>(null);
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const isCoAuthor = courseData?.authors.filter((author) => author.authorRole === AuthorRole.COAUTHOR).some((author) => author.userId === courseData?.userId)

  const columns = useMemo<MRT_ColumnDef<IStudyClass>[]>(() => {
    return [
      {
        accessorFn: (row) => `${row.allStudentCount ? row.allStudentCount : 0} / ${row.capacity}`, //accessorFn used to join multiple data into a single cell
        id: 'capacity', //id is still required when using accessorFn instead of accessorKey
        header: t('course:CAPACITY'),
        size: 150,
        Cell: ({ renderedCellValue }) => (
          <span>{renderedCellValue}</span>
        ),
      },
      {
        accessorFn: (row) => `${formatDateWithHourMinute(row.regStartDate)} - ${formatDateWithHourMinute(row.regEndDate)}`,
        id: 'regStartDate',
        header: t('course:REGISTRATION_PERIOD'),
        size: 300,
        Cell: ({ renderedCellValue }) => (
          <span>{renderedCellValue}</span>
        ),
      },
      {
        accessorFn: (row) => {
          if (row.scheduleId)
            return '';
          return `${formatDateWithHourMinute(row.startDate)} - ${formatDateWithHourMinute(row.endDate)}`;
        },
        id: 'startDate',
        header: t('course:PERIOD_OF'),
        size: 300,
        Cell: ({ renderedCellValue }) => (
          <span>{renderedCellValue}</span>
        ),
      },
      {
        accessorFn: (row) => {
          if (row.scheduleId)
            return `${formatDateWithHourMinute(row.startDate)} - ${formatDateWithHourMinute(row.endDate)}`;
          return '';
        },
        id: 'scheduleId',
        header: t('dictionary:SCHEDULE'),
        size: 300,
        Cell: ({ renderedCellValue }) => (
          <span>{renderedCellValue}</span>
        ),
      },
      {
        accessorKey: 'status',
        header: t('user:STATUS'),
        size: 160,
        enableResizing: false,
        Cell: ({ row }) => {
          const flow = row.original;
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                background: flow.status === FlowStatus.CREATED ? '#E8E8E8' : flow.status === FlowStatus.PUBLISHED ? "#EBF6EB" : flow.status === FlowStatus.WITHDRAWN ? "#FFF4E4" : "#FFC5D8",
                color: flow.status === FlowStatus.CREATED ? '#6B6B6B' : flow.status === FlowStatus.PUBLISHED ? "#31AA27" : flow.status === FlowStatus.WITHDRAWN ? "#D9B201" : common.errorColor,
                borderRadius: "10px",
                height: "28px",
                width: "fit-content",
                px: 1
              }}
            >
              {t(`${flow.status}`)}
            </Box>
          )
        },
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
    ]
  }, [t, common]);

  const changeFlowStatus = (flowId: string | null, status: string, closeMenu: any) => {
    if (flowId) {
      serviceCourse.updateStudyClassStatus(flowId, status)
        .then(res => {
          if (res.status === 200) {
            setFlowList([...flowList].map(flow => {
              if (flow.id === flowId) return { ...flow, status };
              return flow;
            }))
            setMessage(t(`course:messages.FLOW_IS_${status}`));
            setMessageType('success');
            setMessageOpen(true);
          }
        }).catch(err => {
          if (err.response.status === 400) {
            setMessageType('error');
            setMessageOpen(true);
            if (err.response.data.code === 'INCORRECT_THEME_ACCESS_DATE') {
              const params = err.response.data.params;
              const themeNames = Object.values(params);
              const themeNamesString = themeNames.join(', ');

              setMessage(t(`course:messages.${err.response.data.code}`, {
                themeName: themeNamesString
              }));
            } else if (err.response.data.code === 'INCORRECT_TASK_ACCESS_DATE' ||
              err.response.data.code === 'INVALID_TASK_DEADLINE'
            ) {
              const params = err.response.data.params;
              const taskNames = Object.values(params);
              const taskNamesString = taskNames.join(', ');

              setMessage(t(`course:messages.${err.response.data.code}`, {
                taskName: taskNamesString
              }));
            } else {
              setMessage(t(`course:messages.${err.response.data.code}`));
            }
          }
        }).finally(() => {
          closeMenu();
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setTimeout(() => {
            setMessageOpen(false);
          }, 5000);
        });
    }
  }

  const deleteCourse = async () => {
    try {
      const res = await serviceCourse.deleteStudyClass(currentFlowId || "");
      if (res.status === 200) {
        setFlowList([...flowList].filter((x: IStudyClass) => x.id !== currentFlowId));
        setMessage(t('course:messages.FLOW_DELETED'));
        setMessageType('success');
        setMessageOpen(true);
        setTimeout(() => {
          setMessageOpen(false);
        }, 3000);
      }
    } catch (err) {
      setMessage(t('course:messages.FLOW_DELETE_ERROR'));
      setMessageType('error');
      setMessageOpen(true);
      setTimeout(() => {
        setMessageOpen(false);
      }, 3000);
    }
  }

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteCourse,
    modalTitle: t('course:messages.ARE_YOU_SURE_TO_DELETE_FLOW')
  })

  const table = useMaterialReactTable({
    columns,
    enableSorting: false,
    enableColumnActions: false,
    enableExpandAll: false,
    enableColumnResizing: false,
    enableTopToolbar: false,
    enableColumnPinning: false,
    enableRowActions: ((isAuthor || isCoAuthor) && courseData?.status !== CourseStatus.CLOSED) ? true : false,
    enablePagination: false,
    enableTableFooter: false,
    enableBottomToolbar: false,
    enableRowNumbers: true,
    data: flowList,
    localization: {
      noRecordsToDisplay: t('dictionary:EMPTY'),
      rowsPerPage: t('enum:LINES_PER_PAGE'),
      actions: "",
      rowActions: t('common:ACTIONS'),
      expand: "",
      rowNumber: '№',
    },
    layoutMode: 'grid',
    initialState: {
      columnPinning: { right: ['mrt-row-expand', 'mrt-row-actions', 'name'] },
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        display: 'grid',
        borderRadius: '6px'
      },
    },
    muiTableContainerProps: {
      sx: {
        '&::-webkit-scrollbar': { height: '4px', width: '7px' },
        '&::-webkit-scrollbar-thumb': { backgroundColor: '#D9D9D9', borderRadius: '4px' }
      },
    },
    muiTableHeadCellProps: {
      sx: { backgroundColor: common.white }
    },
    muiTableBodyCellProps: {
      sx: { backgroundColor: common.white }
    },
    renderRowActionMenuItems: ({ row, closeMenu }) => {
      return [
        <MenuItem
          key={0}
          onClick={() => {
            closeMenu();
            handleToggleModal(true, undefined, row.original.id);
          }}
          sx={{
            display: row.original?.status === FlowStatus.CREATED || row.original?.status === FlowStatus.WITHDRAWN ?
              'flex' : 'none'
          }}
        >
          {t('common:actions.EDIT')}
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => changeFlowStatus(row.original.id, FlowStatus.PUBLISHED, closeMenu)}
          sx={{
            display: courseData?.status === CourseStatus.PUBLISHED &&
              (row.original?.status === FlowStatus.CREATED || row.original?.status === FlowStatus.WITHDRAWN) ?
              'flex' : 'none'
          }}
        >
          {t('common:actions.PUBLISH')}
        </MenuItem>,
        <MenuItem
          key={2}
          onClick={() => changeFlowStatus(row.original.id, FlowStatus.WITHDRAWN, closeMenu)}
          sx={{
            display: (row.original?.status === FlowStatus.PUBLISHED) &&
              (row.original?.regStartDate ? new Date(row.original?.regStartDate) > new Date()
                : row.original?.startDate && new Date(row.original?.startDate) > new Date())
              ? 'flex' : 'none'
          }}
        >
          {t('common:actions.WITHDRAW_FLOW')}
        </MenuItem>,
        <MenuItem
          key={3}
          onClick={() => changeFlowStatus(row.original.id, FlowStatus.COMPLETED, closeMenu)}
          sx={{ display: courseData?.status === CourseStatus.PUBLISHED && row.original?.status === FlowStatus.PUBLISHED ? 'flex' : 'none' }}
        >
          {t('common:actions.COMPLETE_FLOW')}
        </MenuItem>,
        <MenuItem
          key={4}
          onClick={() => {
            setCurrentFlowId(row.original.id);
            closeMenu();
            openDeleteConfirmModal(row.original.id || '');
          }}
          sx={{ display: row.original?.status === FlowStatus.CREATED || row.original?.status === FlowStatus.WITHDRAWN ? 'flex' : 'none' }}
        >
          {t('common:actions.DELETE')}
        </MenuItem>
      ]
    }
  });

  return (
    <>
      <MaterialReactTable table={table} />
      {DeleteModal}
    </>
  );
};

export default List;
