import { ResponseFilter } from "@interfaces/filter";
import API from "./api";
import { IReview } from "@interfaces/index";
import { ResponseList } from "@interfaces/responseList";
import { StudyClass } from "@interfaces/studyClass";
import { UserShortInfo } from "@interfaces/user";
import { CourseShortInfo } from "@interfaces/course";
import { AddOrganization } from "@interfaces/organization";

export class PublicControllerApi extends API{
    private readonly url = "/course/public/main-page";
    private readonly userUrl='/user/public/user';
    private readonly publicUrl='/dictionary/public/dict';
    getReviewListByCourseId(req:ResponseFilter<{search:string}> & { id: string }):Promise<ResponseList<IReview>>{
        const {id, ...params} = req
        return this.post(`${this.url}/reviews/${id}`, params)
    }

    findNewCourses(body:ResponseFilter<{}>):Promise<ResponseList<StudyClass>>{
        return this.post(`${this.url}/new-courses`, body)
    }

    getContentSummaryPublic(courseId:string):Promise<CourseShortInfo>{
        return this.get(`${this.url}/course-info/${courseId}`)
    }

    getUserByIdPublic(userId:string):Promise<UserShortInfo>{
        return this.get(`${this.userUrl}/${userId}`)
    }

    updateAddOrganization(body:AddOrganization):Promise<Object>{
        return this.post(`${this.publicUrl}/feedback/request`,body)
    }
}