import { Suspense, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  Box,
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ruRU } from "@mui/material/locale";

import Container from "@components/Container";
import Header from "@components/Header";
import LoadingScreen from "@components/LoadingScreen";
import serviceAccount from "@services/account";
import { ColorContext } from "./context/ColorContext";
import { darkTheme } from "./themes/dark";
import { lightTheme } from "./themes/light";
import {
  dictionaryActions,
  getDictionaryList,
} from "@store/dictionary/reducers";
import {
  CourseRole,
  DictionaryCode,
  IDictionary,
  Role,
  TypeCode,
} from "./interfaces";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import Footer from "@pages/home/Footer/Footer";

import "./styles/index.scss"

// Create a client
const queryClient = new QueryClient();

const App = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [mode, setMode] = useState<PaletteMode>("light");
  const currentTheme = mode === "light" ? lightTheme : darkTheme;
  const { i18n } = useTranslation();
  const lang = localStorage.getItem("i18nextLng") || "kz";
  const orgId = useAppSelector(
    (store) => store.account.profileInfo.authorityInfo.orgId
  );
  const role = useAppSelector(
    (store) => store.account.profileInfo.authorityInfo.role
  );

  useEffect(() => {
    // Check if user information exists in local storage
    const storedUserInfo = localStorage.getItem("userInfo");

    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);
      dispatch({
        type: "ACCOUNT/UPDATE_PROFILE_INFO_SUCCESS",
        payload: userInfo,
      });
    } else {
      setProfileInfo();
    }
    // eslint-disable-next-line
  }, []);

  const setProfileInfo = async () => {
    const response = await serviceAccount.getProfileInfo();
    localStorage.setItem("userInfo", JSON.stringify(response.data));
    dispatch({
      type: "ACCOUNT/UPDATE_PROFILE_INFO_SUCCESS",
      payload: response.data,
    });
    const { role } = response.data.authorityInfo;
    const defaultPage = sessionStorage.getItem('defaultPageAfterAuth');
    if (defaultPage) navigate(defaultPage);
    else {
      if (role === Role.EDUCATOR || role === Role.LISTENER || role === Role.MANAGER) {
        navigate("/myCourses");
      } else if (role === Role.ADMIN || role === Role.LOCAL_ADMIN) {
        navigate("/courses");
      } else navigate("/profile");
    }
  };

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "light"
        );
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(currentTheme, ruRU), [currentTheme]);

  useEffect(() => {
    if (lang && lang !== i18n.language) {
      i18n.changeLanguage(lang);
    } else {
      localStorage.setItem("i18nextLng", i18n.language);
    }
  }, [i18n, lang]);

  useEffect(() => {
    if (typeof orgId === "number") {
      if (orgId === 0) {
        const item = {} as IDictionary;
        if (role === CourseRole.LISTENER) {
          dispatch(
            dictionaryActions.setData({
              ...item,
              availableCourseTypes: [TypeCode.FREE],
            })
          );
        } else {
          dispatch(
            dictionaryActions.setData({
              ...item,
              availableCourseTypes: [TypeCode.EDU, TypeCode.FREE],
            })
          );
        }
      } else {
        dispatch(
          getDictionaryList({
            code: DictionaryCode.BRANCH,
            id: String(orgId),
          })
        );
      }
    }
  }, [dispatch, orgId, role]);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <ColorContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <CssBaseline enableColorScheme />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  backgroundColor: "#FFFFFF",
                  minHeight: "100vh",
                  width: "100%",
                }}
              >
                <Header />
                <Container role={role} />
                <Footer />
              </Box>
            </LocalizationProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </ColorContext.Provider>
    </Suspense >
  );
};

export default App;
