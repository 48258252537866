import React from "react";
import s from "./CardList.module.scss";
import CardItem from "./CardItem/CardItem";
import { Box, Button, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { publicControllerApi } from "@services/index";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardList = () => {
  const { t } = useTranslation(["home"]);
  const navigate = useNavigate();
  const { data } = useQuery({
    queryKey: ["findNewCourses"],
    queryFn: () =>
      publicControllerApi.findNewCourses({
        pageNumber: 0,
        size: 8,
        filter: {},
      }),
  });
  if (!data) {
    return null;
  }
  return (
    <div className={s.container}>
      <div className={s.head}>
        <div className={s.title}>{t("home:POPULAR_COURSE")}</div>

        <Button
          sx={{ padding: "8px 12px", borderRadius: "4px" }}
          size="large"
          variant="contained"
          color="primary"
          onClick={() => navigate("/freeCourseCatalogue")}
        >
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 400,
            }}
          >
            {t("home:GO_CATALOG")}
          </Typography>
        </Button>
      </div>
      <div className={s.wrapper}>
        {data.dtoList?.map((item) => (
          <CardItem key={item.id} data={item} />
        ))}
      </div>
    </div>
  );
};

export default CardList;
