import { useFormikContext, useField } from "formik";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import FormHelperText from '@mui/material/FormHelperText';

interface Props {
  label?: string;
  name: string;
  disabled?: boolean;
  pickerType?: string;
  isRequired?: boolean;
  disabledDate?: (day: Dayjs) => boolean;
  minDate?: string | Date;
  maxDate?: string | Date;
}

const DatePicker: React.FC<Props> = ({
  label,
  name,
  disabled = false,
  pickerType,
  isRequired = false,
  disabledDate,
  minDate,
  maxDate,
}) => {
  const { common } = useTheme().palette;
  const formikProps = useFormikContext();
  const [field, meta] = useField(name);

  const handleTimeChange = (newValue: Dayjs | null) => {
    formikProps.setFieldValue(name, newValue?.format("YYYY-MM-DDTHH:mm"))
  };

  const handleChange = (newValue: Dayjs | null) => {
    formikProps.setFieldValue(name, newValue?.format("YYYY-MM-DD"));
  };

  const handleDateTimeChange = (newValue: Dayjs | null) => {
    formikProps.setFieldValue(name, newValue?.format("YYYY-MM-DDTHH:mm"));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%"
      }}
    >
      {label &&
        <Typography>
          {label}
          {isRequired && <span style={{ color: common.errorColor }}>*</span>}
        </Typography>
      }
      <LocalizationProvider dateAdapter={AdapterDayjs} disabled={disabled}>
        {pickerType === "time" ?
          <TimePicker
            inputFormat="HH:mm"
            value={field.value || null}
            onChange={handleTimeChange}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "00:00"
                }}
              />
            )}
            ampm={false}
            ampmInClock={false}
            views={['hours', 'minutes']}
          /> :
          pickerType === "dateTime" ?
            <DateTimePicker
              inputFormat="DD-MM-YYYY HH:mm"
              value={field.value || null}
              onChange={handleDateTimeChange}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  error={meta.error !== undefined}
                  helperText={meta.error}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "дд-мм-гггг чч:мм"
                  }}
                  sx={{
                    '& label.Mui-focused': { color: common.primaryColor },
                    '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': { borderColor: common.primaryColor },
                      '&.Mui-focused fieldset': { borderColor: common.primaryColor }
                    }
                  }}
                />
              )}
              disabled={disabled}
              shouldDisableDate={disabledDate}
              ampm={false}
            /> :
            <DesktopDatePicker
              inputFormat="DD.MM.YYYY"
              value={field.value || null}
              onChange={handleChange}
              minDate={minDate ? dayjs(minDate) : undefined}
              maxDate={maxDate ? dayjs(maxDate) : undefined}
              PopperProps={{
                modifiers: [
                  {
                    name: 'preventOverflow',
                    enabled: false
                  },
                  {
                    name: 'flip',
                    options: {
                      fallbackPlacements: ['bottom'],
                    },
                  },
                  {
                    name: 'positionFixed',
                    enabled: true
                  }
                ],
                sx: {
                  '& .PrivatePickersYear-yearButton': {
                    fontSize: '0.9rem',
                  },
                  zIndex: 10000,
                },
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  error={meta.error !== undefined}
                  helperText={meta.error}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "дд.мм.гггг"
                  }}
                  sx={{
                    '& label.Mui-focused': { color: common.primaryColor },
                    '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': { borderColor: common.primaryColor },
                      '&.Mui-focused fieldset': { borderColor: common.primaryColor }
                    }
                  }}
                />
              )}
              disabled={disabled}
              shouldDisableDate={disabledDate}
            />
        }
      </LocalizationProvider>
    </Box>
  );
};

export default DatePicker;