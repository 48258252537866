import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";

import Message from "@components/Message";
import UnhiddenHints from "@components/UnhiddenHints";
import { useDebounce } from "@hooks/useDebounce";
import {
  AuthorRole,
  CourseRole,
  CourseStatus,
  IUserSearchItem,
  TypeConference,
} from "@interfaces/index";
import { useGetAllStudyClassesByCourseIdQuery } from "@services/course";
import { DynamicModuleLoader, ReducersList } from "@store/DynamicModuleLoader";
import {
  getMessage,
  getMessageOpen,
  getMessageType,
} from "@store/conference/conferenceSelector";
import {
  conferenceActions,
  conferenceReducer,
} from "@store/conference/conferenceSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import CustomButton from "@ui/Button";
import CustomSearch from "@ui/Search";
import { ActiveConference } from "./ActiveConference";
import { FinishedConference } from "./FinishedConference";
import CustomAutocomplete from "@ui/Autocomplete";
import { FilterByGroup } from "./model";
import { useCourseContext } from "@context/CourseContext";
import { EmptyComponent, HintComponent } from "@components/common";
import { ReactComponent as EmptyConferenceSvg } from "@assets/svg_files/emptyComponent/emptyConference.svg";
import { ReactComponent as WarningHintComponent } from "@assets/svg_files/hintComponent/warning.svg";
import { serviceMember } from "@services/member";
const reducers: ReducersList = {
  conference: conferenceReducer,
};

interface Props {
  currentStudyClass?: string | null;
}

export const ConferencePage = ({ currentStudyClass }: Props) => {
  const { t } = useTranslation(["course", "common"]);
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { courseId } = useParams();
  const { courseData } = useCourseContext();
  const profile = useSelector((store: any) => store.account.profileInfo);
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const isCoAuthor = courseData?.authors.filter((author) => author.authorRole === AuthorRole.COAUTHOR).some((author) => author.userId === courseData?.userId)
  const [responsibleMemberList, setResponsibleMemberList] = useState<
    IUserSearchItem[]
  >([]);
  //Selectors
  const typeConference =
    useAppSelector((store) => store.conference?.typeConference) ??
    TypeConference.ACTIVE;
  const messageOpen = useSelector(getMessageOpen);
  const message = useSelector(getMessage);
  const messageType = useSelector(getMessageType);

  //handle
  const handleChange = useCallback(
    (_, type: string) => {
      dispatch(conferenceActions.setTypeConference(type as TypeConference));
    },
    [dispatch]
  );

  const [activeStudyClass, setActiveStudyClass] = useState<number>(0);
  const [isConferenceHintVisible, setIsConferenceHintVisible] =
    useState<boolean>(true);
  const [searchName, setSearchName] = useState<string>("");
  const [filterByCategory, setFilterByCategory] = useState<FilterByGroup>(
    FilterByGroup.ALL
  );

  //api
  const { data: studyClasses, refetch } = useGetAllStudyClassesByCourseIdQuery(
    courseId || ""
  );

  useEffect(() => {
    if (studyClasses && studyClasses.length > 0) {
      dispatch(
        conferenceActions.setClassesId(studyClasses[activeStudyClass].id ?? "")
      );
    }
  }, [activeStudyClass, dispatch, studyClasses]);

  useEffect(() => {
    const storedHintConferenceVisibility = localStorage.getItem(
      `hintConferenceVisibility/${courseId}`
    );
    if (storedHintConferenceVisibility === "false") {
      setIsConferenceHintVisible(false);
    }
  }, [courseId]);

  useEffect(() => {
    localStorage.setItem(
      `hintConferenceVisibility/${courseId}`,
      isConferenceHintVisible.toString()
    );
  }, [isConferenceHintVisible, courseId]);

  useEffect(() => {
    if (studyClasses) {
      serviceMember
        .getMemberList(studyClasses[activeStudyClass]?.id, [
          CourseRole.TUTOR,
          CourseRole.CURATOR,
          CourseRole.ASSISTANT,
          CourseRole.EXAMINER,
        ])
        .then((res) => {
          if (res.status === 200) {
            setResponsibleMemberList(res.data);
          }
        });
    }
  }, [activeStudyClass, studyClasses]);

  // FIXME: When you delete in flow page or add flow that cached in browser need to fix later
  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (currentStudyClass && studyClasses) {
      const index = studyClasses.findIndex(studyClass => studyClass.id === currentStudyClass);
      if (index !== -1) setActiveStudyClass(index);
    }
  }, [currentStudyClass, setActiveStudyClass, studyClasses])

  if (studyClasses && studyClasses.length === 0) {
    return (
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <HintComponent
          leftBorder
          svg={<WarningHintComponent />}
          text={t("course:hints.IF_NO_FLOWS")}
        />
        <EmptyComponent
          height={384}
          text={t("common:hints.NO_ASSIGNMENTS_FOR_GRADING")}
          svg={<EmptyConferenceSvg />}
        />
      </Box>
    );
  }

  return (
    <DynamicModuleLoader reducers={reducers} removeAfterUnmount>
      {messageOpen && (
        <Box sx={{ mb: 2 }}>
          <Message
            type={messageType}
            message={message}
            actions={conferenceActions.setMessageOpen(false)}
          />
        </Box>
      )}

      {isConferenceHintVisible && (
        <UnhiddenHints
          clickHandler={() => setIsConferenceHintVisible(false)}
          clearIconVisible={true}
          text={t("course:hints.SECTION_FOR_CONDUCTING_REMOTE_EDUCATION_EVENT")}
        />
      )}
      <Box display={"flex"} flexDirection={"column"} gap={1.5}>
        {studyClasses && (
          <Box
            display="flex"
            gap={1}
            sx={{
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { width: "6px", height: "2px" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#E5E0EB",
                borderRadius: "6px",
              },
            }}
          >
            {studyClasses?.map((classes, index) => {
              return (
                <Box
                  key={classes.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40px",
                    fontSize: "14px",
                    background:
                      activeStudyClass === index ? common.primaryColor : "white",
                    padding: "12px 16px",
                    borderRadius: "6px",
                    width: "94px",
                    whiteSpace: "nowrap",
                    color: activeStudyClass === index ? "white" : "#8E8E93",
                    cursor: "pointer",
                    "&:hover": {
                      background:
                        activeStudyClass === index
                          ? common.primaryColor
                          : "#F5EFFF",
                    },
                    mt: 2,
                  }}
                  onClick={() => {
                    setActiveStudyClass(index);
                  }}
                >
                  {t("course:EDU_STREAM")} #{index + 1}
                </Box>
              );
            })}
          </Box>
        )}
        {studyClasses &&
          studyClasses.length > 0 &&
          responsibleMemberList.length === 0 && (
            <HintComponent
              leftBorder
              svg={<WarningHintComponent />}
              text={t("course:hints.IF_NO_PARTICIPANTS")}
            />
          )}
      </Box>
      <TabContext value={typeConference}>
        <Box
          sx={{
            display: "block",
            width: "100%",
            background: common.fontWhite,
            border: `1px solid ${common.borderSecondary}`,
            borderRadius: 3,
            p: 2,
            mt: 2,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <Tabs
                onChange={handleChange}
                indicatorColor="secondary"
                value={typeConference}
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "#1A141F",
                  },
                  "& .MuiTabs-indicator": {
                    background: common.borderPrimary,
                  },
                }}
              >
                <Tab
                  label={t("course:conference.ACTIVE_CONFERENCES")}
                  value={TypeConference.ACTIVE}
                  sx={{
                    textTransform: "none",
                    fontSize: { xs: "14px", sm: "18px" },
                  }}
                />
                <Tab
                  label={t("course:conference.COMPLETED_CONFERENCES")}
                  value={TypeConference.FINISHED}
                  sx={{
                    textTransform: "none",
                    fontSize: { xs: "14px", sm: "18px" },
                  }}
                />
              </Tabs>
            </Box>
          </Box>
          <Box
            margin="15px 15px 0 15px"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            gap={1}
            mb={2}
          >
            <CustomSearch
              placeholder={t("course:conference.SEARCH_BY_CONFERENCE_NAME")}
              height="44px"
              onChange={setSearchName}
            />
            <CustomAutocomplete
              sx={{ width: "190px" }}
              name="category"
              options={[
                {
                  id: FilterByGroup.ONLINE,
                  text: FilterByGroup.ONLINE,
                },
                {
                  id: FilterByGroup.ORAL,
                  text: FilterByGroup.ORAL,
                },
                {
                  id: FilterByGroup.ALL,
                  text: "Все",
                },
              ]}
              placeholder="Относится к"
              customIconDisabled={false}
              onChange={(_, value) => setFilterByCategory(value)}
              value={filterByCategory}
            />
            <CustomButton
              backgroundColor={common.primaryColor}
              fontColor={common.fontWhite}
              width="133px"
              borderRadius="6px"
              disabled={!(isAuthor || isCoAuthor || responsibleMemberList.find((member) => member.userId === profile.pltaId))
                || courseData?.status === CourseStatus.CLOSED || responsibleMemberList.length === 0}
              onClick={() =>
                navigate(
                  `/myCourses/${courseId}/conference?classesId=${studyClasses && studyClasses[activeStudyClass]?.id
                  }`
                )
              }
            >
              {t("common:actions.ADD")}
            </CustomButton>
          </Box>
        </Box>
        <TabPanel
          sx={{
            padding: "0",
          }}
          value={typeConference}
        >
          {typeConference === TypeConference.ACTIVE && studyClasses && (
            <ActiveConference
              search={searchName}
              classId={studyClasses[activeStudyClass].id}
              filterByCategory={filterByCategory}
            />
          )}
          {typeConference === TypeConference.FINISHED && (
            <FinishedConference
              search={searchName}
              classId={studyClasses && studyClasses[activeStudyClass]?.id}
              filterByCategory={filterByCategory}
            />
          )}
        </TabPanel>
      </TabContext>
    </DynamicModuleLoader>
  );
};
