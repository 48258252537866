import React, { useEffect, useState, useContext, Dispatch } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Box,
  TextField,
  useTheme,
  Popover,
  Typography,
  AlertColor,
} from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CustomAutocomplete from "@ui/Autocomplete";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import SignalCellularNoSimOutlinedIcon from "@mui/icons-material/SignalCellularNoSimOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { serviceCourse } from "@services/course";
import { useTranslation } from "react-i18next";
import EmptyDataComponent from "@components/EmptyDataComponent";
import { CourseContext } from "@context/CourseContext";
import { useSelector } from "react-redux";
import { getFlowId } from "@store/journal/journalSelector";
import {
  CourseStatus,
  TaskFormat,
  TaskStatus,
  TaskType,
} from "@interfaces/index";
import { handleColorByStatus } from "./lib";
import { serviceTask } from "@services/task";
import { useAppSelector } from "@store/hooks";
interface Props {
  searchFIO: string | null;
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  tasks: any[];
  journalList: any;
  setJournalList: Dispatch<any>;
}

export const ScoreTable = ({
  searchFIO,
  setMessage,
  setMessageOpen,
  setMessageType,
  tasks,
  journalList,
  setJournalList
}: Props) => {
  const [showScoreInput, setShowScoreInput] = useState<number | null>(null);
  const { common } = useTheme().palette;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { courseId } = useParams<{ courseId: string }>();
  const profile = useAppSelector((store) => store.account.profileInfo.pltaId);
  const [journalTasks, setJournalTasks] = useState<Array<any>>([]);
  const [tableTaskValue, setTableTaskValue] = useState<any>();
  const [tableStatusValue, setTableStatusValue] = useState<any>(null);
  const [point, setPoint] = useState<number>(0);
  const { t } = useTranslation(["common", "enum"]);
  const [gradingSystemId, setGradingSystemId] = useState<number | null>(null);
  const navigate = useNavigate();
  const { courseData } = useContext(CourseContext);
  const canPutMark = tasks?.filter((task) => (tableTaskValue?.id === task.taskId) && task?.responsibleMembers?.find((member) => member.userId === profile)).length > 0
  const courseIsClosed = courseData?.status === CourseStatus.CLOSED;
  const flowId = useSelector(getFlowId);
  const statusOption = [
    {
      id: "NOT_ASSIGNED",
      name: t("common:statuses.NOT_ASSIGNED"),
      format: ["WRITTEN", "QUIZ", "ORAL"],
    },
    {
      id: "ASSIGNED",
      name: t("common:statuses.ASSIGNED"),
      format: ["WRITTEN", "QUIZ", "ORAL"],
    },
    { id: "STARTED", name: t("common:statuses.STARTED"), format: ["QUIZ"] },
    {
      id: "UNDER_REVIEW",
      name: t("common:statuses.UNDER_REVIEW"),
      format: ["WRITTEN"],
    },
    {
      id: "ON_CORRECTION",
      name: t("common:statuses.ON_CORRECTION"),
      format: ["WRITTEN"],
    },
    {
      id: "NOT_COMPLETED_ON_TIME",
      name: t("common:statuses.NOT_COMPLETED_ON_TIME"),
      format: ["WRITTEN", "QUIZ", "ORAL"],
    },
    {
      id: "COMPLETED",
      name: t("common:statuses.COMPLETED"),
      format: ["WRITTEN", "QUIZ", "ORAL"],
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const result = tasks.map((node) => {
      if (node.task.type === TaskType.CONTROL) {
        return {
          ...node.task,
          icon: (
            <Box
              sx={{
                width: "24px",
                height: "24px",
                background: "#69CDE3",
                padding: "2px 0 0 3px",
                borderRadius: "10px",
                color: "#fff",
                marginRight: "5px",
              }}
            >
              <TaskOutlinedIcon sx={{ fontSize: "18px" }} />
            </Box>
          ),
        };
      } else if (node.task.isExcludeTotalMark) {
        return {
          ...node.task,
          icon: (
            <Box
              sx={{
                width: "24px",
                height: "24px",
                background: "#F97A7A",
                padding: "2px 0 0 3px",
                borderRadius: "10px",
                color: "#fff",
                marginRight: "5px",
              }}
            >
              <BlockOutlinedIcon sx={{ fontSize: "18px" }} />
            </Box>
          ),
        };
      } else if (node.task.isNoMark) {
        return {
          ...node.task,
          icon: (
            <Box
              sx={{
                width: "24px",
                height: "24px",
                background: "#2D29EF",
                padding: "2px 0 0 3px",
                borderRadius: "10px",
                color: "#fff",
                marginRight: "5px",
              }}
            >
              <SignalCellularNoSimOutlinedIcon sx={{ fontSize: "18px" }} />
            </Box>
          ),
        };
      } else {
        return {
          ...node.task,
          icon: <></>,
        };
      }
    });
    setJournalTasks(result);
    setTableTaskValue(result[0]);
  }, [courseId, tasks]);

  function handleTaskPoint(node, point, index) {
    if (node.progressId && point) {
      if (point > tableTaskValue.point || point < 0) {
        setMessageOpen(true);
        setMessageType("error");
        setMessage(t("course:messages.GRADE_EXCEEDS_MAXIMUM_GRADE"));
        setTimeout(() => {
          setMessageOpen(false);
        }, 5000);
      } else {
        serviceTask.updateTaskProgress({
          id: node.progressId,
          point,
          status: TaskStatus.COMPLETED
        }).then((res) => {
          if (res.status === 200) {
            const newDtoList = [...journalList?.dtoList];
            newDtoList[index].takenPoint = point;
            newDtoList[index].progressStatus = TaskStatus.COMPLETED;
            setMessageOpen(true);
            setMessageType("success");
            setMessage(t("course:messages.GRADE_SUCCESSFULLY_SAVED"));
            setShowScoreInput(null);
            setJournalList({ ...journalList, dtoList: newDtoList });
            setTimeout(() => {
              setMessageOpen(false);
            }, 3000);
          }
        });
      }
    }
  }
  useEffect(() => {
    if (flowId && tableTaskValue) {
      const bodyData = {
        pageNumber: 0,
        size: 25,
        filter: {
          search: searchFIO,
          taskId: tableTaskValue.id,
          classId: flowId,
          status: tableStatusValue || null,
        },
        sorting: {
          "cm.user_name": "asc",
        },
      };
      serviceCourse.getJournalLists(bodyData).then((res) => {
        setJournalList((prevJournalList) => {
          if (JSON.stringify(prevJournalList) !== JSON.stringify(res.data)) {
            return res.data;
          }
          return prevJournalList;
        });
      });
    }
  }, [tableTaskValue, tableStatusValue, searchFIO, flowId, setJournalList]);

  useEffect(() => {
    if (courseId) {
      serviceCourse.getGradingSystemId(courseId).then((res) => {
        if (res.status === 200) {
          setGradingSystemId(res.data);
        }
      });
    }
  }, [courseId]);

  return (
    <Box>
      {journalTasks.length < 1 ? (
        <EmptyDataComponent text={t("course:messages.NO_ASSIGNMENT_GRADING")} />
      ) : (
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          <TableContainer sx={{ mt: "16px" }}>
            <Table>
              <TableHead sx={{ background: common.tableHeaderColor }}>
                <TableRow sx={{ alignItems: "center" }}>
                  <TableCell width="25%">{t("enum:LISTENERS")}</TableCell>
                  <TableCell width="25%">
                    <Box display="flex" gap={1}>
                      {t("enum:FINAL_GRADE")}
                    </Box>
                  </TableCell>
                  <TableCell width="25%">
                    <CustomAutocomplete
                      sx={{ background: common.secondaryColor }}
                      name="tasks"
                      placeholder={t("common:placeholders.CHOOSE_TASK")}
                      inputBgColor={common.tableHeaderColor}
                      value={tableTaskValue}
                      valueAsObject
                      customIconDisabled={false}
                      options={journalTasks}
                      onChange={(_: any, value: any) => {
                        setTableTaskValue(value);
                        setShowScoreInput(null);
                      }}
                      icon={tableTaskValue.icon}
                      clearable={false}
                    />
                  </TableCell>
                  <TableCell width="25%">
                    <CustomAutocomplete
                      sx={{ background: common.secondaryColor }}
                      name="status"
                      customIconDisabled={false}
                      placeholder={t("common:placeholders.SELECT_STATUS")}
                      inputBgColor={common.tableHeaderColor}
                      onChange={(_: any, value: any) => {
                        setTableStatusValue(value);
                        setShowScoreInput(null);
                      }}
                      options={
                        tableTaskValue
                          ? statusOption.filter((status) =>
                            status.format.includes(tableTaskValue.format)
                          )
                          : []
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gradingSystemId !== 3 && (
                  <TableRow>
                    <TableCell size="small"></TableCell>
                    <TableCell
                      size="small"
                      sx={{ fontSize: "12px", color: "#8E8E93" }}
                    >
                      {t("course:POINTS_MAX", { point: 100 })}
                    </TableCell>
                    <TableCell
                      size="small"
                      sx={{ fontSize: "12px", color: "#8E8E93" }}
                    >
                      {t("course:POINTS", { point: tableTaskValue?.point })}
                    </TableCell>
                    <TableCell size="small"></TableCell>
                  </TableRow>
                )}
                {journalList?.dtoList.map((node, index) => {
                  return node.progressId ? (
                    <TableRow key={node.userId}>
                      <TableCell size="small">{node.userFullName}</TableCell>
                      <TableCell size="small">
                        {courseIsClosed ? (
                          <Typography color="#30BE71">
                            {node.totalCoursePoint.toFixed(
                              courseData?.gradingAccuracy
                            )}
                          </Typography>
                        ) : node?.totalCoursePoint ? (
                          <Typography color="#30BE71">
                            {node.totalCoursePoint.toFixed(
                              courseData?.gradingAccuracy
                            )}
                          </Typography>
                        ) : (
                          <Box color={"#9C9C9C"}>—</Box>
                        )}
                      </TableCell>
                      <TableCell size="small">
                        <Box display="flex" justifyContent="space-between">
                          {node.takenPoint !== undefined &&
                            node.takenPoint !== null
                            ? node.takenPoint
                            : showScoreInput !== index + 1 &&
                            ((tableTaskValue.format === TaskFormat.WRITTEN &&
                              (node.progressStatus === TaskStatus.ASSIGNED ||
                                node.progressStatus ===
                                TaskStatus.ON_CORRECTION ||
                                node.progressStatus ===
                                TaskStatus.NOT_COMPLETED_ON_TIME)) ||
                              ((tableTaskValue.format === TaskFormat.ORAL &&
                                !node.isActive)
                                && (
                                  node.progressStatus === TaskStatus.NOT_ASSIGNED ||
                                  node.progressStatus === TaskStatus.STARTED ||
                                  node.progressStatus === TaskStatus.ON_CORRECTION ||
                                  node.progressStatus === TaskStatus.NOT_COMPLETED_ON_TIME))
                              ||
                              tableTaskValue.format === TaskFormat.QUIZ ||
                              !node.allowed ||
                              tableTaskValue.isNoMark || !canPutMark || !node.canListenerAttendFinal ? (
                              <Box sx={{ width: "50px" }}>—</Box>
                            ) : (
                              <Box
                                sx={{ cursor: "pointer", width: "50px" }}
                                onClick={() => {
                                  setPoint(0);
                                  setShowScoreInput(index + 1);
                                }}
                              >
                                —
                              </Box>
                            ))}
                          {showScoreInput === index + 1 && !node.takenPoint && (
                            <Box display="flex" gap={1} alignItems="center">
                              <TextField
                                size="small"
                                sx={{
                                  width: "50px",
                                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                  {
                                    display: "none",
                                  },
                                }}
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    style: {
                                      padding: "3px 5px",
                                    },
                                  },
                                }}
                                onChange={(event) =>
                                  setPoint(
                                    event.target.value
                                      ? Number(event.target.value)
                                      : 0
                                  )
                                }
                              />
                              {point > 0 && (
                                <Box
                                  color="#30BE71"
                                  border="1px solid #30BE71"
                                  padding="3px 5px 0px 5px"
                                  borderRadius="5px"
                                  alignItems="center"
                                  onClick={() =>
                                    handleTaskPoint(node, point, index)
                                  }
                                >
                                  <CheckOutlinedIcon
                                    sx={{ fontSize: "14px" }}
                                  />
                                </Box>
                              )}
                              <Box
                                color={common.errorColor}
                                border="1px solid #FD0053"
                                padding="3px 5px 0px 5px"
                                borderRadius="5px"
                                alignItems="center"
                                onClick={() => setShowScoreInput(null)}
                              >
                                <ClearOutlinedIcon sx={{ fontSize: "14px" }} />
                              </Box>
                            </Box>
                          )}
                          <Box
                            sx={{
                              background:
                                canPutMark && !courseIsClosed && node.canListenerAttendFinal
                                  ? common.primaryColor
                                  : "#E4E5E7",
                              height: "23px",
                            }}
                            padding="3px 5px 0px 5px"
                            borderRadius="5px"
                            color="#fff"
                            onClick={() => {
                              if (canPutMark && !courseIsClosed && node.canListenerAttendFinal)
                                navigate(
                                  `/myCourses/${courseId}/task-assessment/journal/${node.recipientId}/${node.progressId}/flows/${flowId}`
                                );
                            }}
                          >
                            <LoginOutlinedIcon
                              sx={{ fontSize: "14px", cursor: "pointer" }}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              padding: "6px",
                              backgroundColor: handleColorByStatus(
                                node.progressStatus
                              ) as any,
                              color: "white",
                              width: "130px",
                              borderRadius: "4px",
                              fontSize: "10px",
                            }}
                          >
                            {!courseIsClosed
                              ? t(`common:statuses.${node.progressStatus}`)
                              : "—"}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow
                      key={node.userId}
                      sx={{ background: "#E4E5E7" }}
                      title={t("course:messages.NOT_TASK_RECIPIENT")}
                    >
                      <TableCell size="small">{node.userFullName}</TableCell>
                      <TableCell size="small">
                        {node?.totalCoursePoint ? (
                          <Typography color="#30BE71">
                            {node.totalCoursePoint}
                          </Typography>
                        ) : (
                          <Box color={"#9C9C9C"}>—</Box>
                        )}
                      </TableCell>
                      <TableCell size="small">
                        <Box color={"#9C9C9C"}>—</Box>
                      </TableCell>
                      <TableCell size="small">
                        <Box color={"#9C9C9C"}>—</Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Typography sx={{ p: 1, cursor: "pointer" }}>
                {t("common:actions.RECALCULATE")}
              </Typography>
              <Typography sx={{ p: 1, cursor: "pointer" }}>
                {t("common:actions.PUBLISH_GRADES")}
              </Typography>
            </Popover>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};
