import { useState, useRef, useLayoutEffect, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Avatar, Box, useTheme, Badge, Typography, Popover } from "@mui/material";

import { StateSchema } from "@store/StateSchema";
import ProfileMenu from "./ProfileMenu";
import CustomButton from "@ui/Button";
import FadeMenu from "./FadeMenu";
import PopoverNotification from "@pages/notification/PopoverNotification";
import AvatarPicture from "./AvatarPicture";
import { getUnreadNotificationsCount } from "@store/notification/actions";
import CustomAutocomplete from "@ui/Autocomplete";
import { Role } from "@interfaces/index";
import { profileInitialDto } from "@store/account/reducers";
import { HomeVariant } from "@pages/home/types/home";
import { ReactComponent as PlatonusSvg } from "@assets/svg_files/certificate/platonus.svg";

export const langOptions = [
  {
    id: 'kz',
    text: 'KZ'
  },
  {
    id: 'ru',
    text: 'RU'
  },
  {
    id: 'en',
    text: 'EN'
  },
];

export const Header = () => {
  const { t, i18n } = useTranslation(["header", "common", "profile"]);
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const profile = useSelector((store: StateSchema) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const unreadNotificationsCount = useSelector((store: any) => store.notification.notificationCount);

  useEffect(() => {
    if (profile.pltaId) dispatch<any>(getUnreadNotificationsCount());
  }, [dispatch, profile.pltaId]);

  const onClickNotificationBox = () => {
    navigate("notifications");
    setShowNotifications(!showNotifications);
  };

  const headerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (headerRef.current) {
      localStorage.setItem("headerHeight", JSON.stringify(headerRef.current.clientHeight));
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const changeLanguage = (lang: string) => {
    localStorage.setItem('i18nextLng', lang);
    i18n.changeLanguage(lang);
  }

  const login = () => {
    window.location.href = '/oauth2/authorization/gateway';
  }

  return (
    <>
      <Box
        ref={headerRef}
        sx={{
          position: "fixed",
          backgroundColor: common.headerBgPrimary,
          borderBottom: `1px solid ${common.strokePrimary}`,
          width: 1,
          zIndex: 10,
          '@media (max-width:960px)': {
            display: 'none',
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: (pathname !== "/login" && pathname !== "/registration") ? "space-between" : "center",
            px: { xl: 5, lg: 4, md: 3, sm: 1, xs: 1 },
            my: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
            }}
          >
            <Box
              sx={{
                display: {
                  xl: "none",
                  lg: "none",
                  md: "none",
                  sm: "flex",
                  xs: "flex",
                },
              }}
            >
              <FadeMenu />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: '30px',
                alignItems: "center",
                px: { xl: 1, lg: 0.5, md: 0.5, sm: 0.5, xs: 0.5 },
              }}
            >
              <RouterLink to={profile.authorityInfo.orgId !== 104 ? "/" : "/profile"}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                >
                  <Avatar
                    alt="Header Logo"
                    src={require(`@assets/images/headerLogo.png`)}
                  />
                  <Avatar
                    alt="Header Logo Text"
                    src={require(`@assets/images/headerLogo-text.png`)}
                    variant="square"
                    sx={{
                      height: 28,
                      width: {
                        xl: 92,
                        lg: 92,
                        md: 92,
                        sm: 0,
                        xs: 0,
                      },
                      ml: 1
                    }}
                  />
                </Box>
              </RouterLink>
              {/* <Box display='flex' gap={2.5} alignItems='center'>
              <CustomSearch width='300px' placeholder={t('header:WHAT_DO_YOU_WANT_TO_LEARN')} />
            </Box> */}
              {profile.authorityInfo.orgId !== 104 &&
                <Box sx={{
                  display: "flex",
                  gap: '30px',
                  alignItems: "center",
                  px: { xl: 1, lg: 0.5, md: 0.5, sm: 0.5, xs: 0.5 },
                }}>
                  <Typography
                    sx={{
                      fontWeight: 500, cursor: "pointer",
                      "&:hover": {
                        color: common.primaryColor
                      },
                      color: pathname === '/freeCourseCatalogue' ? common.primaryColor : null
                    }}
                    onClick={() => navigate('/freeCourseCatalogue')}
                  >
                    {t('header:COURSES')}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500, cursor: "pointer",
                      "&:hover": {
                        color: common.primaryColor
                      },
                      color: search.includes("ORGANIZATION") ? common.primaryColor : null
                    }}
                    onClick={() => navigate(`/?variant=${HomeVariant.ORGANIZATION}`)}
                  >
                    {t('header:FOR_ORGANIZATION')}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500, cursor: "pointer",
                      "&:hover": {
                        color: common.primaryColor
                      },
                      color: search === '?variant=EDUCATOR' ? common.primaryColor : null
                    }}
                    onClick={() => navigate(`/?variant=${HomeVariant.EDUCATOR}`)}
                  >
                    {t('header:FOR_TEACHER')}
                  </Typography>
                </Box>}
              {profile.pltaId &&
                <Typography
                  sx={{
                    fontWeight: 500, cursor: "pointer",
                    "&:hover": {
                      color: common.primaryColor
                    },
                    color: (pathname === '/myCourses' || (role === Role.SUPER_ADMIN && pathname === '/profile') || pathname === '/courses') ? common.primaryColor : null
                  }}
                  onClick={() => navigate(`${role === Role.SUPER_ADMIN ? '/profile' : (role === Role.ADMIN || role === Role.LOCAL_ADMIN) ? '/courses' : '/myCourses'}`)}
                >
                  {t('header:ACCOUNT')}
                </Typography>
              }
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1
            }}
          >
            {!profile.pltaId ?
              <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                <Typography
                  onClick={() => navigate('registration')}
                  sx={{
                    ml: 0.5, mr: 5,
                    fontWeight: 500,
                    cursor: "pointer",
                    "&:hover": {
                      color: common.primaryColor
                    }
                  }}
                >
                  {t("REGISTRATION")}
                </Typography>
                <CustomButton
                  backgroundColor={common.primaryColor}
                  width="145px"
                  height="40px"
                  borderRadius="6px"
                  fontColor={common.fontWhite}
                  onClick={() => login()}
                >
                  {t("LOGIN")}
                </CustomButton>
              </Box> :
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  pr: { xl: 2, lg: 1, md: 0.5, sm: 0.3, xs: 0.2 },
                }}
              >
                {role !== Role.SUPER_ADMIN && role !== Role.ADMIN && role !== Role.LOCAL_ADMIN &&
                  <Badge
                    badgeContent={unreadNotificationsCount}
                    color="error"
                    onClick={handleClick}
                    sx={{
                      "& .MuiBadge-badge": { fontSize: 10, height: 15, minWidth: 15 }
                    }}
                  >
                    <Avatar
                      alt="Notification"
                      src={require("@assets/images/icon_notification.png")}
                      variant="square"
                      sx={{
                        width: "36px",
                        height: "36px",
                        cursor: "pointer",
                        color: "white",
                      }}
                    />
                  </Badge>
                }
                <AvatarPicture
                  id={profile.profilePhotoId}
                  lastName={profile.lastName || undefined}
                  sxProps={{
                    width: "36px",
                    height: "36px",
                    color: "white",
                    mx: { xl: 2, lg: 1, md: 0.5, sm: 0.3, xs: 0.2 }
                  }}
                />
                <ProfileMenu profile={profile} resetProfile={() => dispatch({ type: 'ACCOUNT/UPDATE_PROFILE_INFO_SUCCESS', payload: profileInitialDto })} />
              </Box>
            }

            {pathname !== "/login" && pathname !== "/registration" &&
              <CustomAutocomplete
                name="lang"
                options={langOptions}
                clearable={false}
                onChange={(_, value: string) => changeLanguage(value)}
                value={i18n.language}
                customIconDisabled={false}
              />
            }
          </Box>
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{ ml: "-220px" }}
          disableScrollLock
        >
          <PopoverNotification onClickNotificationBox={onClickNotificationBox} handleClose={handleClose} />
        </Popover>
      </Box>
      <Box sx={{
        display: "none",
        padding: "12px 24px",
        '@media (max-width:960px)': {
          display: 'flex',
          justifyContent: "space-between"
        },
      }}>
        <PlatonusSvg />
      </Box>
    </>
  );
};

export default Header;