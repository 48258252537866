import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Formik, Form as FormikForm } from "formik";
import Box from "@mui/material/Box";
import { AlertColor } from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";

import SlideNotification from "@components/SlideNotification";
import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import { serviceCourse } from "@services/course";
import { StateSchema } from "@store/StateSchema";
import { ICourse, CourseStatus, CourseFormatType } from "@interfaces/index";
import CustomButton from "@ui/Button";
import FormikInput from "@ui/formik/Input";
import { TextEditor } from "@ui/formik/TextEditor";
import { eduCourseValidationSchema } from "./validation";
import SaveChangesModal from "@components/modals/SaveChangesModal";

interface Props {
  courseInfo: ICourse;
  setCourseInfo: (courseInfo: any) => void;
  setMessageOpen: (isOpen: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  setMessage: (message: string) => void;
}

const ParamsPageEdu = ({
  courseInfo,
  setCourseInfo,
  setMessageOpen,
  setMessageType,
  setMessage
}: Props) => {
  const { courseId } = useParams();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["common", "course"]);
  const profileId = useSelector((store: StateSchema) => store.account.profileInfo.pltaId);
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [enableBtnShortDescription, setEnableBtnShortDescription] = useState<boolean>(true);
  const [enableBtnDescription, setEnableBtnDescription] = useState<boolean>(true);
  const isSaved = courseInfo.status === CourseStatus.SAVED;
  const isWithdrawn = courseInfo.status === CourseStatus.WITHDRAWN;
  const isClosed = courseInfo.status === CourseStatus.CLOSED;
  const isPublished = courseInfo.status === CourseStatus.PUBLISHED;
  const isAuthor = courseInfo?.authors?.some((author) => author.userId === profileId);

  const handleSubmit = (values: ICourse) => {
    values.courseSetting.formatType = !values.courseSetting.formatType
      ? CourseFormatType.SYNC
      : values.courseSetting.formatType;

      if (courseId) {
      serviceCourse
        .updateCourse(courseId, values)
        .then((res) => {
          if (res.status === 200) {
            setCourseInfo(values);
            setMessageOpen(true);
            setMessageType("success");
            setMessage(t("common:messages.DATA_CHANGED_SUCCESSFULLY"));
            setTimeout(() => {
              setMessageOpen(false);
            }, 5000);
            const newCrumbs = [...getBreadcrumbs(courseId)].map((crumb) => {
              // If the crumb to update is found, replace the crumb with updated fields
              if (crumb.level === 1) {
                return { ...crumb, label: values.name };
              }
              // Otherwise, return the original crumb
              return crumb;
            });
            setBreadcrumbs(courseId, newCrumbs);
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            setNotificationOpen(true);
            setTimeout(() => {
              setNotificationOpen(false);
            }, 3000);
          }
        })
        .finally(() => {
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }, 25);
        });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <SlideNotification
        notiOpen={notificationOpen}
        isNotiOpen={setNotificationOpen}
      />
      <Formik
        initialValues={courseInfo}
        enableReinitialize
        validationSchema={eduCourseValidationSchema(t)}
        onSubmit={handleSubmit}
      >
        {({ submitForm, dirty, isValid }) => {
          return (
            <FormikForm>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <FormikInput
                  name="name"
                  label={t("course:COURSE_NAME")}
                  isRequired
                  disabled={!(isSaved || isWithdrawn) || !isAuthor || isClosed || isPublished || !!courseInfo.externalId}
                  maxChar={60}
                  showSymbolCount
                />
                <TextEditor
                  name="shortDescription"
                  label={t("course:SHORT_DESCRIPTION_OF_COURSE")}
                  readOnly={!(isSaved || isWithdrawn) || !isAuthor || isClosed || isPublished}
                  shortToolbar
                  showInline
                  isRequired
                  showSymbolCount
                  minChar={32}
                  maxChar={1024}
                  setIsValidSubmitBtn={setEnableBtnShortDescription}
                />
                <TextEditor
                  name="description"
                  label={t("course:COURSE_DESCRIPTION")}
                  readOnly={!(isSaved || isWithdrawn) || !(isAuthor || !!courseInfo?.externalId) || isClosed || isPublished}
                  shortToolbar
                  showInline
                  isRequired
                  showSymbolCount
                  minChar={64}
                  maxChar={5120}
                  setIsValidSubmitBtn={setEnableBtnDescription}
                />
                <FormikInput
                  label={t("course:COURSE_VOLUME")}
                  name="credits"
                  type="number"
                  isRequired
                  min={0}
                  disabled={!(isSaved || isWithdrawn) || !isAuthor || isClosed || isPublished || !!courseInfo.externalId}
                />
                {(isAuthor || !!courseInfo?.externalId) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <CustomButton
                      backgroundColor={common.primaryColor}
                      fontColor={common.fontWhite}
                      width="98px"
                      borderRadius="6px"
                      height="38px"
                      disabled={
                        !(
                          isValid && dirty &&
                          enableBtnDescription &&
                          enableBtnShortDescription
                        ) || isPublished || isClosed || !(isAuthor || !!courseInfo?.externalId)
                      }
                      onClick={submitForm}
                    >
                      {t("common:actions.SAVE")}
                    </CustomButton>
                  </Box>
                )}
              </Box>
              <SaveChangesModal dirty={dirty} />
            </FormikForm>
          )
        }}
      </Formik>
    </Box>
  );
};

export default ParamsPageEdu;