import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material'
import UnhiddenHints from '@components/UnhiddenHints'
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import Breadcrumbs from '@components/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { CourseStatus, IBreadcrumb } from '@interfaces/index';
import CustomButton from '@ui/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { CoAuthorsList } from './CoAuthorsList';
import { CoAuthorModal } from './CoAuthorModal';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { serviceCourse } from '@services/course';

export const CoAuthorsPage = () => {
  const { t } = useTranslation(['user', 'course', 'enum', 'common', 'profile']);
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const { courseId } = useParams()
  const [openModal, setOpenModal] = useState<boolean>(false)
  useEffect(() => {
    const updatedCrumbs = [...getBreadcrumbs(courseId || "")].filter((x: IBreadcrumb) => x.level !== 4 && x.level !== 3);
    updatedCrumbs.push({
      level: 3,
      page: 'coAuthors',
      label: "course:coAuthors.CO_AUTHORS_COURSE",
      path: null,
      tabNum: null
    })
    if (JSON.stringify(updatedCrumbs) !== JSON.stringify(getBreadcrumbs(courseId || ""))) {
      if (courseId) setBreadcrumbs(courseId, updatedCrumbs);
    }
    // eslint-disable-next-line
  }, [])
  const { data: courseInfo } = useQuery({
    queryKey: ["getCourseInfoAuthors", courseId],
    queryFn: () => serviceCourse.getCourseInfo(courseId || ""),
    placeholderData: keepPreviousData
  })
  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: 'center' }}>
        <Breadcrumbs id={courseId} />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: { sm: "36px", xs: '28px' },
            lineHeight: "44px",
            m: "24px 0px"
          }}
        >{t("course:coAuthors.CO_AUTHORS_COURSE")}</Typography>
        <CustomButton width='135px' height='34px' disabled={courseInfo?.data.status === CourseStatus.CLOSED} borderRadius="4px" onClick={() => setOpenModal(!openModal)}>
          <AddOutlinedIcon />
          <Typography>{t("common:actions.ADD")}</Typography>
        </CustomButton>
      </Box>
      <UnhiddenHints text={t("course:messages.CO_AUTHORS_PAGE_HINT")} />
      <CoAuthorsList courseInfo={courseInfo} />
      <CoAuthorModal openModal={openModal} setOpenModal={setOpenModal} />
    </Box>
  )
}
