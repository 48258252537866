import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CustomSearch from "@ui/Search";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import TitleIcon from "@mui/icons-material/Title";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import LinkIcon from "@mui/icons-material/Link";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import { format, isBefore } from "date-fns";

import { serviceCourse } from "@services/course";
import { getFileType } from "@utils/functions";
import { PDFIcon } from "@assets/svg_files/Icons";
import FileViewerModal from "@components/modals/FileViewerModal";
import { useTranslation } from "react-i18next";
import { CourseAdditionalResource } from "@models/course-additional-resource";
import { resourceInitialDto } from "@pages/myCourses/Educator/TabList/Content/AdditionalResource/models";
import { ContentType } from "@interfaces/index";
import { useCourseContext } from "@context/CourseContext";
import { ContentAccessExpirationStatus } from "@interfaces/status";
import { CourseStatusEnum } from "@models/course";

export const ContentCompletedCourses = () => {
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const [contentList, setContentList] = useState<any[]>([]);
  const { classId } = useParams();
  const { courseData } = useCourseContext();
  const courseId = courseData?.courseId;
  const [search, setSearch] = useState<string>("");
  const [additionalList, setAdditionalList] = useState<Array<any>>([]);
  const [additionalResource, setAdditionalResource] =
    useState<CourseAdditionalResource>(resourceInitialDto);
  const [openFileModal, setOpenFileModal] = useState(false);
  const { t } = useTranslation(["enum", "course", "common"]);
  const location = useLocation();
  const [canStudentViewAfterEnd, setCanStudentViewAfterEnd] =
    useState<boolean>(false);

  const now = new Date();

  const nextMonth = new Date(now);
  nextMonth.setMonth(now.getMonth() + 1);
  const threeMonthsFromNow = new Date(now);
  threeMonthsFromNow.setMonth(now.getMonth() + 3);

  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  const millisecondsInOneMonthExact = nextMonth.getTime() - now.getTime();
  const millisecondsInThreeMonthsExact =
    threeMonthsFromNow.getTime() - now.getTime();

  const handleToggleFileModal = (
    modalState: boolean,
    resource?: CourseAdditionalResource
  ) => {
    if (resource) setAdditionalResource(resource);
    setOpenFileModal(modalState);
  };

  useEffect(() => {
    if (courseId) {
      serviceCourse.getCourseInfo(courseId).then((res) => {
        setCanStudentViewAfterEnd(
          res.data.courseSetting.canStudentViewAfterEnd
        );
      });
    }
  }, [courseId]);

  let sectionCount = 0;
  let themeCount = 0;
  let taskCount = 0;
  let numberSection = 1;

  for (const rootItem of contentList) {
    if (rootItem.type === "SECTION") {
      sectionCount++;
    }
    for (const childItem of rootItem.children) {
      if (childItem.type === "THEME") {
        themeCount++;
      }
      for (const child2Item of childItem.children) {
        if (child2Item.type === "TASK") {
          taskCount++;
        }
      }
    }
  }

  const getContentAvailable = (
    canStudentViewAfterEnd,
    contentAccessExpiration,
    courseData
  ) => {
    if (!canStudentViewAfterEnd) {
      return true;
    }

    const endDate = new Date(courseData?.endDate).getTime();
    const now = Date.now();

    switch (contentAccessExpiration) {
      case ContentAccessExpirationStatus.IMMEDIATELY_AFTER_COMPLETION:
        return false;
      case ContentAccessExpirationStatus.ONE_WEEK_AFTER_COMPLETION:
        return endDate + oneWeekInMilliseconds > now;
      case ContentAccessExpirationStatus.ONE_MONTH_AFTER_COMPLETION:
        return endDate + millisecondsInOneMonthExact > now;
      case ContentAccessExpirationStatus.THREE_MONTHS_AFTER_COMPLETION:
        return endDate + millisecondsInThreeMonthsExact > now;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (classId) {
      serviceCourse
        .getListenerContentList({ name: search, courseId, classId })
        .then((res) => {
          if (res.status === 200)
            setContentList(
              res.data.map((item) => ({
                ...item,
                available: false,
              }))
            );
        });
    }
  }, [classId, search, courseId]);

  useEffect(() => {
    if (classId) {
      serviceCourse.getResourceList(classId).then((res) => {
        if (res.status === 200) setAdditionalList(res.data);
      });
    }
  }, [classId]);

  return (
    <Box
      sx={{
        background: "#fff",
        padding: location.pathname.includes("myCourses") ? "15px" : 0,
        borderRadius: "6px",
        border: `1px solid ${common.borderSecondary}`,
      }}
    >
      {location.pathname.includes("myCourses") && (
        <CustomSearch
          placeholder={t("common:placeholders.SEARCH_BY_NAME")}
          height="44px"
          onChange={setSearch}
        />
      )}
      {location.pathname.includes("courseCatalogue") && (
        <Box>
          <Box>{t("course:CONTENT_COURSE")}</Box>
          <Box display="flex" gap={1} mb={2} alignItems="center">
            <Typography>
              {t("course:SECTIONS")}: {contentList.length}
            </Typography>
            <Box
              sx={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor: "#D9D9D9",
              }}
            ></Box>
            <Typography>
              {t("course:THEMES")}: {themeCount}
            </Typography>
            <Box
              sx={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor: "#D9D9D9",
              }}
            ></Box>
            <Typography>
              {t("course:TASKS")}: {taskCount}
            </Typography>
          </Box>
        </Box>
      )}
      {contentList.length === 0 && additionalList.length === 0 && (
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              alt="Content-default-bg-img"
              src={require(`@assets/images/emptyDataBg.png`)}
              variant="square"
              sx={{
                height: "auto",
                width: "50%",
                mb: "8px",
              }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: { sm: "16px", xs: "14px" },
                lineHeight: "20px",
                mt: "32px",
                mb: "169px",
              }}
            >
              {t("course:content.NO_MATERIALS_YET")}
            </Typography>
          </Box>
        </Box>
      )}
      {contentList.map((content, index) => {
        return (
          <Accordion
            key={index}
            defaultExpanded={true}
            disableGutters={true}
            sx={{
              backgroundColor: common.secondaryColor,
              border: "1px solid #CACACA",
              borderRadius: 1,
              mt: 3,
            }}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {content.type === ContentType.SECTION && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: { xs: "16px", sm: "20px" },
                          lineHeight: "18px",
                        }}
                      >
                        {t("course:section.SECTION")} {numberSection++}:{" "}
                        {content.name}
                      </Typography>
                      <ExpandMoreIcon />
                    </Box>
                  </Box>
                )}
                {content.type === ContentType.TASK ? (
                  content.dateOfAccess &&
                  isBefore(new Date(), new Date(content.dateOfAccess)) ? (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        display="flex"
                        marginLeft="10px"
                        alignItems="center"
                        gap={1}
                      >
                        <NoteAltOutlinedIcon />
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "20px",
                            lineHeight: "18px",
                          }}
                        >
                          {content.name}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        sx={{
                          borderRadius: "10px",
                          background: "#C1C1C1",
                          width: "200px",
                        }}
                        padding="3px 5px"
                        gap={1}
                      >
                        <LockOutlinedIcon />
                        <Typography>
                          {format(
                            new Date(content.dateOfAccess),
                            "dd.MM.yyyy HH:mm"
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        width: "100%",
                        ":hover": getContentAvailable(
                          courseData?.canStudentViewAfterEnd,
                          courseData?.contentAccessExpiration,
                          courseData
                        ) ? {
                          color:  common.primaryColor,
                          cursor: "pointer",
                        }:undefined,
                      }}
                      onClick={() =>
                        getContentAvailable(
                          courseData?.canStudentViewAfterEnd,
                          courseData?.contentAccessExpiration,
                          courseData
                        ) &&
                        navigate(
                          `/myCourses/current/${classId}/tasks/${content.id}`
                        )
                      }
                    >
                      <NoteAltOutlinedIcon />
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "20px",
                          lineHeight: "18px",
                        }}
                      >
                        {content.name}
                      </Typography>
                    </Box>
                  )
                ) : null}

                {content.type === ContentType.THEME && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box display="flex" gap={1}>
                      <DescriptionOutlinedIcon />
                      <Typography
                        onClick={() => {
                          if (location.pathname.includes("courseCatalogue")) {
                            navigate(
                              `/courseCatalogue/${classId}/theme/${content.id}`
                            );
                          } else {
                            getContentAvailable(
                              courseData?.canStudentViewAfterEnd,
                              courseData?.contentAccessExpiration,
                              courseData
                            ) &&
                              navigate(
                                `/myCourses/${classId}/theme/${content.id}`
                              );
                          }
                        }}
                        sx={{
                          fontWeight: 600,
                          fontSize: "20px",
                          lineHeight: "18px",
                        }}
                      >
                        {content.name}
                      </Typography>
                      <ExpandMoreIcon />
                    </Box>
                    {/* {isCourseIndefinite && (
                      <Typography>
                        {formatTime(content.estimatedTime)}
                      </Typography>
                    )} */}
                  </Box>
                )}
              </Box>
            </AccordionSummary>

            {(content.type === ContentType.SECTION ||
              content.type === ContentType.THEME) && (
              <AccordionDetails
                sx={{ display: "flex", flexDirection: "column", p: 0 }}
              >
                {content?.shortDescription && (
                  <Box
                    sx={{
                      pl: 2,
                      pr: 2,
                      py: 1,
                      borderTop: `1px solid #CACACA`,
                      backgroundColor: common.fontWhite,
                      fontSize: "14px",
                    }}
                  >
                    <Typography color={common.primaryColor}>
                      {t("course:section.SHORT_DESCRIPTION")}:
                    </Typography>
                    <Typography>{content.shortDescription}</Typography>
                  </Box>
                )}
                {content?.children
                  .filter((node) => node.status === CourseStatusEnum.PUBLISHED)
                  .map((child, index) =>
                    child.children.length > 0 ? (
                      <Accordion
                        key={index}
                        defaultExpanded={true}
                        disableGutters={true}
                        sx={{
                          borderRadius: 1,
                        }}
                        TransitionProps={{ unmountOnExit: true }}
                      >
                        <AccordionSummary
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            display="flex"
                            width="100%"
                            gap={2}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box display="flex" gap={1}>
                              <DescriptionOutlinedIcon />
                              <Typography
                                sx={{
                                  ":hover": {
                                    color:
                                      child.dateOfAccess &&
                                      getContentAvailable(
                                        courseData?.canStudentViewAfterEnd,
                                        courseData?.contentAccessExpiration,
                                        courseData
                                      ) &&
                                      isBefore(
                                        new Date(),
                                        new Date(child.dateOfAccess)
                                      )
                                        ? null
                                        : common.primaryColor,
                                  },
                                }}
                                onClick={() => {
                                  if (
                                    child.dateOfAccess &&
                                    isBefore(
                                      new Date(),
                                      new Date(child.dateOfAccess)
                                    )
                                  ) {
                                    return null;
                                  } else {
                                    if (
                                      location.pathname.includes(
                                        "courseCatalogue"
                                      )
                                    ) {
                                      navigate(
                                        `/courseCatalogue/${classId}/theme/${child.id}`
                                      );
                                    } else {
                                      getContentAvailable(
                                        courseData?.canStudentViewAfterEnd,
                                        courseData?.contentAccessExpiration,
                                        courseData
                                      ) &&
                                        navigate(
                                          `/myCourses/${classId}/theme/${child.id}`
                                        );
                                    }
                                  }
                                }}
                              >
                                {child.name}
                              </Typography>
                              <ExpandMoreIcon />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexDirection: "column",
                                alignItems: "flex-end",
                                gap: 1,
                              }}
                            >
                              {/* {isCourseIndefinite && (
                                <Box
                                  sx={{
                                    maxWidth: "110px",
                                    borderRadius: "120px",
                                    backgroundColor: "rgba(228, 212, 255, 1)",
                                    p: "2px 12px",
                                    color: "rgba(148, 105, 227, 1)",
                                  }}
                                >
                                  {formatTime(child.estimatedTime)}
                                </Box>
                              )} */}
                              {child.dateOfAccess &&
                                isBefore(
                                  new Date(),
                                  new Date(child.dateOfAccess)
                                ) && (
                                  <Box
                                    display="flex"
                                    sx={{
                                      borderRadius: "10px",
                                      background: "#C1C1C1",
                                      width: "200px",
                                    }}
                                    padding="3px 5px"
                                    gap={1}
                                  >
                                    <LockOutlinedIcon />
                                    <Typography>
                                      {format(
                                        new Date(child.dateOfAccess),
                                        "dd.MM.yyyy HH:mm"
                                      )}
                                    </Typography>
                                  </Box>
                                )}
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          {isBefore(new Date(), new Date(child.dateOfAccess))
                            ? child.children
                                .filter((node) => node.status === "PUBLISHED")
                                .map((node, index) => (
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    key={index}
                                  >
                                    <Box
                                      display="flex"
                                      gap={2}
                                      marginLeft="10px"
                                      marginTop="10px"
                                    >
                                      <NoteAltOutlinedIcon />
                                      <Typography>{node.name}</Typography>
                                    </Box>
                                    {isBefore(
                                      new Date(),
                                      new Date(node.dateOfAccess)
                                    ) && (
                                      <Box
                                        display="flex"
                                        sx={{
                                          borderRadius: "10px",
                                          background: "#C1C1C1",
                                          width: "200px",
                                        }}
                                        padding="3px 5px"
                                        gap={1}
                                      >
                                        <LockOutlinedIcon />
                                        <Typography>
                                          {format(
                                            new Date(node.dateOfAccess),
                                            "dd.MM.yyyy HH:mm"
                                          )}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                ))
                            : child.children
                                .filter((node) => node.status === "PUBLISHED")
                                .map((node, index) =>
                                  node.dateOfAccess &&
                                  isBefore(
                                    new Date(),
                                    new Date(node.dateOfAccess)
                                  ) ? (
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      key={index}
                                    >
                                      <Box
                                        display="flex"
                                        gap={2}
                                        marginLeft="10px"
                                        marginTop="10px"
                                      >
                                        <NoteAltOutlinedIcon />
                                        <Typography>{node.name}</Typography>
                                      </Box>
                                      <Box
                                        display="flex"
                                        sx={{
                                          borderRadius: "10px",
                                          background: "#C1C1C1",
                                          width: "200px",
                                        }}
                                        padding="3px 5px"
                                        gap={1}
                                      >
                                        <LockOutlinedIcon />
                                        <Typography>
                                          {format(
                                            new Date(node.dateOfAccess),
                                            "dd.MM.yyyy HH:mm"
                                          )}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box
                                      display="flex"
                                      gap={2}
                                      marginLeft="10px"
                                      marginTop="10px"
                                      key={index}
                                      justifyContent="space-between"
                                    >
                                      <Box display="flex" gap={1}>
                                        <NoteAltOutlinedIcon />
                                        <Typography
                                          sx={{
                                            ":hover":  getContentAvailable(
                                              courseData?.canStudentViewAfterEnd,
                                              courseData?.contentAccessExpiration,
                                              courseData
                                            ) ? {
                                              color: common.primaryColor,
                                              cursor: "pointer",
                                            }:undefined,
                                          }}
                                          onClick={() =>
                                            getContentAvailable(
                                              courseData?.canStudentViewAfterEnd,
                                              courseData?.contentAccessExpiration,
                                              courseData
                                            ) &&
                                            navigate(
                                              `/myCourses/current/${classId}/tasks/${node.id}`
                                            )
                                          }
                                        >
                                          {node.name}
                                        </Typography>
                                      </Box>
                                      {/* {isCourseIndefinite && (
                                        <Box
                                          sx={{
                                            borderRadius: "120px",
                                            backgroundColor:
                                              "rgba(228, 212, 255, 1)",
                                            p: "2px 12px",
                                            color: "rgba(148, 105, 227, 1)",
                                          }}
                                        >
                                          15 мин (хардкод)
                                        </Box>
                                      )} */}
                                    </Box>
                                  )
                                )}
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          pl: 2,
                          pr: 2,
                          py: 1,
                          borderTop: `1px solid #CACACA`,
                          backgroundColor: common.fontWhite,
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {child.type === ContentType.THEME && (
                          <>
                            {!child.dateOfAccess ? (
                              <Box
                                display="flex"
                                gap={2}
                                justifyContent="space-between"
                                width="100%"
                              >
                                <Box sx={{ display: "flex", gap: 1 }}>
                                  <DescriptionOutlinedIcon />
                                  <Typography
                                    sx={{
                                      ":hover": getContentAvailable(
                                        courseData?.canStudentViewAfterEnd,
                                        courseData?.contentAccessExpiration,
                                        courseData
                                      ) ? {
                                        color: common.primaryColor,
                                        cursor: "pointer",
                                      }:undefined,
                                    }}
                                    onClick={() =>
                                      getContentAvailable(
                                        courseData?.canStudentViewAfterEnd,
                                        courseData?.contentAccessExpiration,
                                        courseData
                                      ) &&
                                      navigate(
                                        `/myCourses/${classId}/theme/${child.id}`
                                      )
                                    }
                                  >
                                    {child.name}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : child.dateOfAccess &&
                              isBefore(
                                new Date(),
                                new Date(child.dateOfAccess)
                              ) ? (
                              <Box display="flex" gap={2}>
                                <DescriptionOutlinedIcon />
                                <Typography>{child.name}</Typography>
                              </Box>
                            ) : (
                              <Box
                                display="flex"
                                gap={2}
                                onClick={() => {
                                  getContentAvailable(
                                    courseData?.canStudentViewAfterEnd,
                                    courseData?.contentAccessExpiration,
                                    courseData
                                  ) &&
                                    navigate(
                                      `/myCourses/${classId}/theme/${child.id}`
                                    );
                                }}
                              >
                                <DescriptionOutlinedIcon />
                                <Typography
                                  sx={{
                                    ":hover": getContentAvailable(
                                      courseData?.canStudentViewAfterEnd,
                                      courseData?.contentAccessExpiration,
                                      courseData
                                    ) ? {
                                      color: common.primaryColor,
                                      cursor: "pointer",
                                    }:undefined,
                                  }}
                                >
                                  {child.name}
                                </Typography>
                              </Box>
                            )}
                          </>
                        )}
                        {child.type === ContentType.TASK && (
                          <>
                            {!child.dateOfAccess ? (
                              <Box
                                display="flex"
                                gap={2}
                                justifyContent="space-between"
                                width="100%"
                              >
                                <Box sx={{ display: "flex", gap: 1 }}>
                                  <NoteAltOutlinedIcon />
                                  <Typography
                                    sx={{
                                      ":hover": getContentAvailable(
                                        courseData?.canStudentViewAfterEnd,
                                        courseData?.contentAccessExpiration,
                                        courseData
                                      ) ? {
                                        color: common.primaryColor,
                                        cursor: "pointer",
                                      }:null,
                                    }}
                                    onClick={() =>
                                      getContentAvailable(
                                        courseData?.canStudentViewAfterEnd,
                                        courseData?.contentAccessExpiration,
                                        courseData
                                      ) &&
                                      navigate(
                                        `/myCourses/current/${classId}/tasks/${child.id}`
                                      )
                                    }
                                  >
                                    {child.name}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : child.dateOfAccess &&
                              isBefore(
                                new Date(),
                                new Date(child.dateOfAccess)
                              ) ? (
                              <Box display="flex" gap={2}>
                                <NoteAltOutlinedIcon />
                                <Typography>{child.name}</Typography>
                              </Box>
                            ) : (
                              <Box
                                display="flex"
                                gap={2}
                                onClick={() => {
                                  getContentAvailable(
                                    courseData?.canStudentViewAfterEnd,
                                    courseData?.contentAccessExpiration,
                                    courseData
                                  ) &&
                                    navigate(
                                      `/myCourses/current/${classId}/tasks/${child.id}`
                                    );
                                }}
                              >
                                <NoteAltOutlinedIcon />
                                <Typography
                                  sx={{
                                    ":hover":  getContentAvailable(
                                      courseData?.canStudentViewAfterEnd,
                                      courseData?.contentAccessExpiration,
                                      courseData
                                    ) ?{
                                      color: common.primaryColor,
                                      cursor: "pointer",
                                    }:null,
                                  }}
                                >
                                  {child.name}
                                </Typography>
                              </Box>
                            )}
                          </>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            alignItems: "flex-end",
                          }}
                        >
                          {/* {isCourseIndefinite && (
                            <Box
                              sx={{
                                borderRadius: "120px",
                                backgroundColor: "rgba(228, 212, 255, 1)",
                                p: "2px 12px",
                                color: "rgba(148, 105, 227, 1)",
                                width: "160px",
                              }}
                            >
                              15 мин (хардкод)
                            </Box>
                          )} */}
                          {isBefore(
                            new Date(),
                            new Date(child.dateOfAccess || "")
                          ) && (
                            <Box
                              display="flex"
                              sx={{
                                borderRadius: "10px",
                                background: "#C1C1C1",
                                width: "200px",
                              }}
                              padding="3px 5px"
                              gap={1}
                            >
                              <LockOutlinedIcon />
                              <Typography>
                                {format(
                                  new Date(child.dateOfAccess),
                                  "dd.MM.yyyy HH:mm"
                                )}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )
                  )}
              </AccordionDetails>
            )}
          </Accordion>
        );
      })}
      {additionalList.length > 0 && (
        <Accordion
          defaultExpanded={true}
          disableGutters={true}
          sx={{
            backgroundColor: common.secondaryColor,
            border: "1px solid #CACACA",
            borderRadius: 1,
            mt: 3,
          }}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: "16px", sm: "20px" },
                  lineHeight: "18px",
                }}
              >
                {t("enum:ADDITIONAL_RESOURCES")}
              </Typography>
              <ExpandMoreIcon />
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: "flex", flexDirection: "column", p: 0 }}
          >
            {additionalList.map((additional) => (
              <Box
                key={additional.id}
                sx={{
                  display: "flex",
                  pl: 2,
                  py: 1,
                  borderTop: `1px solid #CACACA`,
                  backgroundColor: common.fontWhite,
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                <Box sx={{ cursor: "pointer" }}>
                  {additional?.name &&
                    getFileType(additional?.name) === "pdf" && <PDFIcon />}
                  {additional?.name &&
                    getFileType(additional?.name) === "image" && (
                      <PhotoOutlinedIcon />
                    )}
                  {additional?.name &&
                    getFileType(additional?.name) === "audio" && (
                      <PlayCircleOutlinedIcon />
                    )}
                  {additional?.resourceType === "TEXT" && <TitleIcon />}
                  {additional?.link && <LinkIcon />}
                </Box>
                <Typography
                  sx={{ cursor: "pointer" }}
                  onClick={
                    getContentAvailable(
                      courseData?.canStudentViewAfterEnd,
                      courseData?.contentAccessExpiration,
                      courseData
                    ) && additional?.link
                      ? () => window.open(`${additional.link}`, "_blank")
                      : () => handleToggleFileModal(true, additional)
                  }
                >
                  {additional?.name}
                </Typography>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
      {openFileModal && (
        <FileViewerModal
          openModal={openFileModal}
          handleToggleModal={handleToggleFileModal}
          file={additionalResource}
        />
      )}
    </Box>
  );
};
