import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme, Popover, Grid } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BallotIcon from "@mui/icons-material/Ballot";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import {
  formatDateWithHourMinute,
  getBase64,
  getLangDictById,
  removeHtml,
} from "@utils/functions";
import { IBreadcrumb } from "@interfaces/index";
import { serviceFile } from "@services/file";
import { useLocation } from "react-router-dom";
import { serviceCourse } from "@services/course";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";
import CustomButton from "@ui/Button";
import RegisterToCourseModal from "@components/modals/RegisterToCourseModal";
import { StudyClass } from "@interfaces/studyClass";

interface IProps {
  courseInfo: StudyClass;
  isDisabled: boolean;
  status?: string;
}

const CourseCard = ({ courseInfo, isDisabled, status }: IProps) => {
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState<string>("");
  const { setBreadcrumbs } = useBreadcrumbs();
  const location = useLocation();
  const canSelfRegister = courseInfo?.canSelfRegistration;
  const { t } = useTranslation(["course"]);
  const isRegistrationActive =
    new Date() > new Date(courseInfo?.regStartDate || "") &&
    new Date() < new Date(courseInfo?.regEndDate || "");
  const [isSelfReg, setIsSelfReg] = useState<boolean>(false);
  const [openRegisterToCourseModal, setOpenRegisterToCourseModal] =
    useState<boolean>(false);
  const [message, setMessage] = useState<string>(
    t("course:messages.ARE_YOU_SURE_WANT_TO_ENROLL_IN_COURSE")
  );
  const [success, setSuccess] = useState<boolean>(false);
  const [attention, setAttention] = useState<boolean>(false);
  const [studentCount, setStudentCount] = useState<number | null>(null);

  useEffect(() => {
    if (courseInfo?.course?.coverFileId) {
      serviceFile
        .getFileMimeType(courseInfo.course?.coverFileId)
        .then((res) => {
          const contentDisposition = res.headers["content-disposition"];
          const fileName = contentDisposition
            .split(";")[1]
            .split("filename")[1]
            .split("=")[1]
            .trim();
          const file = new File([res.data], fileName);
          getBase64(file, (url) => setFileUrl(url));
        });
    }
    if (location.pathname.includes("courseCatalogue")) {
      setStudentCount(courseInfo?.allStudentCount);
    }
  }, [courseInfo, location.pathname]);

  const onClickCourseCard = (
    courseName: string | null,
    classId: string | null
  ) => {
    const crumb: IBreadcrumb = {
      level: 1,
      page: "course",
      label: `${courseName}`,
      path: `/myCourses/${status?.toLowerCase()}/${classId}`,
      tabNum: null,
    };
    if (classId) setBreadcrumbs(classId, [crumb]);
    if (location.pathname.includes("courseCatalogue")) {
      navigate(
        `/courseCatalogue/course/${courseInfo?.course?.id}?classId=${courseInfo?.id}`
      );
    } else {
      navigate(`/myCourses/${status?.toLowerCase()}/${classId}`);
    }
  };
  const [hint, setHint] = useState<HTMLElement | null>(null);

  const hintPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHint(event.currentTarget);
  };

  const hintPopoverClose = () => {
    setHint(null);
  };
  const openHint = Boolean(hint);

  const registerToCourse = () => {
    if (courseInfo.id) {
      serviceCourse
        .signUpToCourse(courseInfo.id)
        .then((res) => {
          if (res.status === 200) {
            setStudentCount((prev) => prev! + 1);
            setSuccess(true);
            setMessage(t("course:messages.SUCESSFULLY_REGISTERED_TO_COURSE"));
            setIsSelfReg(true);
          }
        })
        .catch((err) => {
          setMessage(t("course:messages.FAILED_TO_ENROLL_IN_COURSE"));
          setAttention(true);
        })
        .finally(() => {
          setTimeout(() => {
            setOpenRegisterToCourseModal(false);
            setSuccess(false);
            setAttention(false);
            setMessage(
              t(
                "course:messages.ARE_YOU_SURE_WANT_TO_CANCEL_COURSE_REGISTRATION"
              )
            );
          }, 5000);
        });
    }
  };

  const cancelRegistration = () => {
    if (courseInfo.id) {
      serviceCourse
        .cancelClassRegistration(courseInfo.id)
        .then((res) => {
          if (res.status === 200) {
            setStudentCount((prev) => prev! - 1);
            setSuccess(true);
            setMessage(t("course:messages.REGISTRATION_TO_COURSE_CANCELED"));
            setIsSelfReg(false);
          }
        })
        .catch((err) => {
          setMessage(t("course:messages.FAILED_TO_CANCEL_COURSE_REGISTRATION"));
          setAttention(true);
        })
        .finally(() => {
          setTimeout(() => {
            setOpenRegisterToCourseModal(false);
            setSuccess(false);
            setAttention(false);
            setMessage(
              t("course:messages.ARE_YOU_SURE_WANT_TO_ENROLL_IN_COURSE")
            );
          }, 2000);
        });
    }
  };

  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <Box
        sx={{
          border: `1px solid ${common.borderSecondary}`,
          padding: "12px 12px",
          borderRadius: "9px",
          cursor: "pointer",
          transition: "transform 0.3s",
          "&:hover": {
            border: `1px solid ${common.primaryColor}`,
            boxShadow: 12,
          },
        }}
      >
        <Box
          onClick={() => {
            !isDisabled &&
              onClickCourseCard(courseInfo?.course?.name || "", courseInfo?.id);
          }}
        >
          <Box
            sx={{
              ...(fileUrl
                ? {
                    backgroundImage: `url(${fileUrl})`,
                    height: "143px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    position: "relative",
                    borderRadius: "4px",
                  }
                : {
                    position: "relative",
                    height: "143px",
                    backgroundColor: courseInfo?.course?.coverColor,
                    borderRadius: "4px",
                  }),
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "28px",
                height: "28px",
                borderRadius: "50%",
                background: `rgba(148, 105, 227,0.5)`,
                position: "absolute",
                top: "8px",
                left: "8px",
                cursor: "pointer",
                padding: "10px",
              }}
              onMouseEnter={hintPopoverOpen}
              onMouseLeave={hintPopoverClose}
            >
              <ErrorIcon sx={{ color: "white", opacity: 1, width: "20px" }} />
            </Box>
            {canSelfRegister && (
              <Box
                sx={{
                  backgroundColor: "#EDFF23",
                  borderRadius: "9px",
                  display: "flex",
                  alignItems: "bottom",
                  alignContent: "flex-end",
                  position: "absolute",
                  bottom: "8px",
                  right: "8px",
                  left: "8px",
                  width: "95%",
                }}
              >
                <Typography sx={{ fontSize: "12px", m: "8px", mr: "16px" }}>
                  <span style={{ marginRight: 5 }}>
                    {t("course:REGISTRATION_PERIOD")}:
                  </span>
                  {formatDateWithHourMinute(
                    courseInfo?.course?.courseSetting.regStartDate ??
                      courseInfo?.regStartDate
                  )}
                  -
                  {formatDateWithHourMinute(
                    courseInfo?.course?.courseSetting.regEndDate ??
                      courseInfo?.regEndDate
                  )}
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              fontWeight: "500",
              fontSize: { sm: "17px", xs: "15px" },
              cursor: "pointer",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              height: "50px",
              mt: "12px",
              lineHeight: "19px",
            }}
          >
            {courseInfo?.course?.name ?? courseInfo?.name}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: { sm: "center", xs: "flex-start" },
              color: common.borderSecondary,
              flexDirection: { xs: "column", sm: "row" },
              gap: 1,
              height: "30px",
              mb: { xs: 4, sm: 0 },
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                color: "#9C9C9C",
                width: "186px",
              }}
            >
              <CalendarMonthIcon
                sx={{ width: "20px", height: "20px", mr: "7px" }}
              />
              {formatDateWithHourMinute(courseInfo?.startDate)}-
              {formatDateWithHourMinute(courseInfo?.endDate)}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                color: "#9C9C9C",
              }}
            >
              <BallotIcon sx={{ width: "20px", height: "20px", mr: "7px" }} />
              {courseInfo?.course?.credits} {t("course:listener.CREDITS")}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: { sm: "center", xs: "flex-start" },
              color: common.borderSecondary,
              flexDirection: { xs: "column", sm: "row" },
              mt: 1,
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                color: "#9C9C9C",
                width: "186px",
              }}
            >
              <LanguageIcon sx={{ width: "20px", height: "20px", mr: "7px" }} />
              {getLangDictById(courseInfo?.course?.courseSetting.langId || 1)}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                color: "#9C9C9C",
              }}
            >
              <PeopleAltIcon
                sx={{ width: "20px", height: "20px", mr: "7px" }}
              />
              {location.pathname.includes("courseCatalogue")
                ? studentCount
                : courseInfo?.allStudentCount}
              /{courseInfo?.capacity}
            </Box>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={openHint}
              anchorEl={hint}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={hintPopoverClose}
              disableRestoreFocus
              disableScrollLock
            >
              <Box
                sx={{
                  p: 2,
                  color: common.fontWhite,
                  backgroundColor: "#1A1F36",
                  width: { sm: "318px", xs: "232px" },
                  fontSize: "12px",
                  fontFamily: "Golos",
                }}
              >
                {removeHtml(courseInfo?.course?.shortDescription || "")}
              </Box>
            </Popover>
          </Box>
        </Box>
        {location.pathname.includes("courseCatalogue") && (
          <Box
            sx={{
              mt: "14px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomButton
              backgroundColor={
                isSelfReg ? common.fontWhite : common.primaryColor
              }
              fontColor={!isSelfReg ? common.fontWhite : common.primaryColor}
              width="100%"
              borderRadius="6px"
              onClick={() => {
                setOpenRegisterToCourseModal(true);
              }}
              disabled={
                (courseInfo?.course?.courseSetting.capacity ===
                  courseInfo?.allStudentCount &&
                  !isSelfReg) ||
                !isRegistrationActive
              }
            >
              {isSelfReg
                ? t("course:listener.CANCEL_COURSE_REGISTRATION")
                : t("course:listener.SIGN_UP_FOR_THE_COURSE")}
            </CustomButton>
          </Box>
        )}
        {openRegisterToCourseModal && (
          <RegisterToCourseModal
            openModal={openRegisterToCourseModal}
            message={message}
            handleToggleModal={() => setOpenRegisterToCourseModal(false)}
            registerToCourse={isSelfReg ? cancelRegistration : registerToCourse}
            success={success}
            attention={attention}
          />
        )}
      </Box>
    </Grid>
  );
};

export default CourseCard;
