import background1 from "@assets/images/home/Slide1.png";
import background2 from "@assets/images/home/Slide2.png";
import background3 from "@assets/images/home/Slide3.png";
import background4 from "@assets/images/home/Slide4.png";
import background5 from "@assets/images/home/Slide5.png";
import background6 from "@assets/images/home/Slide6.png";
import background7 from "@assets/images/home/Slide7.png";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CarouselProps, HomeVariant } from "../types/home";
import CarouselBlock from "./CarouselBlock/CarouselBlock";
import { Role } from "@interfaces/index";

interface Props {
  variant: HomeVariant;
}

const CarouselComponent = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const userInfo = localStorage.getItem("userInfo");
  const role = JSON.parse(userInfo || "{}")?.authorityInfo?.role;
  const { variant } = props;
  const navigate = useNavigate();
  const { t } = useTranslation(["home", "course"]);
  const handleHomeVariant = (e: HomeVariant): CarouselProps[] => {
    switch (e) {
      case HomeVariant.ORGANIZATION:
        return [
          {
            backgroundImage: background4,
            button: t('home:CONTACT_US'),
            onClick: () => {
              searchParams.set("modal", "true");
              setSearchParams(searchParams);
            },
            title: t('home:TEACH_STUDENTS_THROUGH_ONLINE_COURSES'),
            text: t('home:CREATE_AND_MANAGE_YOUR_COURSES'),
            balance: true,
          },
          {
            backgroundImage: background5,
            button: t('home:CONTACT_US'),
            onClick: () => {
              searchParams.set("modal", "true");
              setSearchParams(searchParams);
            },
            title: t('home:TEACH_SKILLS_DEMANDED_BY_STUDENTS'),
            text: t('home:LEARNING_NEW_SKILLS_HELPS_STUDENTS_BE_BETTER'),
          },
        ];
      case HomeVariant.EDUCATOR:
        return [
          {
            backgroundImage: background6,
            button: (!role || role === Role.EDUCATOR || role === Role.MANAGER) ? t('course:CREATE_COURSE') : undefined,
            onClick: () => navigate(userInfo ? "/myCourses" : "/registration"),
            title: t('home:CREATE_DEMANDED_ONLINE_COURSES_WITH_EASE'),
            text: t('home:MANAGE_YOUR_COURSE_EFFORTLESSLY_AND_ATTRACT_STUDENTS'),
            maxWidth: "50%",
          },
          {
            backgroundImage: background7,
            button: (!role || role === Role.EDUCATOR || role === Role.MANAGER) ? t('course:CREATE_COURSE') : undefined,
            onClick: () => navigate(userInfo ? "/myCourses" : "/registration"),
            title: t('home:YOUR_COURSE_STARTS_HERE'),
            text: t('home:OPEN_ACCESS'),
            balance: true,
          },
        ];
      case HomeVariant.LISTENER:
        return [
          {
            backgroundImage: background1,
            button: t("home:CHOOSE_COURSE"),
            onClick: () => navigate("/freeCourseCatalogue"),
            title: t("home:BECOME_BEST_VERSION"),
            text: t("home:TURN_YOU_AMBITIONS"),
          },
          {
            backgroundImage: background2,
            button: t("home:CHOOSE_COURSE"),
            onClick: () => navigate("/freeCourseCatalogue"),
            title: t("home:SALES_COURSE"),
            text: t("home:GET_ACCESS_EDUCATION"),
          },
          {
            backgroundImage: background3,
            button: t("home:CHOOSE_COURSE"),
            onClick: () => navigate("/freeCourseCatalogue"),
            title: t("home:TAKE_BEST_COURSE"),
            text: t("home:OPEN_ACCESS"),
            maxWidth: "61%",
          },
        ];
      default:
        return [];
    }
  };
  return <CarouselBlock carousel={handleHomeVariant(variant)} />;
};

export default CarouselComponent;
