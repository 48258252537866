import { ReactComponent as InstagramIcon } from "@assets/svg_files/home/instagram.svg";
import { ReactComponent as LinkedIcon } from "@assets/svg_files/home/linked.svg";
import { ReactComponent as Plta } from "@assets/svg_files/home/platonus.svg";
import { ReactComponent as Platonus } from "@assets/svg_files/home/plta.svg";
import { ReactComponent as TictokIcon } from "@assets/svg_files/home/tiktok.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "../Container/Container";
import s from "./Footer.module.scss";
import { HomeVariant } from "../types/home";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const {t} = useTranslation(['home'])
  const navigate = useNavigate();
  const location = useLocation();
  if (location.pathname === "/registration") {
    return null;
  }
  return (
    <footer className={s.footer}>
      <Container className={s.container}>
        <div className={s.wrapper}>
          <div className={s.content}>
            <div className={s.logo}>
              <Plta onClick={() => navigate('/')}/>
              <Platonus onClick={() => window.open("https://platonus.kz/")} />
            </div>
            <div className={s.catalog}>
              <div className={s.title}>Platonus Academy</div>
              <ul className={s.list}>
                <li
                  className={s.item}
                  onClick={() => navigate("/freeCourseCatalogue")}
                >
                  {t('home:COURSE_CATALOGUE')}
                </li>
                <li
                  className={s.item}
                  onClick={() => navigate("/?variant=ORGANIZATION")}
                >
                  {t('home:FOR_ORGANIZATIONS')}
                </li>
                <li
                  className={s.item}
                  onClick={() => navigate(`/?variant=${HomeVariant.EDUCATOR}`)}
                >
                  {t('home:FOR_TEACHER')}
                </li>
                <li 
                  className={s.item}
                  onClick={() => navigate('/#faq')}
                >
                  {t('home:POPULAR_QUESTIONS')}
                </li>
              </ul>
            </div>
            <div className={s.catalog2}>
              <div className={s.title}>{t('home:CONTACTS')}</div>
              <ul className={s.list}>
                <li className={s.item}>+7 (7172) 47-25-25</li>
                <li className={s.item}>info@platonus.kz</li>
                <li className={s.item}>{t('home:TECHNICAL_SUPPORT')}</li>
              </ul>
            </div>
            <div className={s.social}>
              <div className={s.title}>{t('home:SOCIAL_MEDIA')}</div>
              <div className={s.divider}>
                <div
                  onClick={() =>
                    window.open("https://www.instagram.com/platonus.kz/")
                  }
                  className={s.icon}
                >
                  <InstagramIcon />
                </div>
                <div
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/platonus/mycompany/"
                    )
                  }
                  className={s.icon}
                >
                  <LinkedIcon />
                </div>
                <div
                  onClick={() =>
                    window.open("https://www.tiktok.com/@platonus.kz/")
                  }
                  className={s.icon}
                >
                  <TictokIcon />
                </div>
              </div>
            </div>
          </div>
          <div className={s.time}>
            © 2005 - 2024 ТОО «Platonus» | {t('home:ALL_RIGHTS_RESERVED')}
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
