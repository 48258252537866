import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DeleteIcon from "@mui/icons-material/Delete";

import { serviceTask } from '@services/task';
import CustomButton from '@ui/Button';
import CustomSearch from '@ui/Search';
import Pagination from '@ui/Pagination';
import { AuthorRole, CourseStatus, ITask } from '@interfaces/index';
import AddTask from './AddTask';
import { contentStatuses, taskFormats } from '@utils/enums';
import CustomAutocomplete from '@ui/Autocomplete';
import useDeleteConfirmModal from '@hooks/useDeleteConfirmModal';

interface Props {
  courseId: string;
  courseData: any;
  courseType?: string | null;
}

interface IFilter {
  name: string;
  format: string | null;
  status: string;
}

const List = ({ courseId, courseData, courseType }: Props) => {
  const navigate = useNavigate();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["common", "enum", "dictionary", "user"]);

  const [taskList, setTaskList] = useState<Array<ITask>>([]);
  const [currentTask, setCurrentTask] = useState<ITask>()
  const [isQuestionInTest, setIsQuestionInTest] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const isCoAuthor = courseData?.authors.filter((author) => author.authorRole === AuthorRole.COAUTHOR).some((author) => author.userId === courseData?.userId)
  const [filter, setFilter] = useState<IFilter>({ name: "", format: null, status: "" });
  const [taskCreationAvailable, setTaskCreationAvailable] = useState<boolean>(false);
  const searchByParams = (paramName: string, value: string) => {
    if (paramName === 'format') setFilter({ ...filter, format: value });
    if (paramName === 'status') setFilter({ ...filter, status: value });
  };

  useEffect(() => {
    serviceTask.getTaskList(courseId, { pageNumber: currentPage, size: rowsPerPage, filter })
      .then(res => {
        if (res.status === 200) {
          setTaskList(res.data.dtoList);
          setCount(res.data.totalElements);
        }
      })
  }, [courseId, currentPage, rowsPerPage, filter]);

  useEffect(() => {
    if (courseId) {
      serviceTask.getTaskCreationAvailability(courseId)
        .then(res => {
          if (res.status === 200) {
            setTaskCreationAvailable(res.data);
          }
        });
    }
  }, [courseId]);

  const searchByName = (searchValue: string) => {
    setFilter({ ...filter, name: searchValue });
  };

  const handleChangePage = (newPage: number) => setCurrentPage(newPage);

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
    setCurrentPage(0);
  };

  const [openAddTaskModal, setOpenAddTaskModal] = useState(false);
  const handleToggleAddTaskModal = (modalState: boolean) => setOpenAddTaskModal(modalState);

  const deleteTask = (taskId: string) => {
    return serviceTask.deleteTask(taskId || "").then(res => {
      if (res.status === 200) {
        serviceTask.getTaskList(courseId, { pageNumber: currentPage, size: rowsPerPage, filter })
          .then(res => {
            if (res.status === 200) {
              setTaskList(res.data.dtoList);
              setCount(res.data.totalElements);
            }
          })
      }
    })
  }

  useEffect(() => {
    if (currentTask?.quiz?.id) {
      serviceTask.getQuestionExists(currentTask?.quiz?.id).then((res) => {
        setIsQuestionInTest(res.data)
      })
    }
  }, [currentTask])

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: deleteTask,
    modalTitle: (currentTask?.format === 'QUIZ' && isQuestionInTest)
      ? t('course:TASK_HAS_QUESTIONS_AND_ANSWERS_DELETE_ANYWAY', {
        name: currentTask.name
      })
      : t('course:DELETE_TASK_CONFIRMATION_TEXT')
  })

  return (
    <>
      {DeleteModal}
      <Grid container spacing={2} sx={{ px: 3 }}>
        <Grid item lg={5.5} xs={12}>
          <CustomSearch
            borderRadius='4px'
            placeholder={t("placeholders.SEARCH_BY_TASK_NAME")}
            onChange={searchByName}
          />
        </Grid>
        <Grid item lg={2.5} sm={6} xs={12} md={4}>
          <CustomAutocomplete
            name='format'
            placeholder={t('dictionary:FORMAT')}
            options={taskFormats}
            onChange={(name: string, value: string) => searchByParams(name, value)}
          />
        </Grid>
        <Grid item lg={2.5} sm={6} md={4} xs={12}>
          <CustomAutocomplete
            name='status'
            placeholder={t('user:STATUS')}
            options={contentStatuses}
            onChange={(name: string, value: string) => searchByParams(name, value)}
          />
        </Grid>
        <Grid item lg={1.5} xs={12} md={4}>
          <CustomButton
            backgroundColor={common.primaryColor}
            fontColor={common.fontWhite}
            borderRadius="6px"
            height="38px"
            disabled={!taskCreationAvailable || courseData?.status === CourseStatus.CLOSED || !(isAuthor || isCoAuthor)}
            onClick={() => handleToggleAddTaskModal(true)}
          >
            {t('common:actions.ADD')}
          </CustomButton>
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed', borderTop: `1px solid ${common.borderSecondary}` }}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('course:task.TASK_NAME')}
                </TableCell>
                <TableCell>
                  {t('user:STATUS')}
                </TableCell>
                <TableCell>
                  {t('dictionary:FORMAT')}
                </TableCell>
                <TableCell>
                  {t('dictionary:TYPE')}
                </TableCell>
                <TableCell>
                  {t('course:task.REFERS_TO')}
                </TableCell>
                {courseType === 'EDU' &&
                  <TableCell>
                    {t('course:task.TASK_GROUP')}
                  </TableCell>}
                <TableCell>
                  {" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taskList?.length > 0 && (
                taskList.map((task, index) => {
                  return (
                    <TableRow key={`${index}_${task.id}`}>
                      <TableCell
                        sx={{
                          cursor: "pointer",
                          "&:hover": { color: common.primaryColor }
                        }}
                        onClick={() => navigate(`/myCourses/${courseId}/tasks/${task.id}/${task.format}/${task.section ? task.section.id : "new"}/${task.theme ? task.theme.id : "new"}`)}
                      >
                        {task.name}
                      </TableCell>
                      <TableCell>
                        {task.status === 'SAVED' ? <RemoveCircleOutlineIcon /> : <CheckCircleIcon sx={{ color: `${common.primaryColor}` }} />}
                      </TableCell>
                      <TableCell>
                        {t(`enum:${task.format}`)}
                      </TableCell>
                      <TableCell>
                        {t(`enum:${task.type}`)}
                      </TableCell>
                      <TableCell>
                        {t(`enum:BELONGS_TO_${task.parentType}`)}
                      </TableCell>
                      {courseType === 'EDU' &&
                        <TableCell>
                          {task.group?.name || '-'}
                        </TableCell>}
                      <TableCell>
                        {task.status !== CourseStatus.PUBLISHED &&
                          <DeleteIcon
                            color="error"
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              openDeleteConfirmModal(task.id || "");
                              setCurrentTask(task)
                            }}
                          />}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Pagination
              count={count}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </TableContainer>
      </Box>
      {openAddTaskModal &&
        <AddTask
          openModal={openAddTaskModal}
          handleToggleModal={handleToggleAddTaskModal}
          sectionId="new"
          themeId="new"
        />
      }
    </>
  )
}

export default List;