import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { Formik, Form as FormikForm } from 'formik';
import { useTranslation } from "react-i18next";
import { AlertColor } from '@mui/material/Alert';
import MultiRangeSlider from "multi-range-slider-react";

import { serviceDict } from '@services/dictionary';
import CustomButton from '@ui/Button';
import FormikInput from '@ui/formik/Input';
import FormikCheckbox from '@ui/formik/Checkbox';
import { DTO, initialDtoForEDUCourse } from './models';
import { validationSchema } from './validation';
import FormikSwitch from '@ui/formik/Switch';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import { severityViolations } from '@utils/enums';
import { Role, TypeCode } from '@interfaces/index';

interface Props {
  code: string;
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  orgId?: string
}

const Course = ({ code, setMessage, setMessageOpen, setMessageType, orgId }: Props) => {
  const [initialData, setInitialData] = useState<DTO>(initialDtoForEDUCourse);
  const { common } = useTheme().palette;
  const { t } = useTranslation(["generalSetting", "common", "aero"]);
  const profile = useSelector((store: any) => store.account.profileInfo);
  const id = orgId ? orgId : (orgId === undefined && profile.authorityInfo.role === Role.LOCAL_ADMIN) ? profile.authorityInfo.orgId : '';
  const { authorityInfo: { role } } = profile;
  const isInputDisabled = role === Role.LOCAL_ADMIN && !!id;
  const [trustLevelLow, setTrustLevelLow] = useState(33);
  const [trustLevelHigh, setTrustLevelHigh] = useState(66);
  const [errorRuleTrust, setErrorRuleTrust] = useState<boolean>(false)
  const handleInput = (e) => {
    setTrustLevelLow(e.minValue);
    setTrustLevelHigh(e.maxValue);
  }

  useEffect(() => {
    serviceDict.getTypeOptionList(id || 0).then((res) => {
      if (res.status === 200) {
        res.data.find(typeOption => typeOption.code === code)?.useProctoring ?
          setInitialData(res.data.find(typeOption => typeOption.code === code)) :
          setInitialData({
            ...res.data.find(typeOption => typeOption.code === code), violation: {
              deviceAccessMicrophone: "MEDIUM",
              deviceAccessScreen: "MEDIUM",
              deviceAccessWebcam: "MEDIUM",
              faceRecLookAway: "MEDIUM",
              faceRecNoStudent: "MEDIUM",
              faceRecNotRecognized: "MEDIUM",
              faceRecTooManyPeople: "MEDIUM",
              audio: "MEDIUM",
              clipboardCopy: "MEDIUM",
              clipboardPaste: "MEDIUM",
              internetConnectionOffline: "MEDIUM",
              screenBrowserFocus: "MEDIUM",
              tabsForbiddenTab: "MEDIUM",
            }
          })
          ;
        setTrustLevelLow(res.data.find(typeOption => typeOption.code === code).violation?.trustLevelLow || 33)
        setTrustLevelHigh(res.data.find(typeOption => typeOption.code === code).violation?.trustLevelHigh || 66)
      }
    })
  }, [code, id]);

  useEffect(() => {
    trustLevelHigh > 90 || trustLevelLow < 10 ? setErrorRuleTrust(true) : setErrorRuleTrust(false)
  }, [trustLevelHigh, trustLevelLow])

  return (
    <Box sx={{ backgroundColor: common.fontWhite, width: "100%", borderRadius: "12px", p: "32px", border: `1px solid ${common.borderSecondary}` }}>
      <Typography fontSize="20px" fontWeight={700} marginBottom="10px">{t("generalSetting:GENERAL")}</Typography>
      <Formik
        initialValues={initialData}
        enableReinitialize
        validationSchema={validationSchema(t)}
        onSubmit={(values) => {
          values.code = code;
          // if (values.code === TypeCode.EDU && values.accountAttendance === false) {
          //   values.accountAttendanceInControlWork = false
          // }
          // if (!values.accountAttendanceInControlWork) {
          //   values.availablePassesPerCourse = 0
          // }
          if (values.useProctoring) {
            values = {
              ...values, rule: {
                faceRec: true,
                recording: true,
                audio: true,
                authorization: true,
                clipboard: true,
                screen: true,
                dualScreen: true,
                thirdpartyApps: true,
                capturingTabs: true,
                otherTabsBlock: true
              }, violation: { ...values.violation, trustLevelHigh: trustLevelHigh, trustLevelLow: trustLevelLow }
            }

          } else {
            values = {
              ...values, rule: null, violation: null
            }
          }

          serviceDict.createCourseTypeOption(values).then((res) => {
            if (res.status === 200) {
              setMessageOpen(true);
              setMessage(t('common:messages.DATA_SAVED_SUCCESSFULLY'));
              setMessageType("success");
              setTimeout(() => {
                setMessageOpen(false);
              }, 3000)
            }
          }).catch(err => {
            setMessageOpen(true);
            setMessage(t('common:messages.ERROR_SAVING_DATA'));
            setMessageType("error");
            setTimeout(() => {
              setMessageOpen(false);
            }, 10000)
          }).finally(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
        }}
      >
        {({ submitForm, values, dirty, isValid }) => {
          return <FormikForm>
            {/*Временно убрать*/}
            {/* <FormikCheckbox
              label={t("EXAMINATION_OF_COURSE_BEFORE_PUBLICATION_IS_PROVIDED")}
              name="provideExpertiseBeforePublish"
              labelPlacement='end'
              disabled={isInputDisabled}
            /> */}
            <Box sx={{
              display: 'flex',
              flexDirection: id ? 'column' : 'row',
              gap: 2,
              alignItems: id ? 'flex-start' : 'flex-end'
            }}>
              <Box sx={{ width: '50%' }}>
                <FormikInput
                  label={t('MAX_SIZE_OF_TOPIC')}
                  name='maxFileSizeCanUploadPerTheme'
                  type="number"
                  isRequired
                  disabled={!isInputDisabled}
                />
              </Box>
              <Box sx={{ width: '50%' }}>
                <FormikInput
                  label={t('MAX_SIZE_OF_TASK')}
                  name='maxFileSizeCanTutorUploadPerTask'
                  type="number"
                  isRequired
                  disabled={!isInputDisabled}
                />
              </Box>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: id ? 'column' : 'row',
              gap: 2,
              alignItems: id ? 'flex-start' : 'flex-end',
              mt: 2
            }}>
              <Box sx={{ width: '50%' }}>
                <FormikInput
                  label={t('MAX_PROMO_VIDEO_SIZE')}
                  name='maxPromoVideoSize'
                  type="number"
                  isRequired
                  disabled={!isInputDisabled}
                />
              </Box>
              <Box sx={{ width: '50%' }}>
                <FormikInput
                  label={t('MAX_THEME_VIDEO_SIZE')}
                  name='maxThemeVideoSize'
                  type="number"
                  isRequired
                  disabled={!isInputDisabled}
                />
              </Box>
            </Box>
            {code === 'EDU' && id &&
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                  <FormikCheckbox
                    label={t('USE_COMMON_SCHEDULE_FOR_COMPLETING_COURSE')}
                    name="useTimetable"
                    labelPlacement='end'
                    disabled={!isInputDisabled}
                  />
                </Box>
                {/* <FormikCheckbox
                  label={t('KEEP_ATTENDANCE_RECORDS')}
                  name="accountAttendance"
                  labelPlacement='end'
                  disabled={!isInputDisabled}
                  hintText={role === Role.LOCAL_ADMIN ? t('common:hints.KEEP_ATTENDANCE_HINT') : undefined}
                />
                {values?.accountAttendance &&
                  <FormikCheckbox
                    label={t('CONSIDER_ATTENDANCE_OF_STUDENTS')}
                    name="accountAttendanceInControlWork"
                    labelPlacement='end'
                    disabled={!isInputDisabled}
                    hintText={role === Role.LOCAL_ADMIN ? t('common:hints.CONSIDER_ATTENDANCE_HINT') : undefined}
                  />
                }
                {values?.accountAttendanceInControlWork && values?.accountAttendance &&
                  <Box sx={{ width: "50%" }}>
                    <FormikInput
                      type='number'
                      name="availablePassesPerCourse"
                      label={t('PERMISSIBLE_NUMBER_OF_PASSES_PER_COURSE')}
                      max={100}
                      disabled={!isInputDisabled}
                    />
                  </Box>
                } */}
              </Box>
            }
            {role === Role.LOCAL_ADMIN &&
              <>
                <Divider sx={{ margin: "30px 0" }} />
                <Typography fontSize="20px" fontWeight={700} marginBottom="10px">{t("aero:AERO_SETTING")}</Typography>
                <FormikSwitch name={`useProctoring`} sx={{ width: "500px", marginBottom: "30px" }} label={t("aero:USE_ONLINE_AERO")} />
                {values.useProctoring && <Box sx={{ width: '50%' }}>
                  <Typography fontSize="16px" fontWeight={700}>{t("aero:SEVERITY_VIOLATIONS")}</Typography>
                  <Typography fontSize="12px" color="#8E8E93">{t("aero:APPROPRIATE_CONFIDENCE_PERCENTAGE")}</Typography>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="14px">{t("aero:@FACE_REC/NO_STUDENT")}</Typography>
                    <FormikAutocomplete
                      name="violation.faceRecNoStudent"
                      options={severityViolations}
                      clearable={false}
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Divider sx={{ margin: "10px 0" }} />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="14px">{t("aero:@FACE_REC/NOT_RECOGNIZED")}</Typography>
                    <FormikAutocomplete
                      name="violation.faceRecNotRecognized"
                      options={severityViolations}
                      clearable={false}
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Divider sx={{ margin: "10px 0" }} />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="14px">{t("aero:@FACE_REC/TOO_MANY_PEOPLE")}</Typography>
                    <FormikAutocomplete
                      name="violation.faceRecTooManyPeople"
                      options={severityViolations}
                      clearable={false}
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Divider sx={{ margin: "10px 0" }} />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="14px">{t("aero:@FACE_REC/LOOK_AWAY")}</Typography>
                    <FormikAutocomplete
                      name="violation.faceRecLookAway"
                      options={severityViolations}
                      clearable={false}
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Divider sx={{ margin: "10px 0" }} />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="14px">{t("aero:@SCREEN/BROWSER_FOCUS")}</Typography>
                    <FormikAutocomplete
                      name="violation.screenBrowserFocus"
                      options={severityViolations}
                      clearable={false}
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Divider sx={{ margin: "10px 0" }} />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="14px">{t("aero:@AUDIO")}</Typography>
                    <FormikAutocomplete
                      name="violation.audio"
                      options={severityViolations}
                      clearable={false}
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Divider sx={{ margin: "10px 0" }} />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="14px">{t("aero:@CLIPBOARD/COPY")}</Typography>
                    <FormikAutocomplete
                      name="violation.clipboardCopy"
                      options={severityViolations}
                      clearable={false}
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Divider sx={{ margin: "10px 0" }} />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="14px">{t("aero:@CLIPBOARD/PASTE")}</Typography>
                    <FormikAutocomplete
                      name="violation.clipboardPaste"
                      options={severityViolations}
                      clearable={false}
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Divider sx={{ margin: "10px 0" }} />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="14px">{t("aero:@INTERNET_CONNECTION/OFFLINE")}</Typography>
                    <FormikAutocomplete
                      name="violation.internetConnectionOffline"
                      options={severityViolations}
                      clearable={false}
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Divider sx={{ margin: "10px 0" }} />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="14px">{t("aero:@DEVICE_ACCESS/WEBCAM")}</Typography>
                    <FormikAutocomplete
                      name="violation.deviceAccessWebcam"
                      options={severityViolations}
                      clearable={false}
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Divider sx={{ margin: "10px 0" }} />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="14px">{t("aero:@DEVICE_ACCESS/MICROPHONE")}</Typography>
                    <FormikAutocomplete
                      name="violation.deviceAccessMicrophone"
                      options={severityViolations}
                      clearable={false}
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Divider sx={{ margin: "10px 0" }} />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="14px">{t("aero:@DEVICE_ACCESS/SCREEN")}</Typography>
                    <FormikAutocomplete
                      name="violation.deviceAccessScreen"
                      options={severityViolations}
                      clearable={false}
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Divider sx={{ margin: "10px 0" }} />
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="14px">{t("aero:@TABS/FORBIDDEN_TAB")}</Typography>
                    <FormikAutocomplete
                      name="violation.tabsForbiddenTab"
                      options={severityViolations}
                      clearable={false}
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  <Divider sx={{ margin: "10px 0" }} />

                  <Typography fontSize="16px" fontWeight={700} margin="20px 0">{t("aero:TRUST_LEVEL")}</Typography>
                  <MultiRangeSlider
                    min={0}
                    max={100}
                    step={10}
                    style={{ border: "none", boxShadow: "none" }}
                    minValue={trustLevelLow}
                    maxValue={trustLevelHigh}
                    minCaption={`${trustLevelLow}`}
                    maxCaption={`${trustLevelHigh}`}
                    barLeftColor='#D62B20'
                    barInnerColor='#E5D226'
                    barRightColor='#7FE47E'
                    thumbLeftColor="white"
                    thumbRightColor="white"
                    labels={["0", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100"]}
                    onInput={(e) => {
                      handleInput(e);
                    }}
                  />
                  <Box display="flex" gap={10} marginTop="20px">
                    <Box sx={{ alignItems: "center", display: "flex", gap: 1, mb: "10px" }}>
                      <Box sx={{ background: "#D62B20", width: "6px", height: "42px", borderRadius: "4px" }}></Box>
                      <Box>
                        <Typography fontSize="14px" fontWeight={500}>{t("aero:LOW_TRUST")}</Typography>
                        <Typography fontSize="14px" fontWeight={400} color="#6F6F7E">(0-{trustLevelLow}%)</Typography>
                      </Box>
                    </Box>
                    <Box sx={{ alignItems: "center", display: "flex", gap: 1, mb: "10px" }}>
                      <Box sx={{ background: "#E5D226", width: "6px", height: "42px", borderRadius: "4px" }}></Box>
                      <Box>
                        <Typography fontSize="14px" fontWeight={500}>{t("aero:AVERAGE_TRUST")}</Typography>
                        <Typography fontSize="14px" fontWeight={400} color="#6F6F7E">({trustLevelLow + 1}-{trustLevelHigh}%)</Typography>
                      </Box>
                    </Box>
                    <Box sx={{ alignItems: "center", display: "flex", gap: 1, mb: "10px" }}>
                      <Box sx={{ background: "#7FE47E", width: "6px", height: "42px", borderRadius: "4px" }}></Box>
                      <Box>
                        <Typography fontSize="14px" fontWeight={500}>{t("aero:HIGH_TRUST")}</Typography>
                        <Typography fontSize="14px" fontWeight={400} color="#6F6F7E">({trustLevelHigh + 1}-100%)</Typography>
                      </Box>
                    </Box>
                  </Box>
                  {errorRuleTrust && <Typography fontSize="11px" color={common.errorColor}>{t("aero:RULE_TRUST_LEVE")}</Typography>}
                </Box>}
              </>
            }
            <Box sx={{ mt: "100px", display: "flex", justifyContent: "end", mr: "4px" }}>
              <CustomButton
                disabled={(!(isValid && dirty) || isInputDisabled) && errorRuleTrust}
                onClick={submitForm}
                backgroundColor={common.primaryColor}
                fontColor={common.fontWhite}
                width="98px"
                borderRadius="6px"
                height="38px"
                sx={{
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "130%",
                }}
              >
                {t("common:actions.SAVE")}
              </CustomButton>
            </Box>
          </FormikForm>
        }}
      </Formik>
    </Box>
  )
}

export default Course