import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { Box, Tab, Typography, useTheme } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { serviceTask } from '@services/task';
import CustomButton from '@ui/Button';
import Message from '@components/Message';
import { CourseFormatType, IBreadcrumb, ITheme, CourseStatus, AuthorRole } from '@interfaces/index';
import { initialDto } from "./models";
import Breadcrumbs from '@components/Breadcrumbs';
import { useBreadcrumbs } from '@context/BreadcrumbsContext';
import { CourseContext } from '@context/CourseContext';
import ThemeSettings from './ThemeSettings';
import Recipients from './Recipients';
import AddTask from '../Task/AddTask';
import { AdditionalMaterials } from './AdditionalMaterials';

const Theme = () => {
  const { courseId, themeId } = useParams();
  const { t } = useTranslation(['common', 'course', 'enum']);
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const [initialData, setInitialData] = useState<ITheme>(initialDto);
  const [taskCreationAvailable, setTaskCreationAvailable] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [themeTabValue, setThemeTabValue] = useState('themeSettings');
  const currentTab = searchParams.get('tab');
  const { common } = useTheme().palette;
  const navigate = useNavigate();

  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const { courseData } = useContext(CourseContext);
  const disableByStatus = courseData?.status === CourseStatus.PUBLISHED || courseData?.status === CourseStatus.CLOSED;
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const isCoAuthor = courseData?.authors.filter((author) => author.authorRole === AuthorRole.COAUTHOR).some((author) => author.userId === courseData?.userId)

  const [openAddTaskModal, setOpenAddTaskModal] = useState(false);
  const handleToggleAddTaskModal = (modalState: boolean) => setOpenAddTaskModal(modalState);

  useEffect(() => {
    const updatedCrumbs = [...getBreadcrumbs(courseId || "")].filter((x: IBreadcrumb) => x.level !== 4 && x.level !== 3);
    updatedCrumbs.push({
      level: 3,
      page: 'theme',
      label: themeId === "new" ? t('course:theme.ADDING_THEME') : initialData.name,
      path: window.location.pathname,
      tabNum: null
    });
    // Check if the breadcrumbs have actually changed before updating
    if (JSON.stringify(updatedCrumbs) !== JSON.stringify(getBreadcrumbs(courseId || ""))) {
      if (courseId) setBreadcrumbs(courseId, updatedCrumbs);
    }
  }, [getBreadcrumbs, setBreadcrumbs, courseId, themeId, initialData.name, t]);

  useEffect(() => {
    if (courseId) {
      serviceTask.getTaskCreationAvailability(courseId)
        .then(res => {
          if (res.status === 200) {
            setTaskCreationAvailable(res.data);
          }
        });
    }
  }, [courseId]);

  const handleTabChange = (newValue: string) => {
    setThemeTabValue(newValue);
    setSearchParams({ tab: newValue })
  };

  useEffect(() => {
    if (currentTab) setThemeTabValue(currentTab);
    else setThemeTabValue("themeSettings");
  }, [currentTab]);

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 3 }}>
        <Breadcrumbs id={courseId} />
      </Box>
      <Typography sx={{ fontWeight: 500, fontSize: "36px", lineHeight: "44px", mb: '36px' }}>
        {initialData.id ? initialData.name : t('course:theme.ADDING_THEME')}
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column-reverse', md: 'row' } }}>
        <Box sx={{ width: { md: '80%', xs: '100%' } }}>
          {messageOpen &&
            <Box sx={{ mb: 1 }}>
              <Message
                type={messageType}
                message={message}
                setMessageOpen={setMessageOpen}
              />
            </Box>
          }
          <Box sx={{ width: '100%', backgroundColor: 'white', border: `1px solid ${common.borderSecondary}`, height: 'auto', borderRadius: '12px' }}>
            <TabContext value={themeTabValue}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  pt: "10px",
                  mx: 4
                }}
              >
                <TabList
                  onChange={(_, value: string) => handleTabChange(value)}
                  textColor="inherit"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: common.primaryColor
                    }
                  }}
                >
                  <Tab label={t("course:theme.THEME_SETTINGS")} value="themeSettings"
                    sx={{
                      textTransform: 'none',
                      marginRight: 4,
                      fontSize: '18px',
                      fontWeight: 500,
                      lineHeight: '24px',
                      textAlign: 'center',
                      p: '0 5px 5px'
                    }} />
                  {courseData?.formatType === CourseFormatType.SYNC &&
                    <Tab label={t("course:task.RECIPIENTS")} value="recipients"
                      sx={{
                        textTransform: 'capitalize',
                        marginRight: 4,
                        fontSize: '18px',
                        fontWeight: 500,
                        lineHeight: '24px',
                        textAlign: 'center',
                        p: '0 5px 5px'
                      }}
                      disabled={themeId === 'new'}
                    />
                  }
                  <Tab label={t("course:ADDITIONAL_MATERIALS")} value="additionalMaterials"
                    sx={{
                      textTransform: 'none',
                      marginRight: 4,
                      fontSize: '18px',
                      fontWeight: 500,
                      lineHeight: '24px',
                      textAlign: 'center',
                      p: '0 5px 5px'

                    }}
                    disabled={themeId === 'new'}
                  />
                </TabList>
              </Box>
              <TabPanel value="themeSettings" sx={{ p: '16px 0' }}>
                <ThemeSettings
                  initialData={initialData}
                  setInitialData={setInitialData}
                  setMessage={setMessage}
                  setMessageOpen={setMessageOpen}
                  setMessageType={setMessageType} />
              </TabPanel>
              {courseData?.formatType === CourseFormatType.SYNC &&
                <TabPanel value="recipients" sx={{ p: '16px 0' }}>
                  <Recipients
                    setMessage={setMessage}
                    setMessageOpen={setMessageOpen}
                    setMessageType={setMessageType}
                  />
                </TabPanel>
              }
              <TabPanel value="additionalMaterials" sx={{ p: '16px 0' }}>
                <AdditionalMaterials
                  initialData={initialData}
                  setMessage={setMessage}
                  setMessageOpen={setMessageOpen}
                  setMessageType={setMessageType}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row', md: 'column' },
          width: { xs: '100%', md: 'auto' },
          height: 'fit-content',
          gap: 2,
          px: '28px',
          border: `1px solid ${common.borderSecondary}`,
          backgroundColor: `${common.fontWhite}`,
          borderRadius: '12px',
          py: '12px'
        }}>
          <Box sx={{ border: `2px solid ${common.primaryColor}`, borderRadius: '10px' }}>
            <CustomButton
              backgroundColor={common.fontWhite}
              borderRadius="8px"
              fontColor={common.primaryColor}
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                alignItems: "center"
              }}
              onClick={() => handleToggleAddTaskModal(true)}
              disabled={themeId === "new" || !taskCreationAvailable || disableByStatus || !(isAuthor || isCoAuthor)}
            >
              {t('common:actions.ADD_TASK_TO_THE_TOPIC')}
            </CustomButton>
          </Box>
          {themeId !== "new" &&
            <Box sx={{ border: `2px solid ${common.primaryColor}`, borderRadius: '10px' }}>
              <CustomButton
                backgroundColor={common.fontWhite}
                borderRadius="8px"
                fontColor={common.primaryColor}
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "20px",
                  alignItems: "center",
                }}
                onClick={() => navigate(`/myCourses/${courseId}/new/theme/new`)}
                disabled={disableByStatus || !(isAuthor || isCoAuthor)}
              >
                {t('common:actions.ADD_NEW_TOPIC')}
              </CustomButton>
            </Box>
          }
          {openAddTaskModal &&
            <AddTask
              openModal={openAddTaskModal}
              handleToggleModal={handleToggleAddTaskModal}
              sectionId="new"
              themeId={themeId || "new"}
            />
          }
        </Box>
      </Box>
    </Box >
  )
}

export default Theme;