import { useEffect, useState, useContext } from 'react'
import { AlertColor, Box, Tooltip, Typography, useTheme } from '@mui/material'
import CustomSearch from '@ui/Search'
import CustomButton from '@ui/Button';
import { AddVisitModal } from './AddVisitModal';
import { VisitTable } from './VisitTable';
import { serviceCourse } from '@services/course';
import { useParams } from 'react-router-dom';
import DateTimePicker from '@ui/DateTimePicker';
import endOfWeek from 'date-fns/endOfWeek';
import startOfWeek from 'date-fns/startOfWeek';
import { decodeContentDispositionFilename, formatDateWithTimezone } from '@utils/functions';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { serviceDict } from '@services/dictionary';
import { IDictType } from '@pages/generalSettings/models';
import { useTranslation } from 'react-i18next';
import { CourseContext } from "@context/CourseContext";
import { AuthorRole, CourseRole, CourseStatus, DictionaryCode, IUserSearchItem } from '@interfaces/index';
import { useSelector } from 'react-redux';
import { getFlowId } from '@store/journal/journalSelector';
import { serviceMember } from '@services/member';

interface Props {
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  studyClassesId: string,
  activeStudyClass: any
}
interface IFilter {
  name: string;
}

export const VisitPage = ({ setMessage, setMessageOpen, setMessageType, studyClassesId, activeStudyClass }: Props) => {
  const { common } = useTheme().palette;
  const [openVisitModal, setOpenVisitModal] = useState<boolean>(false)
  const { courseId } = useParams()
  const profile = useSelector((store: any) => store.account.profileInfo);
  const [filter, setFilter] = useState<IFilter>({ name: "" });
  const [attendanceDateList, setAttendanceDateList] = useState<any>(null)
  const [updateList, setUpdateList] = useState<boolean>(false)
  const [startDate, setStartDate] = useState(activeStudyClass?.startDate);
  const [endDate, setEndDate] = useState(activeStudyClass?.endDate);
  const [studyDict, setStudyDict] = useState<IDictType[]>([]);
  const [responsibleMemberList, setResponsibleMemberList] = useState<
    IUserSearchItem[]
  >([]);
  const [disabledStudyDict, setDisabledStudyDict] = useState<string[]>([])
  const lng = localStorage.getItem("i18nextLng") || "kz";
  const { t } = useTranslation(["common", "course"]);
  const { courseData } = useContext(CourseContext);
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const isCoAuthor = courseData?.authors.filter((author) => author.authorRole === AuthorRole.COAUTHOR).some((author) => author.userId === courseData?.userId)
  const [disableExportToExcel, setDisableExportToExcel] = useState<boolean>(false);
  const flowId = useSelector(getFlowId);
  useEffect(() => {
    serviceDict
      .getDictionaryList(DictionaryCode.CLASS_TYPE, {
        pageNumber: 0,
        size: 10,
        filter: {},
        sorting: lng === 'kz' ? { isStandard: "desc", nameKz: "asc" } : lng === 'ru' ? { isStandard: "desc", nameRu: "asc" } : { isStandard: "desc", nameEn: "asc" },
      })
      .then((res) => {
        if (res.status === 200) {
          setStudyDict(res.data.dtoList);
          setDisabledStudyDict(res.data.dtoList.filter(dict => dict.isDisabled).map(dict => dict.id))
        }
      });
  }, [lng]);

  useEffect(() => {
    if (flowId) {
      serviceCourse
        .getAttendanceDateList(flowId, startDate?.split("T")[0] || "", endDate?.split("T")[0] || "")
        .then((res) => {
          if (res.status === 200) setAttendanceDateList(res.data);
        });
    }
  }, [flowId, updateList, startDate, endDate]);

  const handleExporToExcel = () => {
    if (flowId) {
      serviceCourse.exportAttendanceToExcel(flowId).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          "download",
          `${decodeContentDispositionFilename(res.headers["content-disposition"])}`
        );
        document.body.appendChild(link);
        link.click();
      })
    }
  }
  useEffect(() => {
    if (studyClassesId) {
      serviceMember
        .getMemberList(studyClassesId, [
          CourseRole.TUTOR,
          CourseRole.CURATOR,
          CourseRole.ASSISTANT,
          CourseRole.EXAMINER,
        ])
        .then((res) => {
          if (res.status === 200) {
            setResponsibleMemberList(res.data);
          }
        });
    }
  }, [studyClassesId]);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, width: '100%', marginBottom: "20px" }}>
        <Box sx={{ width: { sm: '80%', xs: '100%' }, mr: '16px' }}>
          <CustomSearch placeholder={t('common:placeholders.SEARCH_BY_LISTENER_FULL_NAME')} onChange={(searchValue) => setFilter({ name: searchValue })} />
        </Box>
        <Tooltip
          componentsProps={{
            tooltip: { sx: { background: "none" } }
          }}
          title={<Box
            sx={{
              background: "#1A1F36",
              color: "#fff",
              fontSize: "11px",
              borderRadius: "6px",
              padding: "5px"
            }}
          >
            <Typography fontSize="11px">{t('common:actions.ADD_DATE_FOR_MARKING_ATTENDANCE')}</Typography>
          </Box>} placement="bottom">
          <CustomButton
            sx={{
              backgroundColor: common.primaryColor,
              height: "40px", width: '200px',
              color: common.fontWhite,
              "&.MuiButtonBase-root:hover": {
                bgcolor: common.primaryColor,
              },
              textTransform: "none",
            }}
            width='200px'
            borderRadius="8px"
            onClick={() => setOpenVisitModal(true)}
            disabled={!(isAuthor || isCoAuthor || responsibleMemberList.find((member) => member.userId === profile.pltaId)) || courseData?.status === CourseStatus.CLOSED}
          >
            {t('common:actions.ADD')}
          </CustomButton>
        </Tooltip>
      </Box>
      <Box display="flex" gap={2} alignItems="center">
        <CustomButton
          height="40px"
          backgroundColor={common.primaryColor}
          borderRadius="8px"
          width='200px'
          onClick={handleExporToExcel}
          fontColor={common.fontWhite}
          disabled={disableExportToExcel}
          sx={{
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "20px",
            alignItems: "center",
            color: "#FFFFFF"
          }}
        >
          {t('common:actions.EXPORT_TO_EXCEL')}
          <DescriptionOutlinedIcon />
        </CustomButton>
        <Box display="flex" gap={1} alignItems="center">
          <Typography>{t('course:FROM')}</Typography>
          <DateTimePicker
            date={new Date(startDate || "")}
            name="day"
            minDate={activeStudyClass?.startDate}
            maxDate={activeStudyClass?.endDate}
            handleDateChange={(value) => value && setStartDate(formatDateWithTimezone(value))} />
        </Box>
        <Box display="flex" gap={1} alignItems="center">
          <Typography>{t('course:TO')}</Typography>
          <DateTimePicker date={new Date(endDate || "")} name="day" minDate={activeStudyClass?.startDate} maxDate={activeStudyClass?.endDate}
            handleDateChange={(value) => value && setEndDate(formatDateWithTimezone(value))} />
        </Box>
      </Box>
      <VisitTable
        attendanceDateList={attendanceDateList || []}
        setMessageOpen={setMessageOpen}
        setMessageType={setMessageType}
        setMessage={setMessage}
        filter={filter}
        updateLists={() => setUpdateList(!updateList)}
        studyDict={studyDict}
        setDisableExportToExcel={setDisableExportToExcel}
        studyClassesId={studyClassesId}
      />
      <AddVisitModal
        openVisitModal={openVisitModal}
        handleCloseVisitModal={() => setOpenVisitModal(false)}
        setMessageOpen={setMessageOpen}
        updateLists={() => setUpdateList(!updateList)}
        setMessageType={setMessageType}
        setMessage={setMessage}
        studyDict={studyDict}
        disabledStudyDict={disabledStudyDict}
        startDate={activeStudyClass?.startDate}
        endDate={activeStudyClass?.endDate}
      />
    </Box>
  )
}
