import { useState, RefObject, createRef, useEffect } from 'react';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Formik, Form as FormikForm } from "formik";

import { serviceMember } from '@services/member';
import CustomModal from '@components/Modal';
import Message from '@components/Message';
import AvatarViaUserId from '@components/AvatarViaUserId';
import TransferGroup from './TransferGroup';
import CustomButton from '@ui/Button';
import FormikInput from '@ui/formik/Input';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import { IUserSearchItem } from '@interfaces/index';
import { listenerGroupValidationSchema } from './validation';
import { IGroupDto, groupInitialDto } from './models';
import { useTranslation } from "react-i18next";

interface Props {
  groupId?: string;
  modalOpen: boolean;
  handleToggleGroupModal: (modalState: boolean, groupId?: string, message?: string) => void;
  setData: any;
  courseCompleted?: boolean
  migratedCourse?: boolean;
  transferGroup: (oldGroupId: string, newGroupId: string) => void
  openSnackbarFunc: () => void
  studyClasses: any
}

const GroupModal = ({ groupId, modalOpen, handleToggleGroupModal, setData, courseCompleted, transferGroup, openSnackbarFunc, migratedCourse, studyClasses }: Props) => {
  const { common } = useTheme().palette;
  const [listenerMemberList, setListenerMemberList] = useState<IUserSearchItem[]>([]);
  const [initialData, setInitialData] = useState<IGroupDto>(groupInitialDto);
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const validationMessageRef: RefObject<HTMLElement> = createRef();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [clickedUser, setClickedUser] = useState<any>();
  const open = Boolean(anchorEl);
  const { t } = useTranslation(["common", "course"]);

  const handleClick = (event: React.MouseEvent<HTMLElement>, user: string, values: IGroupDto) => {
    setClickedUser(user);
    setInitialData(values);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setClickedUser(undefined);
  };

  const [openTransferGroupModalWindow, setOpenTransferGroupModalWindow] = useState(false);
  const handleCloseTransferGroupModalWindow = () => {
    setOpenTransferGroupModalWindow(false);
    setClickedUser(undefined);
  }

  useEffect(() => {
    if (studyClasses.id) {
      serviceMember.getNonGroupMembers(studyClasses?.id, groupId).then((res) => {
        if (res.status === 200) {
          setListenerMemberList(res.data)
        }
      });
    }
  }, [studyClasses.id, groupId]);

  useEffect(() => {
    if (groupId) {
      serviceMember.getMemberGroupById(groupId).then((res) => {
        if (res.status === 200) {
          setInitialData(res.data);
        }
      })
    }
  }, [groupId]);

  const unselectUser = () => {
    setInitialData({
      ...initialData,
      members: [...initialData.members].filter((member: any) => member.id !== clickedUser?.id)
    });
    handleClose();
  }

  const menuItemHoverStyle = {
    backgroundColor: common.primaryColor,
    color: common.fontWhite
  };

  const catchError = (errorResponse: any) => {
    const { status, data } = errorResponse;
    if (status === 400 && data.code === "GROUP_CAPACITY_IS_OVER") {
      setMessageOpen(true);
      setTimeout(() => {
        setMessageOpen(false);
      }, 5000);
      validationMessageRef.current?.scrollTo(0, 0);
    }
  }

  return (
    <CustomModal
      open={modalOpen}
      handleToggleModal={handleToggleGroupModal}
      width={494}
      divRef={validationMessageRef}
      showCloseIcon={courseCompleted}
      maxHeight='600px'
    >
      <>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "36px",
            color: "#000000",
            display: "flex",
            alignItems: "center",
          }}
        >
          {!initialData.id
            ? t('common:actions.ADDING_GROUP')
            : t('common:actions.EDITING_GROUP')}
        </Typography>
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={listenerGroupValidationSchema(t)}
          onSubmit={(values: any) => {
            if (!values.id) {
              values = { ...values, classId: studyClasses.id };
              serviceMember.createGroup(values).then((res) => {
                if (res.status === 200) {
                  setData(res.data);
                  handleToggleGroupModal(false, '', t('course:messages.GROUP_ADDED'));
                }
              }).catch(error => {
                catchError(error.response);
              })
            } else {
              serviceMember.updateGroup(values).then((res) => {
                if (res.status === 200) {
                  setData(res.data);
                  handleToggleGroupModal(false, '', t('course:messages.GROUP_UPDATED'));
                }
              }).catch(error => {
                catchError(error.response);
              });
            }
          }}
        >
          {({ values, submitForm }) => (
            <FormikForm>
              {messageOpen &&
                <Message
                  type="error"
                  message={t('course:messages.NUMBER_OF_PARTICIPANTS_EXCEEDS_MAX', {
                    capacity: values.capacity
                  })}
                  setMessageOpen={setMessageOpen}
                />
              }
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}
              >
                <FormikInput
                  label={t('course:content.TITLE_RESOURSES')}
                  name="name"
                  isRequired
                  disabled={courseCompleted || migratedCourse}
                />
                <FormikInput
                  label={t('course:MAX_NUMBER_OF_PARTICIPANTS')}
                  name="capacity"
                  type='number'
                  isRequired
                  disabled={courseCompleted || migratedCourse}
                />
                {!courseCompleted && !migratedCourse && <FormikAutocomplete
                  label={t('common:actions.ADD_STUDENTS')}
                  name="members"
                  options={listenerMemberList}
                  valueAsObject
                  multiple
                  selectAll
                  maxSelectedItems={Number(values.capacity)}
                />}
                {values.members.length > 0 &&
                  <Box>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{
                        fontWeight: 500,
                        fontSize: "28px",
                        lineHeight: "36px",
                        color: "#000000",
                        display: "flex",
                        alignItems: "center",
                        my: { xs: '4px', sm: '4px', md: '8px', lg: '16px', xl: '24px' }
                      }}
                    >
                      {t('course:LISTENERS')}
                    </Typography>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      alignItems: "center",
                      maxHeight: '208px',
                      overflowY: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '7px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#E5E0EB',
                        borderRadius: '6px'
                      }
                    }}
                    >
                      {values.members.map((user: any) => {
                        return (
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} key={user.id}>
                            <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', gap: 2 }}>
                              <AvatarViaUserId
                                userId={user.userId}
                                sxProps={{ width: 45, height: 45 }}
                              />
                              <Typography
                                sx={{
                                  color: "#323C47",
                                  fontWeight: "500",
                                  lineHeight: "20px",
                                  height: "20px",
                                  width: "180px",
                                  display: 'flex',
                                  alignContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                {user.userFullName}
                              </Typography>
                            </Box>
                            <Box
                              id="fade-button"
                              aria-controls={open ? "fade-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={(e) => { if (!(courseCompleted || migratedCourse)) handleClick(e, user, values) }}
                              sx={{ cursor: "pointer" }}
                            >
                              <MoreVertIcon sx={{ color: (courseCompleted || migratedCourse) ? '#0000001F' : common.primaryColor }} />
                            </Box>
                          </Box>
                        )
                      })}
                    </Box>
                  </Box>
                }
              </Box>
              {!courseCompleted &&
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    mt: 3
                  }}
                >
                  <CustomButton
                    backgroundColor={common.btnCancel}
                    fontColor={common.primaryColor}
                    width="100%"
                    borderRadius="6px"
                    height="40px"
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    onClick={() => handleToggleGroupModal(false)}
                  >
                    {t('common:actions.CANCEL')}
                  </CustomButton>
                  <CustomButton
                    backgroundColor={common.primaryColor}
                    fontColor={common.fontWhite}
                    width="100%"
                    borderRadius="6px"
                    height="40px"
                    sx={{
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    onClick={submitForm}
                  >
                    {groupId ? t('common:actions.SAVE') : t('common:actions.ADD')}
                  </CustomButton>
                </Box>
              }
            </FormikForm>
          )}
        </Formik>
        {open &&
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setOpenTransferGroupModalWindow(true)
              }}
              sx={{ "&:hover": menuItemHoverStyle }} disabled={clickedUser?.migrated}>
              {t('common:actions.TRANSFER_TO_ANOTHER_GROUP')}
            </MenuItem>
            <MenuItem
              onClick={() => unselectUser()}
              sx={{ "&:hover": menuItemHoverStyle }}
            >
              {t('common:actions.DELETE')}
            </MenuItem>
          </Menu>
        }
        {openTransferGroupModalWindow &&
          <TransferGroup
            openTransferGroupModalWindow={openTransferGroupModalWindow}
            handleCloseTransferGroupModalWindow={handleCloseTransferGroupModalWindow}
            transferGroup={transferGroup}
            unselectUser={unselectUser}
            clickedUser={clickedUser}
            initialData={initialData}
            openSnackbarFunc={openSnackbarFunc}
            studyClassesId={studyClasses?.id}
          />
        }
      </>
    </CustomModal>
  )
}

export default GroupModal;