import {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  Fragment,
  FC,
} from "react";
import {
  Box,
  Typography,
  useTheme,
  Checkbox,
  TextField,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TuneIcon from "@mui/icons-material/Tune";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";

import { serviceTask } from "@services/task";
import { serviceMember } from "@services/member";
import { formatDateWithHourMinute } from "@utils/functions";
import { taskFormats, taskTypes } from "@utils/enums";
import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import CustomAutocomplete from "@ui/Autocomplete";
import {
  CourseRole,
  CourseStatus,
  IBreadcrumb,
  ICourse,
  IMember,
  ITask,
  ITaskFreeCourses,
  IUserSearchItem,
  Role,
} from "@interfaces/index";
import { IFilter, initialFilter } from "./models";
import UserFullName from "@components/UserFullName";
import UnhiddenHints from "@components/UnhiddenHints";
import { CourseContext } from "@context/CourseContext";
import EmptyDataComponent from "@components/EmptyDataComponent";
import { useGetAllStudyClassesByCourseIdQuery } from "@services/course";
import { useFlowInfoContext } from "../../FlowInfoProvider";
import { EmptyComponent, HintComponent } from "@components/common";
import { ReactComponent as EmptyMeasurementSvg } from "@assets/svg_files/emptyComponent/emptyMeasurement.svg";
import { ReactComponent as WarningHintComponent } from "@assets/svg_files/hintComponent/warning.svg";
import { useAppSelector } from "@store/hooks";
interface Props {
  courseInfo: ICourse;
  currentStudyClass?: string | null
}

interface TaskItem {
  classId: string;
  id: string;
  responsibleMemberIds: string[];
  task: ITaskFreeCourses;
  taskId: string;
  isCloseAccess: boolean;
  dateOfAccess: string | null;
  responsibleMembers: Array<IMember>;
}

const TaskList: FC<Props> = ({ courseInfo, currentStudyClass }) => {
  const navigate = useNavigate();
  const { t } = useTranslation([
    "user",
    "common",
    "course",
    "enum",
    "dictionary",
  ]);
  const { common } = useTheme().palette;
  const { courseId } = useParams<{ courseId: string }>();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [responsibleMemberList, setResponsibleMemberList] = useState<
    IUserSearchItem[]
  >([]);
  const profile = useAppSelector((store) => store.account.profileInfo.pltaId);
  const [taskList, setTaskList] = useState<TaskItem[]>([]);
  const [filter, setFilter] = useState<IFilter>(initialFilter);
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { activeStudyClass, setActiveStudyClass, flowItem } =
    useFlowInfoContext();
  const formatText = {
    ORAL: t("enum:ORAL"),
    WRITTEN: t("enum:WRITTEN"),
    QUIZ: t("enum:QUIZ"),
  };
  const { courseData } = useContext(CourseContext);
  const searchByName = (searchValue: string) => {
    const new_filter = { ...filter, name: searchValue };
    setFilter(new_filter);
  };

  const searchByParams = (value: any, paramName: string) => {
    if (paramName === "format") setFilter({ ...filter, format: value });
    if (paramName === "status") setFilter({ ...filter, status: value?.id });
    if (paramName === "type") setFilter({ ...filter, type: value });
    if (paramName === "responsibleMember")
      setFilter({ ...filter, responsibleMemberId: value });
  };

  const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    if (name === "showMyTasks") {
      setFilter({ ...filter, showMyTasks: checked });
    } else {
      setFilter({
        ...filter,
        isNoMark: checked && name === "isNoMark",
        isExcludeTotalMark: checked && name === "isExcludeTotalMark",
      });
    }
  };

  //api
  const { data: studyClasses, refetch } = useGetAllStudyClassesByCourseIdQuery(
    courseId || ""
  );

  useEffect(() => {
    if (studyClasses) {
      serviceMember
        .getMemberList(studyClasses[activeStudyClass]?.id, [
          CourseRole.TUTOR,
          CourseRole.ASSISTANT,
          CourseRole.EXAMINER,
        ])
        .then((res) => {
          if (res.status === 200) {
            setResponsibleMemberList(res.data);
          }
        });
    }
  }, [activeStudyClass, studyClasses]);
  useEffect(() => {
    if (studyClasses) {
      serviceTask
        .getAssessmentTaskList(studyClasses[activeStudyClass]?.id, {
          name: filter.name,
          status: filter.status,
          type: filter.type,
          format: filter.format,
          responsibleMemberId: filter.responsibleMemberId,
          showMyTasks: filter.showMyTasks,
          isNoMark: filter.isNoMark,
          isExcludeTotalMark: filter.isExcludeTotalMark,
        })
        .then((res) => {
          if (res.status === 200) {
            if (
              courseData?.roleType === CourseRole.EXAMINER ||
              courseData?.roleType === CourseRole.ASSISTANT
            ) {
              setTaskList(
                res.data.filter((task: ITask) =>
                  task.responsibleMembers.some(
                    (member) => member.userId === courseData?.userId
                  )
                )
              );
            } else if (courseData?.roleType === Role.EDUCATOR) {
              const filteredTasks = res.data.filter(element =>
                element.responsibleMembers && element.responsibleMembers.some(member => member.userId === profile)
              );
              setTaskList(filteredTasks);
            } else {
              setTaskList(res.data);
            }
          }
        });
    }
  }, [
    filter,
    courseData?.roleType,
    courseData?.userId,
    studyClasses,
    activeStudyClass,
    profile,
  ]);
  // FIXME: When you delete in flow page or add flow that cached in browser need to fix later
  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if(currentStudyClass && studyClasses) {
      const index = studyClasses.findIndex(studyClass => studyClass.id === currentStudyClass);
      if (index !== -1) setActiveStudyClass(index);
    }
  }, [currentStudyClass, setActiveStudyClass, studyClasses])

  const onClickTaskCard = (taskName: string | null, taskId: string | null) => {
    if (courseData?.status !== "CLOSED" && (courseData?.allowEstimateAfterCompletion || (studyClasses && studyClasses[activeStudyClass].status === CourseStatus.PUBLISHED))) {
      const crumb: IBreadcrumb = {
        level: 3,
        page: "task",
        label: `${taskName}`,
        path: `/myCourses/${courseId}/task-recipients/${taskId}`,
        tabNum: null,
      };
      if (courseId) setBreadcrumbs(courseId, [...getBreadcrumbs(courseId), crumb]);
      navigate(
        `/myCourses/${courseId}/task-recipients/${taskId}/flows/${studyClasses && studyClasses[activeStudyClass]?.id
        }`
      );
    }
  };

  if (
    studyClasses &&
    studyClasses.length > 0 &&
    responsibleMemberList.length === 0
  ) {
    return (
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        {studyClasses && (
          <Box
            display="flex"
            gap={1}
            sx={{
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { width: "6px", height: "2px" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#E5E0EB",
                borderRadius: "6px",
              },
            }}
          >
            {studyClasses?.map((classes, index) => {
              return (
                <Box
                  key={classes.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40px",
                    fontSize: "14px",
                    background:
                      activeStudyClass === index
                        ? common.primaryColor
                        : "white",
                    padding: "12px 16px",
                    borderRadius: "6px",
                    width: "94px",
                    whiteSpace: "nowrap",
                    color: activeStudyClass === index ? "white" : "#8E8E93",
                    cursor: "pointer",
                    "&:hover": {
                      background:
                        activeStudyClass === index
                          ? common.primaryColor
                          : "#F5EFFF",
                    },
                    mt: 2,
                  }}
                  onClick={() => {
                    setActiveStudyClass(index);
                  }}
                >
                  {t("course:EDU_STREAM")} #{index + 1}
                </Box>
              );
            })}
          </Box>
        )}
        <HintComponent
          leftBorder
          svg={<WarningHintComponent />}
          text={t("course:hints.IF_NO_PARTICIPANTS")}
        />
        <EmptyComponent
          height={384}
          text={t("common:hints.NO_ASSIGNMENTS_FOR_GRADING")}
          svg={<EmptyMeasurementSvg />}
        />
      </Box>
    );
  }

  if (studyClasses && studyClasses.length === 0) {
    return (
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <HintComponent
          leftBorder
          svg={<WarningHintComponent />}
          text={t("course:hints.IF_NO_FLOWS")}
        />
        <EmptyComponent
          height={384}
          text={t("common:hints.NO_ASSIGNMENTS_FOR_GRADING")}
          svg={<EmptyMeasurementSvg />}
        />
      </Box>
    );
  }

  return (
    <Box>
      <UnhiddenHints text={t("course:hints.HINT_FOR_ASSESSMENT_SECTION")} />
      {studyClasses && (
        <Box
          display="flex"
          gap={1}
          sx={{
            width: "100%",
            overflowX: "auto",
            "&::-webkit-scrollbar": { width: "6px", height: "2px" },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#E5E0EB",
              borderRadius: "6px",
            },
          }}
        >
          {studyClasses?.map((classes, index) => {
            return (
              <Box
                key={classes.id}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "40px",
                  fontSize: "14px",
                  background:
                    activeStudyClass === index ? common.primaryColor : "white",
                  padding: "12px 16px",
                  borderRadius: "6px",
                  width: "94px",
                  whiteSpace: "nowrap",
                  color: activeStudyClass === index ? "white" : "#8E8E93",
                  cursor: "pointer",
                  "&:hover": {
                    background:
                      activeStudyClass === index
                        ? common.primaryColor
                        : "#F5EFFF",
                  },
                  mt: 2,
                }}
                onClick={() => {
                  setActiveStudyClass(index);
                }}
              >
                {t("course:EDU_STREAM")} #{index + 1}
              </Box>
            );
          })}
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          background: "#FFFFFF",
          padding: "15px",
          borderRadius: "6px",
          minHeight: "650px",
          mt: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          <TextField
            label={t("common:placeholders.SEARCH_BY_NAME")}
            color="primary"
            size="small"
            name="searchValue"
            onChange={(e) => searchByName(e.target.value)}
            sx={{
              width: "100%",
              "& label.Mui-focused": { color: common.primaryColor },
              "& .MuiInput-underline:after": {
                borderBottomColor: common.primaryColor,
              },
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": { borderColor: common.primaryColor },
                "&.Mui-focused fieldset": { borderColor: common.primaryColor },
              },
            }}
          />
          <Box
            sx={{
              border: `1px solid ${common.borderSecondary}`,
              height: "40px",
              width: "124px",
              borderRadius: "9px",
              display: "flex",
              alignItems: "center",
              px: "4px",
              cursor: "pointer",
            }}
            onClick={() => setOpenFilter(!openFilter)}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                color: "black",
                mr: "8px",
                ml: "4px",
              }}
            >
              {t("common:FILTER")}
            </Typography>
            <TuneIcon />
            {openFilter ? (
              <ArrowDropUpIcon sx={{ width: "30px", height: "30px" }} />
            ) : (
              <ArrowDropDownIcon sx={{ width: "30px", height: "30px" }} />
            )}
          </Box>
        </Box>
        {openFilter && (
          <Box display="flex" gap={2} flexDirection="column">
            <Box
              display="flex"
              gap={3}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <CustomAutocomplete
                name="format"
                placeholder={t("dictionary:FORMAT")}
                options={taskFormats}
                onChange={(_: any, taskFormat: any) =>
                  searchByParams(taskFormat, "format")
                }
              />
              <CustomAutocomplete
                name="type"
                placeholder={t("dictionary:TYPE")}
                options={taskTypes}
                onChange={(_: any, taskType: any) =>
                  searchByParams(taskType, "type")
                }
              />
              <CustomAutocomplete
                name="responsibleMember"
                placeholder={t("course:theme.RESPONSIBLE_TEACHER")}
                options={responsibleMemberList}
                onChange={(_: any, taskMember: any) => {
                  searchByParams(taskMember, "responsibleMember");
                }}
              />
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: { xs: "flex-start", sm: "center" },
                  gap: 2,
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    name="showMyTasks"
                    checked={filter.showMyTasks}
                    onChange={(e) => onChangeCheckbox(e)}
                    sx={{
                      color: common.primaryColor,
                      "&, &.Mui-checked": { color: common.primaryColor },
                    }}
                  />
                  <Typography>{t("common:actions.DISPLAY_TASKS")}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    name="isNoMark"
                    checked={filter.isNoMark}
                    onChange={(e) => onChangeCheckbox(e)}
                    sx={{
                      color: common.primaryColor,
                      "&, &.Mui-checked": { color: common.primaryColor },
                    }}
                  />
                  <Typography>
                    {t("course:theme.WITHOUT_EVALUATION")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    name="isExcludeTotalMark"
                    checked={filter.isExcludeTotalMark}
                    onChange={(e) => onChangeCheckbox(e)}
                    sx={{
                      color: common.primaryColor,
                      "&, &.Mui-checked": { color: common.primaryColor },
                    }}
                  />
                  <Typography>
                    {t("course:test.NOT_CONSIDER_WHEN_CALCULATING_FINAL_GRADE")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {taskList && taskList.length === 0 && (
          <EmptyDataComponent
            text={t("course:messages.NO_ASSIGNMENT_TO_GRADE")}
          />
        )}
        {taskList &&
          taskList.map(({ task, responsibleMembers, dateOfAccess }) => {
            return (
              <Box
                key={task.id}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "8px",
                  border: "1px solid #C1C1C1",
                  p: 2.5,
                  cursor: "pointer",
                  ":hover": { background: "#CACACA" },
                }}
                onClick={() => onClickTaskCard(task.name, task.id)}
              >
                <Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <NoteAltOutlinedIcon />
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {task.name}
                    </Typography>
                  </Box>
                  {(task.isNoMark || task.isExcludeTotalMark) && (
                    <Box display="flex" alignItems="center" gap={1}>
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "20px",
                          backgroundColor: "#D5BEFF",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            width: "15.5px",
                            height: "15.5px",
                            color: common.primaryColor,
                          }}
                        />
                      </Box>
                      <Typography alignItems="center">
                        {task.isNoMark
                          ? t("course:theme.WITHOUT_EVALUATION")
                          : t(
                            "course:theme.NOT_TAKEN_INTO_ACCOUNT_IN_FINAL_GRADE"
                          )}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    gap={1}
                  >
                    <Typography alignItems="center">
                      {t("dictionary:TYPE")}:{" "}
                      {task.type === "TASK"
                        ? t("enum:TASK")
                        : t("enum:CONTROL")}
                    </Typography>
                    {!matchesDownSm && (
                      <Box
                        sx={{
                          width: "6px",
                          height: "6px",
                          borderRadius: "6px",
                          backgroundColor: "#D9D9D9",
                        }}
                      />
                    )}
                    <Typography alignItems="center">
                      {t("dictionary:FORMAT")}: {formatText[task?.format || ""]}
                    </Typography>
                  </Box>
                  <Typography alignItems="center">
                    <span style={{ marginRight: 4 }}>
                      {t("course:theme.RESPONSIBLE_TEACHER")} :
                    </span>
                    {responsibleMembers?.map((member: any, index: number) => {
                      if (index !== 0)
                        return (
                          <Fragment key={member.id}>
                            , <UserFullName id={member.userId} />
                          </Fragment>
                        );
                      return (
                        <UserFullName key={member.id} id={member.userId} />
                      );
                    })}
                  </Typography>
                  {dateOfAccess && (
                    <Typography alignItems="center">
                      {t("course:theme.ACCESS_OPENING_DATE")}:{" "}
                      {formatDateWithHourMinute(dateOfAccess)}
                    </Typography>
                  )}
                </Box>
                <TaskListenerStats
                  taskId={task.id}
                  format={task.format}
                  classId={studyClasses && studyClasses[activeStudyClass]?.id}
                />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

const TaskListenerStats = ({ taskId, format, classId }) => {
  const [allListeners, setAllListeners] = useState<number | string>("");
  const [completedListeners, setCompletedListeners] = useState<number | string>(
    ""
  );
  const { t } = useTranslation(["course"]);

  useEffect(() => {
    if (taskId && classId) {
      serviceTask
        .getAssessmentSumListenerTaskId(taskId, classId)
        .then((res) => {
          if (res.status === 200) {
            setAllListeners(res.data.all);
            setCompletedListeners(res.data.completed);
          }
        });
    }
  }, [classId, taskId]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Avatar
        alt={`${taskId}`}
        src={require("@assets/images/task_assessment_icon.png")}
        sx={{
          width: 32,
          height: 32,
          backgroundColor: "#FFF7E5",
        }}
      />
      <Box sx={{ width: "140px" }}>
        <Typography sx={{ fontWeight: 500, fontSize: 20 }}>
          {completedListeners}/{allListeners}
        </Typography>
        <Typography sx={{ fontSize: 14, color: "#9DA6BA" }}>
          {t("course:test.COMPLETED_TASK")}
        </Typography>
      </Box>
    </Box>
  );
};

export default TaskList;
