import CustomModal from '@components/Modal'
import { Box, Divider, Typography, useTheme } from '@mui/material'
import CustomAutocomplete from '@ui/Autocomplete'
import React, { useState } from 'react'
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import CustomButton from '@ui/Button';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { serviceMember } from '@services/member';
import { useParams } from 'react-router-dom';
import { AuthorRole, Role } from '@interfaces/index';
import { serviceCourse } from '@services/course';

export const CoAuthorModal = ({ openModal, setOpenModal }) => {
  const { t } = useTranslation(['user', 'course', 'enum', 'common', 'profile']);
  const { common } = useTheme().palette;
  const { courseId } = useParams()
  const queryClient = useQueryClient()
  const [addCoAuthors, setAddCoAuthors] = useState([])
  const { data: coAuthorList } = useQuery({
    queryKey: ["CoAuthorsList"],
    queryFn: () => serviceMember.getNonCoAuthorList(courseId || "", { roleCode: Role.EDUCATOR })
  })

  const { mutate: handleAddCoAuthors } = useMutation({
    mutationFn: () => serviceCourse.createCoAuthors(courseId || "", addCoAuthors),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getCourseInfoAuthors'] })
      setOpenModal(!openModal)
    }
  })

  return (
    <Box>
      <CustomModal open={openModal} handleToggleModal={() => setOpenModal(!openModal)} maxWidth={500} showCloseIcon closeIsControlled px={2} py={2}>
        <Box>
          <Typography sx={{ fontWeight: 500, fontSize: "20px" }}>{t("course:coAuthors.ADD_CO_AUTHORS")}</Typography>
          <Divider sx={{ m: "24px 0px" }} />
          <CustomAutocomplete
            name='coauthors'
            selectAll
            multiple
            options={coAuthorList?.data || []}
            label={t("course:coAuthors.ASSIGN_CO_AUTHORS")}
            placeholder={t("common:placeholders.CHOOSE_EDUCATOR")}
            onChange={(_, value) => setAddCoAuthors(value.map((node) => {
              return { userId: node.id, courseId: courseId, authorRole: AuthorRole.COAUTHOR, username: node.fullName, profilePhotoId: node.profilePhotoId }
            }))
            }
            valueAsObject
          />
          <Box display="flex" m="15px 0">
            <Box sx={{ width: "10px", background: "#FFEACC", borderRadius: "6px 0 0 6px" }} />
            <Box display="flex" padding="3px 10px" gap={1.5}>
              <ReportProblemOutlinedIcon sx={{ color: "#FF9500" }} />
              <Typography fontSize="14px" sx={{ color: "#6D737A" }}>
                {t("course:hints.ADD_CO_AUTHORS_HINT")}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: '24px' }} gap={1.5}>
            <CustomButton
              width="92px"
              height="40px"
              borderRadius="8px"
              backgroundColor="#F6F6F6"
              fontColor={common.primaryColor}
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                lineHeight: "24px",
              }}
              onClick={() => setOpenModal(!openModal)}
            >
              {t('common:actions.CANCEL')}
            </CustomButton>
            <CustomButton
              width="146px"
              height="40px"
              borderRadius="8px"
              disabled={addCoAuthors.length === 0}
              backgroundColor={common.primaryColor}
              fontColor="#FFFFFF"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                lineHeight: "24px",
              }}
              onClick={() => handleAddCoAuthors()}
            >
              {t('common:actions.SAVE')}
            </CustomButton>
          </Box>
        </Box>
      </CustomModal>
    </Box>
  )
}
