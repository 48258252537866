import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import Logo from '@assets/images/Logo.png'
import { FC, useEffect, useState } from "react";

const heartbeat = keyframes`
  0% { transform: scale(1); }
  20% { transform: scale(1.2); }
  40% { transform: scale(1); }
  60% { transform: scale(1.2); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
`;

const StyledImage = styled.img`
    width: 6%;
    animation: ${heartbeat} 2s ease-in-out infinite
`
export interface LoadingScreenProps {
    delay?: number;
}

const LoadingScreen: FC<LoadingScreenProps> = ({
    delay = 250,
    ...props
  }) => {

    const [show, setShow] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true)
        }, delay)

        return () => {
            clearTimeout(timeout)
        }
    }, [delay])

    return show ? (
        <div 
        style={{
            width: '100vw',
            height: '100vh', 
            zIndex: 2, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center'
        }}
        >
            <StyledImage src={Logo} alt='' />
        </div>
    ) : null
}

export default LoadingScreen
