import { useEffect, useState } from "react";
import { Box, FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { LinearProgressWithLabel } from "@components/LinearProgressWithLabel";
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { formatDateWithTimezone, getOnlyDateAndFormat } from '@utils/functions';

import CustomSearch from "@ui/Search";
import { serviceCourse } from "@services/course";
import { serviceDict } from "@services/dictionary";
import { formatDate } from "@utils/functions";
import UnhiddenHints from "@components/UnhiddenHints";
import CustomAutocomplete from "@ui/Autocomplete";
import { useSelector } from "react-redux";
import Hint from "@components/Hint";
interface IFilter {
  name: string;
  fromDate: string | null;
  toDate: string | null;
  status: string | null;
}

export const Attendance = ({ courseData }: { courseData: any }) => {
  const { t } = useTranslation(["common", "course", "dictionary", "enum"]);
  const { classId, status } = useParams()
  const { common } = useTheme().palette;
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [period, setPeriod] = useState<boolean>(false);
  const [attendanceInfo, setAttendanceInfo] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>(null)
  const [filter, setFilter] = useState<IFilter>({ name: "", toDate: null, status: null, fromDate: null });
  const [courseInfo, setCourseInfo] = useState<any>(courseData)
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const currentDate = getOnlyDateAndFormat(formatDateWithTimezone(new Date()))

  useEffect(() => {
    if (classId && role === 'LISTENER') {
      const paginationConfig = {
        pageNumber: 0,
        size: 100,
        filter: { ...filter, classId: classId },
        sorting: {}
      }
      serviceCourse.getAttendanceList(paginationConfig).then((res) => {
        if (res.status === 200) {
          setAttendanceInfo(res.data)
        }
      })
    }
  }, [classId, filter, role])

  useEffect(() => {
    if (role !== 'LISTENER') {
      serviceDict.getTypeOptionById(courseData?.typeId).then((res) => {
        if (res.status === 200) setCourseInfo(res.data)
      })
    }
  }, [role, courseData?.typeId])

  const searchByName = (searchValue: string) => {
    setFilter({ ...filter, name: searchValue });
  };
  const selectByStatus = (event?: any) => {
    if (event) {
      setSelectedStatus(event);
      setFilter({ ...filter, status: event.id });
    } else {
      setSelectedStatus("ALL");
      setFilter({ ...filter, status: null });
    }
  }
  //TODO: we must add date filter

  const attendancePersent = attendanceInfo.filter((node) => node.attendanceStatus === "PRESENT" || node.attendanceStatus === "WORKED")

  const checkPicture = (attendance: string) => {
    switch (attendance) {
      case 'PRESENT':
        return <Box sx={{ width: '84px', height: '26px', backgroundColor: '#E9E9E9', borderRadius: '7px', textAlign: 'center' }}>
          <CheckIcon sx={{ color: '#3BCA23', fontWeight: '500' }} />
        </Box>
      case 'ABSENT':
        return <Box sx={{ width: '84px', height: '26px', backgroundColor: '#E9E9E9', borderRadius: '7px', textAlign: 'center', mt: '6px' }}>
          <ClearIcon sx={{ color: '#C20606', fontWeight: '500' }} />
        </Box>
      case 'WORKED':
        return <Box sx={{ width: '84px', height: '26px', backgroundColor: '#E9E9E9', borderRadius: '7px', textAlign: 'center', mt: '6px' }}>
          <PanoramaFishEyeIcon sx={{ color: '#FFCA0E', fontWeight: '500' }} />
        </Box>
      default:
        return <Box sx={{ width: '84px', height: '26px', backgroundColor: '#E9E9E9', borderRadius: '7px', textAlign: 'center', mt: '6px' }}>
          <PanoramaFishEyeIcon sx={{ color: '#FFCA0E', fontWeight: '500' }} />
        </Box>
    }
  }
  return (
    <Box display='flex' flexDirection='column' gap={1.5}>
      {courseInfo?.accountAttendanceInControlWork && (status === "completed" || status === "current") && role === 'LISTENER' &&
        <UnhiddenHints text={t('course:hints.ATTENDANCE_PAGE_HINT', {
          availablePassesPerCourse: courseInfo.availablePassesPerCourse
        })} />}
      {courseInfo?.accountAttendance && (status === "completed" || status === "current") && role !== 'LISTENER' && role === 'LISTENER' &&
        <UnhiddenHints text={t('course:hints.COURSE_TAKES_INTO_ACCOUNT_ATTENDANCE')} />
      }
      {role !== 'LISTENER' && <Hint text={t('course:hints.PREVIEW_ATTENDANCE_HINT')} />}
      <Box sx={{ width: '100%', backgroundColor: common.white, height: 'auto', border: `1px solid ${common.borderSecondary}`, borderRadius: '9px', p: '16px' }}>
        <Typography sx={{ fontWeight: '500', fontSize: '20px', lineHeight: '21px', mb: '19px' }}>{t('course:COURSE_ATTENDANCE')}</Typography>
        {attendanceInfo.length > 0 && <LinearProgressWithLabel sx={{ background: common.secondaryColor, '& .MuiLinearProgress-bar': { backgroundColor: common.primaryColor } }} value={(attendancePersent.length / attendanceInfo.length) * 100} />}
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, justifyContent: 'space-between', mt: '16px', mx: '21px' }}>
          <CustomSearch onChange={searchByName} placeholder={t('common:placeholders.SEARCH_BY_NAME')} />
          <Box onClick={() => setOpenFilter(!openFilter)} sx={{ width: '130px', border: `1px solid ${common.borderSecondary}`, borderRadius: '6px', px: '6px', py: '6px', display: 'flex', alignItems: 'center' }}>
            <Typography>{t('common:FILTER')}</Typography>
            <TuneIcon sx={{ ml: '4px', color: "#ABA7AF" }} />
            {openFilter ? <ArrowDropDownIcon sx={{ ml: '4px', rotate: '180deg' }} /> : <ArrowDropDownIcon sx={{ ml: '4px' }} />}
          </Box>
        </Box>
        <Box sx={{ width: '97%', mx: '21px' }}>
          <Box sx={{ display: openFilter ? 'block' : 'none' }}>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' }, alignItems: { sm: 'center', xs: 'flex-start' }, mb: '16px' }}>
              {/* <FormControlLabel control={
                <Checkbox checked={period} onChange={(e) => setPeriod(e.target.checked)} sx={{ color: common.primaryColor, "&, &.Mui-checked": { color: common.primaryColor } }} />
              } label="Период проведения курса" /> */}
              <FormControl fullWidth size="small" sx={{ width: '235px' }} color="secondary">
                <CustomAutocomplete
                  name="statusAttendance"
                  label={t('course:listener.ATTENDANCE_MARKS')}
                  defaultValue={selectedStatus}
                  onChange={(_, event) => selectByStatus(event)}
                  options={[{ id: "PRESENT", name: t('common:statuses.PRESENT') }, { id: "ABSENT", name: t('common:statuses.ABSENT') }, { id: "WORKED", name: t('common:statuses.MADE_UP') }]} />
                {/* <Select label="Отметки посещаемости" value={selectedStatus} onChange={(event) => selectByStatus(event)}>
              <MenuItem value={"PRESENT"}>Был</MenuItem>
              <MenuItem value={"ABSENT"}>Не был</MenuItem>
              <MenuItem value={"WORKED"}>Отработал</MenuItem>
            </Select> */}
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
            <TableContainer component={Paper} sx={{ mt: '28px' }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: '500', lineHeight: '20px' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>{t('course:listener.DATE_OF_ATTENDANCE')} <ImportExportIcon sx={{ ml: '12px' }} /></Box>
                    </TableCell>
                    <TableCell sx={{ fontWeight: '500', lineHeight: '20px' }}>{t('dictionary:TITLE')}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: '500', lineHeight: '20px' }} >{t('course:listener.ATTENDANCE_MARK')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendanceInfo.length > 0 ?
                    attendanceInfo.map((row, index) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">{formatDate(row.attendanceDictionary.date)}</TableCell>
                        <TableCell >{row.attendanceDictionary.name}</TableCell>
                        <TableCell align="right"><Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: '36px' }}>{checkPicture(row.attendanceStatus)}</Box></TableCell>
                      </TableRow>
                    ))
                    : <>
                      {role !== 'LISTENER' ?
                        <>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">{currentDate}</TableCell>
                            <TableCell >{t('enum:PRACTICE')}</TableCell>
                            <TableCell align="right"><Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: '36px' }}>{checkPicture('PRESENT')}</Box></TableCell>
                          </TableRow>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">{currentDate}</TableCell>
                            <TableCell>{t('enum:LECTURE')}</TableCell>
                            <TableCell align="right"><Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: '36px' }}>{checkPicture('ABSENT')}</Box></TableCell>
                          </TableRow>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">{currentDate}</TableCell>
                            <TableCell>{t('enum:LAB_WORK')}</TableCell>
                            <TableCell align="right"><Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: '36px' }}>{checkPicture('WORKED')}</Box></TableCell>
                          </TableRow>
                        </> : null
                      }

                    </>
                  }
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, alignItems: { xs: 'flex-start', sm: 'center' }, mt: 3, mb: '45px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '84px', height: '26px', backgroundColor: '#E9E9E9', borderRadius: '7px', textAlign: 'center' }}>
                  <CheckIcon sx={{ color: '#3BCA23', fontWeight: '500' }} />
                </Box>
                <Typography sx={{ ml: '8px' }}>{t('common:statuses.PRESENT')}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '84px', height: '26px', backgroundColor: '#E9E9E9', borderRadius: '7px', textAlign: 'center' }}>
                  <ClearIcon sx={{ color: '#C20606', fontWeight: '500' }} />
                </Box>
                <Typography sx={{ ml: '8px' }}>{t('common:statuses.ABSENT')}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '84px', height: '26px', backgroundColor: '#E9E9E9', borderRadius: '7px', textAlign: 'center' }}>
                  <PanoramaFishEyeIcon sx={{ color: '#FFCA0E', fontWeight: '500' }} />
                </Box>
                <Typography sx={{ ml: '8px' }}>{t('common:statuses.MADE_UP')}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
};