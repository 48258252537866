import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { AlertColor, Box, Typography, useTheme } from '@mui/material'
import { FieldArray, Formik, Form as FormikForm } from 'formik';
import FormikInput from '@ui/formik/Input';
import FormikAutocomplete from '@ui/formik/Autocomplete';
import CustomButton from '@ui/Button';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import isBefore from 'date-fns/isBefore';

import { serviceCourse } from '@services/course';
import CustomModal from '@components/Modal';
import { validationSchema } from './validation';
import DatePicker from '@ui/formik/DatePicker';
import { useTranslation } from 'react-i18next';
import { CourseContext } from "@context/CourseContext";
import { useSelector } from 'react-redux';
import { getFlowId } from '@store/journal/journalSelector';
interface Props {
  openVisitModal: boolean;
  handleCloseVisitModal: () => any;
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  updateLists: () => void;
  studyDict: any;
  disabledStudyDict: string[]
  startDate: string;
  endDate: string
}

export const AddVisitModal = ({ openVisitModal, handleCloseVisitModal, setMessage, setMessageOpen, setMessageType, updateLists, studyDict, disabledStudyDict, startDate, endDate }: Props) => {
  const { common } = useTheme().palette;
  const lng = localStorage.getItem("i18nextLng") || "kz";
  const { courseId } = useParams();
  const [lengthTypeOccupations, setLengthTypeOccupations] = useState<Array<any>>([{ id: 1 }])
  const { t } = useTranslation(["common", "course"]);
  const { courseData } = useContext(CourseContext);
  const flowId = useSelector(getFlowId);
  useEffect(() => {
    setLengthTypeOccupations([{ id: 1 }])
  }, [openVisitModal])

  return (
    <>
      <CustomModal
        open={openVisitModal}
        handleToggleModal={handleCloseVisitModal}
        minWidth={250}
        disableAutoFocus
      >
        <>
          <Typography fontWeight={600} fontSize="24px">{t('course:DETERMINE_DATE_FOR_MARKING_ATTENDANCE')}</Typography>
          <Formik
            initialValues={{ addVisit: [{ typeOccupations: null, markAll: null, name: null }], date: null }}
            enableReinitialize
            validationSchema={validationSchema(t)}
            onSubmit={(value) => {
              const attendanceBody = value.addVisit.map((visit) => {
                return {
                  classId: flowId,
                  name: visit.typeOccupations === 1 ? "Практика" : visit?.name,
                  date: value.date,
                  defaultStatus: visit.markAll ?? "NONE",
                  classTypeId: visit.typeOccupations
                }
              })
              serviceCourse.crateAttendance(attendanceBody).then((res) => {
                if (res.status === 200) {
                  handleCloseVisitModal()
                  setMessageOpen(true);
                  setMessageType("success");
                  setMessage("Успешно");
                  updateLists();
                  setTimeout(() => {
                    setMessageOpen(false);
                  }, 3000)
                }
              })
            }}>
            {({ values, submitForm }) => {
              return <FieldArray
                name='addVisit'
                render={() => (
                  <FormikForm>
                    <Box m="12px 0px" width={"100%"}>
                      <DatePicker label={t('common:label.DATE')}
                        name="date" isRequired
                        minDate={startDate}
                        maxDate={endDate}
                      />
                    </Box>
                    <Box display="flex" gap={18}>
                      <Typography>{t('course:TYPES_OF_CLASSES')}<span style={{ color: common.errorColor }}>*</span></Typography>
                      <Typography>{t('common:actions.MARK_EVERYONE_AS')}</Typography>
                    </Box>
                    {lengthTypeOccupations.map((length, index) => (
                      <Box key={length.id}>
                        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={1} mb="12px" >
                          <Box display="flex" alignItems="center" gap={1} width='100%'>
                            {lengthTypeOccupations.length > 1 && <Typography >{index + 1}</Typography>}
                            <Box display='flex' flexDirection='column' gap={1} width='100%'>
                              <FormikAutocomplete
                                name={`addVisit.${index}.typeOccupations`}
                                options={studyDict}
                                clearable={false}
                                sx={{ width: "100%" }}
                                disabledOptions={disabledStudyDict}
                              />
                            </Box>
                          </Box>
                          <Box display="flex" alignItems="center" gap={1} width='100%'>
                            <Box display='flex' flexDirection='column' gap={1} width='100%' ml={lengthTypeOccupations.length > 1 ? '15px' : '0'}>
                              <FormikAutocomplete
                                name={`addVisit.${index}.markAll`}
                                sx={{ width: "100%" }}
                                clearable={false}
                                disabled={isBefore(new Date(), new Date(values.date || ""))}
                                options={[{ id: "PRESENT", name: t('common:statuses.PRESENT') }, { id: "WORKED", name: t('common:statuses.MADE_UP') }, { id: "ABSENT", name: t('common:statuses.ABSENT') }]}
                              />
                            </Box>
                            <Box display='flex' alignItems='center'>
                              {lengthTypeOccupations.length > 1 &&
                                <DeleteOutlinedIcon color="error" sx={{ cursor: "pointer" }} onClick={(() => {
                                  setLengthTypeOccupations(lengthTypeOccupations => lengthTypeOccupations.filter((len, lenIndex) => lenIndex !== index))
                                  return values?.addVisit.filter((len, lenIndex) => lenIndex !== index)
                                })} />}
                            </Box>
                          </Box>
                        </Box>
                        {values?.addVisit[index]?.typeOccupations === 2 && <Box m="12px 0px" width={lengthTypeOccupations.length > 1 ? "485px" : "100%"}>
                          <FormikInput name={`addVisit.${index}.name`} label={t('common:label.NAME')} isRequired placeholder={t('common:placeholders.ENTER_NAME')} />
                        </Box>}
                      </Box>
                    ))}
                    {lengthTypeOccupations.length < 8 && <Box
                      color={common.primaryColor}
                      display="flex"
                      gap={1}
                      marginBottom="12px"
                      sx={{ cursor: "pointer" }}
                      onClick={() => setLengthTypeOccupations(lengthTypeOccupations => [...lengthTypeOccupations, { id: lengthTypeOccupations.length + 1 }])}>
                      <AddCircleOutlineOutlinedIcon />
                      <Typography>{t('common:actions.ADD_TYPE_OF_CLASS_FOR_ACCOUNTING_ATTENDANCE')}</Typography>
                    </Box>}
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <CustomButton
                        backgroundColor={common.btnCancel}
                        fontColor={common.primaryColor}
                        borderRadius="6px"
                        height="40px"
                        sx={{
                          alignItems: "center",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                        onClick={handleCloseVisitModal}
                      >
                        {t('common:actions.CANCEL')}
                      </CustomButton>
                      <CustomButton
                        height="40px"
                        backgroundColor={common.primaryColor}
                        borderRadius="8px"
                        fontColor={common.fontWhite}
                        onClick={submitForm}
                        sx={{
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "20px",
                          alignItems: "center",
                          color: "#FFFFFF",
                        }}
                      >
                        {t('common:actions.SAVE')}
                      </CustomButton>
                    </Box>
                  </FormikForm>
                )}
              />
            }}
          </Formik>
        </>
      </CustomModal>
    </>
  )
}
