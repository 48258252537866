import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AlertColor } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import { isEmpty } from 'lodash';

import Breadcrumbs from "@components/Breadcrumbs";
import Message from "@components/Message";
import PublishCourseModal from "@components/modals/PublishCourseModal";
import { useBreadcrumbs } from "@context/BreadcrumbsContext";
import { CourseContext } from "@context/CourseContext";
import { serviceCourse } from "@services/course";
import Description from "../TabList/Description";
import { SettingPage } from "../TabList/Settings";
import Flows from "../TabList/Flows";
import Content from "../TabList/Content";
import PreviewLogout from "@components/PreviewLogout";
import { ContentCurrentCourses } from "@pages/myCourses/Student/CurrentCourses/Content";
import { AuthorRole, CourseStatus, IBreadcrumb, ICourse, Role, TypeCode } from "@interfaces/index";
import { ParticipantsPage } from "../TabList/Participants/ParticipantsPage";

interface IProps {
  courseId: string,
  courseInfo: ICourse;
  setCourseInfo: (courseInfo: ICourse) => void;
};

const tabList = [
  {
    label: "course:DESCRIPTION",
    tabName: "course:DESCRIPTION",
    step: 1
  },
  {
    label: "course:SETTINGS",
    tabName: "course:SETTINGS",
    step: 2
  },
  {
    label: "course:FLOW_CONTROL",
    tabName: "course:FLOWS",
    step: 3
  },
  {
    label: "course:PARTICIPANTS",
    tabName: "course:PARTICIPANTS",
    step: 4
  },
  {
    label: "course:CONTENT_COURSE",
    tabName: "course:CONTENT",
    step: 5
  },
  {
    label: "common:actions.PREVIEW",
    tabName: "common:actions.PREVIEW",
    step: 6
  },
];

const SavedCourse = ({ courseId, courseInfo, setCourseInfo }: IProps) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["course", "common", "profile"]);
  const [publishBtnDisabled, setPublishBtnDisabled] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [blockedTabAnchorEl, setBlockedTabAnchorEl] = useState<HTMLElement | null>(null);
  const [success, setSuccess] = useState<boolean>(false)
  const [attention, setAttention] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('')
  const [messageType, setMessageType] = useState<AlertColor>('success');
  const { courseData, setCourseData } = useContext(CourseContext);
  const { getBreadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const [triggerMessage, setTriggerMessage] = useState<string>()
  const stepLabel = getBreadcrumbs(courseId).find((x: IBreadcrumb) => x.level === 2)?.label || "course:DESCRIPTION";
  const currentStep = getBreadcrumbs(courseId).find((x: IBreadcrumb) => x.level === 2)?.tabNum || 1;
  const [isPublishing, setIsPublishing] = useState<boolean>(false)
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role } = profile.authorityInfo;
  const navigate = useNavigate();
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const isCoAuthor = courseData?.authors.filter((author) => author.authorRole === AuthorRole.COAUTHOR).some((author) => author.userId === courseData?.userId)

  const handlePublishCoursePopover = (event: React.MouseEvent<HTMLElement> | null) => {
    setAnchorEl(event ? event.currentTarget : event);
  };

  const popoverOpen = Boolean(anchorEl);

  const handleBlockedTabPopover = (event: React.MouseEvent<HTMLElement> | null) => {
    setBlockedTabAnchorEl(event ? event.currentTarget : event);
  };

  const blockedTabPopover = Boolean(blockedTabAnchorEl);

  const [openPublishCourseModal, setOpenPublishCourseModal] = useState(false);
  const handleTogglePublishCourseModal = (modalState: boolean, success?: boolean, attention?: boolean, message?: string) => {
    setSuccess(success || false)
    setAttention(attention || false)
    setModalMessage(message || "")
    setOpenPublishCourseModal(modalState);
    if (!modalState && triggerMessage === 'SUCCESS') {
      setCourseInfo({ ...courseInfo, status: CourseStatus.PUBLISHED });
      setCourseData({ ...courseData, status: CourseStatus.PUBLISHED });
    }
  }

  useEffect(() => {
    let updatedCrumbs = [...getBreadcrumbs(courseId)].filter((x: IBreadcrumb) => x.level !== 3 && x.level !== 4);
    if (updatedCrumbs.length === 0) {
      updatedCrumbs.push({ level: 1, page: "course", label: `${courseInfo?.name}`, path: `/myCourses/${courseId}`, tabNum: null });
      updatedCrumbs.push({ level: 2, page: 'course', label: "course:DESCRIPTION", path: window.location.pathname, tabNum: 1 });
    } else if (updatedCrumbs.length === 1) {
      updatedCrumbs.push({ level: 2, page: 'course', label: "course:DESCRIPTION", path: window.location.pathname, tabNum: 1 });
    }
    // Check if the breadcrumbs have actually changed before updating
    if (JSON.stringify(updatedCrumbs) !== JSON.stringify(getBreadcrumbs(courseId))) {
      setBreadcrumbs(courseId, updatedCrumbs);
    }
  }, [getBreadcrumbs, setBreadcrumbs, courseId, courseInfo?.name]);

  useEffect(() => {
    const settings = courseInfo.courseSetting;
    // EDU COURSE MANDATORY FIELDS
    if (courseInfo.typeCode === TypeCode.EDU) {
      if (isEmpty(courseInfo.name) || isEmpty(String(settings.langId)) || isEmpty(courseInfo.shortDescription) ||
        isEmpty(courseInfo.description) || isEmpty(String(courseInfo.credits)) ||
        (isEmpty(courseInfo.coverFileId) && isEmpty(courseInfo.coverColor)) ||
        isEmpty(settings.subdivisionIds) ||
        // isEmpty(settings.eduProgramIds) ||
        isEmpty(String(settings.gradingSystemId))) {
        setPublishBtnDisabled(true);
      } else {
        setPublishBtnDisabled(false);
      }
    } else {
      // FREE COURSE MANDATORY FIELDS
      if (isEmpty(courseInfo.name) || isEmpty(courseInfo.shortDescription) ||
        isEmpty(courseInfo.description) || isEmpty(String(courseInfo.categoryId)) ||
        isEmpty(courseInfo.targetAudience) || isEmpty(String(courseInfo.difficultyLevelId)) ||
        (isEmpty(courseInfo.coverFileId) && isEmpty(courseInfo.coverColor)) ||
        (isEmpty(courseInfo.promoVideoId) && isEmpty(courseInfo.promoVideoUrl)) ||
        settings.langId === null || isEmpty(settings.formatType) ||
        isEmpty(settings.durationType) || isEmpty(String(settings.gradingSystemId)) ||
        (settings?.completedTasksRate === null || isEmpty(String(settings?.completedTasksRate))) ||
        (settings?.minLimitForTask === null || isEmpty(String(settings?.minLimitForTask)))
      ) {
        setPublishBtnDisabled(true);
      } else {
        setPublishBtnDisabled(false);
      }
    }
  }, [
    courseInfo.name,
    courseInfo.shortDescription,
    courseInfo.description,
    courseInfo.credits,
    courseInfo.coverFileId,
    courseInfo.coverColor,
    courseInfo.courseSetting,
    courseInfo.categoryId,
    courseInfo.difficultyLevelId,
    courseInfo.promoVideoId,
    courseInfo.promoVideoUrl,
    courseInfo.targetAudience,
    courseInfo.typeCode
  ]);

  const changeStep = (step: number, label: string) => {
    const newCrumbs = [...getBreadcrumbs(courseId)].map((crumb) => {
      // If the crumb to update is found, replace the crumb with updated fields
      if (crumb.level === 2) {
        return { ...crumb, label, tabNum: step };
      }
      // Otherwise, return the original crumb
      return crumb;
    });
    setBreadcrumbs(courseId, newCrumbs);
  }

  const publishCourse = () => {
    setIsPublishing(true)
    if (courseId) {
      serviceCourse.changeCourseStatus(courseId, CourseStatus.PUBLISHED).then(res => {
        if (res.status === 200) {
          handleTogglePublishCourseModal(true, true, false, t('COURSE_PUBLISHED'));
          setTriggerMessage('SUCCESS')
          setTimeout(() => {
            handleTogglePublishCourseModal(false)
            setCourseInfo({ ...courseInfo, status: CourseStatus.PUBLISHED });
            setCourseData({ ...courseData, status: CourseStatus.PUBLISHED });
          }, 3000)
        }
      }).catch(err => {
        if (err.response.status === 400) {
          // console.log(err)
          if (err.response.data.code === 'THERE_ARE_NO_PUBLISHED_ITEMS') {
            const params = err.response.data.params;
            const unpublishedItems = Object.values(params);
            const unpublishedItemsString = unpublishedItems.join(', ')
            handleTogglePublishCourseModal(true, false, false, t(`course:messages.${err.response.data.code}`, {
              unpublishedItems: unpublishedItemsString
            }));
          } else {
            handleTogglePublishCourseModal(true, false, false, t(`course:messages.${err.response.data.code}`));
          }
        }
      });
    }
  }

  const handleCloseCourse = () => {
    if (courseId) {
      serviceCourse.closeCourse(courseId).then((res) => {
        if (res.status === 200) {
          setSuccess(true)
          setAttention(false)
          setModalMessage(t('course:messages.COURSE_CLOSED'))
          setTimeout(() => {
            navigate('/courses')
          }, 3000)
        }
      })
    }
  }

  return (
    <Grid>
      <Grid container>
        <Grid item xs={10}>
          <Box sx={{ display: 'flex', mb: 3 }}>
            <Breadcrumbs id={courseId} />
          </Box>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { sm: "36px", xs: '28px' },
              lineHeight: "44px"
            }}
          >
            {t(`${stepLabel}`)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                lineHeight: "18px"
              }}
            >
              {t("COURSE_STATUS")}
            </Typography>
            <Button
              sx={{
                background: `${courseInfo.status === CourseStatus.SAVED ? "#E4E5E7" : "#FFEA8A"}`,
                color: "#000000",
                textTransform: "none",
                borderRadius: "10px",
                height: "28px"
              }}
            >
              {t(courseInfo?.status || CourseStatus.SAVED)}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction='row-reverse' mt={2}>
        <Grid item xs={12} lg={2} alignItems='center' justifyContent='center' >
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: 'row', lg: "column" },
                alignItems: "center",
                gap: { xs: 1, lg: 3 },
                width: { xs: '250px', sm: '550px', lg: '100%' },
                marginLeft: { lg: '24px' },
                marginBottom: { xs: '10px', lg: 0 },
                overflowX: 'auto',
                background: common.fontWhite,
                borderRadius: "12px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: { sm: 2, xs: 1 },
                  flexDirection: { xs: 'row', lg: "column" },
                  width: "100%",
                  alignItems: "center",
                  py: 2,
                  px: 2,
                  border: `1px solid ${common.borderSecondary}`,
                  borderRadius: "12px"
                }}
              >
                {currentStep !== 6 && tabList.map((item, index) => {
                  if (item.step === 4 && courseData?.roleType === Role.ADMIN)
                    return (
                      <Box
                        key={`course_creation_step_btns_${item.step}_${index}`}
                        aria-owns={blockedTabPopover ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handleBlockedTabPopover}
                        onMouseLeave={() => handleBlockedTabPopover(null)}
                        sx={{
                          width: '100%',
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <Button
                          sx={{
                            borderRadius: "8px",
                            width: "100%",
                            minWidth: '100px',
                            border: `2px solid ${common.primaryColor}`,
                            textTransform: "none",
                            fontSize: '14px'
                          }}
                          disabled
                        >
                          {t(`${item.tabName}`)}
                        </Button>
                      </Box>
                    );
                  if (item.step === 6 && !(isAuthor || isCoAuthor)) return null;
                  return (
                    <Button
                      key={`course_creation_step_btns_${item.step}_${index}`}
                      sx={{
                        background: currentStep === item.step ? common.primaryColor : common.fontWhite,
                        color: currentStep === item.step ? common.fontWhite : common.primaryColor,
                        "&.MuiButtonBase-root:hover": {
                          background: common.secondaryColor,
                          color: common.primaryColor
                        },
                        borderRadius: "8px",
                        width: "100%",
                        minWidth: '100px',
                        border: `2px solid ${common.primaryColor}`,
                        textTransform: "none",
                        fontSize: '14px'
                      }}
                      onClick={() => changeStep(item.step, item.label)}
                    >
                      {t(`${item.tabName}`)}
                    </Button>
                  );
                })}
                {currentStep === 6 &&
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
                    <Button
                      sx={{
                        background: common.primaryColor,
                        color: common.fontWhite,
                        "&.MuiButtonBase-root:hover": {
                          background: common.primaryColor,
                          color: common.fontWhite
                        },
                        borderRadius: "8px",
                        width: "100%",
                        minWidth: '100px',
                        border: `2px solid ${common.primaryColor}`,
                        textTransform: "none",
                        fontSize: '14px'
                      }}
                    >
                      {t('course:CONTENT')}
                    </Button>
                  </Box>
                }
                {currentStep !== 6 && isAuthor &&
                  <Box
                    aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePublishCoursePopover}
                    onMouseLeave={() => handlePublishCoursePopover(null)}
                    sx={{
                      width: '100%',
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Button
                      sx={{
                        background: common.fontWhite,
                        color: common.primaryColor,
                        "&.MuiButtonBase-root:hover": {
                          background: common.secondaryColor,
                          color: common.primaryColor
                        },
                        borderRadius: "8px",
                        width: "100%",
                        border: `2px solid ${common.primaryColor}`,
                        textTransform: "none"
                      }}
                      onClick={() => publishCourse()}
                      disabled={publishBtnDisabled}
                    >
                      {t("common:actions.PUBLISH")}
                    </Button>
                  </Box>
                }
                {courseInfo.externalId && role === Role.LOCAL_ADMIN &&
                  <Button
                    sx={{
                      background: common.fontWhite,
                      color: common.primaryColor,
                      borderRadius: "8px",
                      width: '100%',
                      minWidth: '120px',
                      border: `2px solid ${common.primaryColor}`,
                      textTransform: "none",
                      fontSize: '14px'
                    }}
                    onClick={() => {
                      setOpenPublishCourseModal(true)
                      setModalMessage(t('common:messages.ARE_YOU_SURE_WANT_TO_CLOSE_COURSE'))
                      setIsPublishing(false)
                      setAttention(true)
                    }}
                  >
                    {t("common:actions.CLOSE_THE_COURSE")}
                  </Button>
                }
                {popoverOpen && publishBtnDisabled &&
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={popoverOpen && publishBtnDisabled}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    onClose={() => handlePublishCoursePopover(null)}
                    disableRestoreFocus
                    disableScrollLock
                  >
                    <Box sx={{
                      p: 1,
                      color: common.fontWhite,
                      backgroundColor: '#1A1F36',
                      width: '200px',
                      fontFamily: 'Golos',
                      fontSize: '14px'
                    }}>{t('course:hints.FILL_ALL_REQUIRED_FIELDS_TO_PUBLISH_COURSE')}</Box>
                  </Popover>
                }
                {blockedTabPopover &&
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={blockedTabPopover}
                    anchorEl={blockedTabAnchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    onClose={() => handleBlockedTabPopover(null)}
                    disableRestoreFocus
                    disableScrollLock
                  >
                    <Box sx={{
                      p: 1,
                      color: common.fontWhite,
                      backgroundColor: '#1A1F36',
                      width: '200px',
                      fontFamily: 'Golos',
                      fontSize: '14px'
                    }}>{t('course:MODULE_ACCESS_LIMITED')}</Box>
                  </Popover>
                }
              </Box>
            </Box>
          </Box>
        </Grid>
        {currentStep && tabList.map((item, index) => {
          if (currentStep !== item.step) return null;
          return (
            <Grid
              item xs={12} lg={10}
              key={`course_creation_step_${item.step}_${index}`}
            >
              {messageOpen &&
                <Box sx={{ mb: 2 }}>
                  <Message
                    type={messageType}
                    message={message}
                    setMessageOpen={setMessageOpen}
                  />
                </Box>
              }
              {(() => {
                switch (item.step) {
                  case 1: return (
                    <Description
                      courseInfo={courseInfo}
                      setCourseInfo={setCourseInfo}
                      setMessageOpen={setMessageOpen}
                      setMessage={setMessage}
                      setMessageType={setMessageType}
                    />
                  );
                  case 2: return (
                    <SettingPage
                      courseInfo={courseInfo}
                      setCourseInfo={setCourseInfo}
                      setMessageOpen={setMessageOpen}
                      setMessage={setMessage}
                      setMessageType={setMessageType}
                    />
                  );
                  case 3: return <Flows />;
                  case 4: return <ParticipantsPage />;
                  case 6: return <ContentCurrentCourses />;
                  default: return (
                    <Content
                      setMessageOpen={setMessageOpen}
                      setMessage={setMessage}
                      setMessageType={setMessageType}
                    />
                  );
                }
              })()}
            </Grid>
          );
        })}
      </Grid>
      {currentStep === 6 && <PreviewLogout courseId={courseId} />}
      {openPublishCourseModal &&
        <PublishCourseModal
          openModal={openPublishCourseModal}
          handleToggleModal={handleTogglePublishCourseModal}
          publishCourse={isPublishing ? publishCourse : handleCloseCourse}
          success={success}
          attention={attention}
          message={modalMessage}
        />
      }
    </Grid>
  );
}

export default SavedCourse;