import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MenuIcon from '@mui/icons-material/Menu';
import { Box } from '@mui/system';
import { useLocation, Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useTranslation } from "react-i18next";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import { useSelector } from "react-redux";
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Role } from "@interfaces/index";

const FadeMenu = () => {
  const location = useLocation();
  const { common } = useTheme().palette;
  const { t } = useTranslation(["sideMenu"]);
  const profile = useSelector((store: any) => store.account.profileInfo);
  const { role, orgId } = profile.authorityInfo;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const MY_COURSES_ROLE_LIST = ["EDUCATOR", "LISTENER", "MANAGER"];
  const ADMIN_LIST = ["ADMIN", "LOCAL_ADMIN"];

  return (
    <>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon sx={{
          alignContent: 'center',
          color: common.primaryColor, px: { xl: 1, lg: 0.5, md: 0.5, sm: 0.5, xs: 0.5 }, width: 40, height: 40
        }} />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        color="secondary"
      >
        <Box sx={{ width: { sm: 450, xs: 350 }, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <RouterLink to={"/"} style={{ textDecoration: "none" }} onClick={handleClose}>
            <MenuItem
              sx={{
                background:
                  location.pathname === "/" ? common.primaryColor : "#FFF",
                borderRadius: "10px",
                color:
                  location.pathname === "/"
                    ? common.fontWhite
                    : common.fontPrimary,
                fontWeight: 500,
                "&:hover": {
                  color: common.primaryColor,
                  backgroundColor: common.secondaryColor,
                },
              }}
            >
              <AccountCircleOutlinedIcon sx={{ mr: 0.8 }} />
              {`${t("PROFILE")}`}
            </MenuItem>
          </RouterLink>
          {(MY_COURSES_ROLE_LIST.includes(role)) &&
            <RouterLink to={"/myCourses"} style={{ textDecoration: "none" }} onClick={handleClose}>
              <MenuItem
                sx={{
                  background:
                    location.pathname.includes("myCourses")
                      ? common.primaryColor
                      : "#FFF",
                  borderRadius: "10px",
                  color:
                    location.pathname.includes("myCourses")
                      ? common.fontWhite
                      : common.fontPrimary,
                  fontWeight: 500,
                  "&:hover": {
                    color: common.primaryColor,
                    backgroundColor: common.secondaryColor,
                  },
                }}
              >
                <SchoolOutlinedIcon sx={{ mr: 0.8 }} />
                {`${t("MY_COURSES")}`}
              </MenuItem>
            </RouterLink>
          }
          {ADMIN_LIST.includes(role) &&
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xl: 1.2, lg: 1.2, md: 1.2, sm: 0.8, xs: 0.8 },
              }}
              onClick={handleClose}
            >
              <RouterLink to={"/users"} style={{ textDecoration: "none" }}>
                <MenuItem
                  sx={{
                    background:
                      location.pathname.includes("users") ? common.primaryColor : "#FFF",
                    borderRadius: "10px",
                    color:
                      location.pathname.includes("users")
                        ? common.fontWhite
                        : common.fontPrimary,
                    fontWeight: 500,
                    "&:hover": {
                      color: common.primaryColor,
                      backgroundColor: common.secondaryColor,
                    },
                  }}
                >
                  <PeopleAltOutlinedIcon sx={{ mr: 0.8 }} />
                  {t("USERS")}
                </MenuItem>
              </RouterLink>
              {role === Role.LOCAL_ADMIN &&
                <RouterLink to={`/settings`} style={{ textDecoration: "none" }}>
                  <MenuItem
                    sx={{
                      background:
                        location.pathname.includes("settings") ? common.primaryColor : "#FFF",
                      borderRadius: "10px",
                      color:
                        location.pathname.includes("settings")
                          ? common.fontWhite
                          : common.fontPrimary,
                      fontWeight: 500,
                      "&:hover": {
                        color: common.primaryColor,
                        backgroundColor: common.secondaryColor,
                      },
                    }}
                  >
                    <SettingsOutlinedIcon sx={{ mr: 0.8 }} />
                    {`${t("SETTINGS")}`}
                  </MenuItem>
                </RouterLink>}
              <RouterLink to={`/courses`} style={{ textDecoration: "none" }}>
                <MenuItem
                  sx={{
                    background:
                      location.pathname.includes("courses") || location.pathname.includes('myCourses') ? common.primaryColor : "#FFF",
                    borderRadius: "10px",
                    color:
                      location.pathname.includes("courses") || location.pathname.includes('myCourses')
                        ? common.fontWhite
                        : common.fontPrimary,
                    fontWeight: 500,
                    "&:hover": {
                      color: common.primaryColor,
                      backgroundColor: common.secondaryColor,
                    },
                  }}
                >
                  <AutoStoriesOutlinedIcon sx={{ mr: 0.8 }} />
                  {`${t("COURSES")}`}
                </MenuItem>
              </RouterLink>
            </Box>
          }
          {role === Role.LISTENER && orgId !== 0 &&
            <RouterLink to={"/courseCatalogue"} style={{ textDecoration: "none" }} onClick={handleClose}>
              <MenuItem
                sx={{
                  background:
                    location.pathname.includes("courseCatalogue")
                      ? common.primaryColor
                      : "#FFF",
                  borderRadius: "10px",
                  color:
                    location.pathname.includes("courseCatalogue")
                      ? common.fontWhite
                      : common.fontPrimary,
                  fontWeight: 500,
                  "&:hover": {
                    color: common.primaryColor,
                    backgroundColor: common.secondaryColor,
                  },
                }}
              >
                <AutoStoriesOutlinedIcon sx={{ mr: 0.8 }} />
                {`${t('enum:COURSE_CATALOG')}`}
              </MenuItem>
            </RouterLink>}
        </Box>
      </Menu>
    </>
  );
}

export default FadeMenu;