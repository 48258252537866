
import { AxiosRequestConfig } from "axios";
import axios from "@utils/axios";

class API {
  public apiVersion = "/api";

  async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await axios.get<T>(this.apiVersion + url, config);
    return response.data;
  }

  async post<T, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) {
    const response = await axios
      .post<T>(this.apiVersion + url, data, config);
    return response.data;
  }

  async put<T, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) {
    const response = await axios
      .put<T>(this.apiVersion + url, data, config);
    return response.data;
  }
  async delete<T, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) {
    const response = await axios
      .delete<T>(this.apiVersion + url, config);
    return response.data;
  }
  async postWithResponse<T, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) {
    const response = await axios
      .post<T>(this.apiVersion + url, data, config);
    return response;
  }

  async putWithResponse<T, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) {
    const response = await axios
      .put<T>(this.apiVersion + url, data, config);
    return response;
  }
  async deleteWithResponse<T, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) {
    const response = await axios
      .delete<T>(this.apiVersion + url, config);
    return response;
  }
}

export interface ResponseWithContent<T = unknown> {
  content: T[];
}

export interface DefaultGetParams {
  size?: number;
  page?: number;
  sort?: string;
}

export default API;
