import React, { useMemo, useState } from 'react'
import { AlertColor, Box, IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { useTranslation } from 'react-i18next';
import { MaterialReactTable, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import {
  type MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { serviceCourse } from '@services/course';
import { useParams } from 'react-router-dom';
import AvatarPicture from '@components/AvatarPicture';
import { formatDateWithHourMinute } from '@utils/functions';
import AvatarViaUserId from '@components/AvatarViaUserId';
import Message from '@components/Message';
import { AuthorRole, CourseStatus } from '@interfaces/index';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const CoAuthorsList = ({ courseInfo }) => {
  const { common } = useTheme().palette;
  const queryClient = useQueryClient()
  const [message, setMessage] = useState<string>("");
  const [anchorEls, setAnchorEls] = useState({});
  const [isPublicProfileOpen, setIsPublicProfileOpen] =
    useState<boolean>(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(
    {}
  );
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const { t } = useTranslation(['user', 'course', 'enum', 'common', 'profile', "integrationLogs"]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25, //customize the default page size
  });
  const open = Boolean(anchorEls);

  const { mutate: handleDelete } = useMutation({
    mutationFn: (id) => serviceCourse.updateStatusAuthor(id as any, false),
    onSuccess: () => {
      setMessage(t('common:messages.SUCCESSFULLY_REMOVED_MANUALLY'))
      setMessageOpen(true)
      setMessageType('success')
      setTimeout(() => {
        setMessageOpen(false)
      }, 3000);
      return queryClient.invalidateQueries({ queryKey: ['getCourseInfoAuthors'] });
    },
    onError: (error: any) => {
      setMessage(t(`common:messages.${error?.response?.data?.code}`))
      setMessageOpen(true)
      setMessageType('error')
      setTimeout(() => {
        setMessageOpen(false)
      }, 3000)
    }
  })
  const handlePopoverOpen = (profile: boolean) => {
    setPopoverAnchorEl(anchorEls);
    setAnchorEls({})
    setIsPublicProfileOpen(profile)
  };



  const handleMenuOpen = (event, rowId) => {
    setAnchorEls((prev) => ({ ...prev, [rowId]: event.currentTarget }));
  };

  const handleMenuClose = (rowId) => {
    setAnchorEls((prev) => ({ ...prev, [rowId]: null }));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'username',
        header: t("integrationLogs:MEMBER"),
        size: 300,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}>
            <Box sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}>
              <AvatarViaUserId
                userId={row?.original?.userId}
                sxProps={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                }}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                {renderedCellValue}
              </Typography>
            </Box>
          </Box>
        )
      },
      {
        accessorKey: 'createdAt',
        header: t("enum:ADDITION_DATE"),
        enableSorting: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          formatDateWithHourMinute(renderedCellValue)
        )
      },

    ],
    [t],
  );

  const table = useMaterialReactTable({
    columns,
    data: courseInfo?.data.authors.filter((authors) => authors.authorRole !== AuthorRole.AUTHOR), //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableSorting: true,
    enableColumnActions: false,
    enableExpandAll: false,
    enableColumnResizing: false,
    enableTopToolbar: false,
    enableRowNumbers: false,
    enableColumnPinning: false,
    enableRowActions: true,
    manualPagination: true,
    manualSorting: true,
    positionActionsColumn: "last",
    localization: {
      noRecordsToDisplay: t('dictionary:EMPTY'),
      rowsPerPage: t('enum:LINES_PER_PAGE'),
      actions: "...",
      expand: "",
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: 30,
        muiTableBodyCellProps: { align: "center" },
        muiTableHeadCellProps: { align: "center" }
      },
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default, //change default background color
    }),
    initialState: {
      columnPinning: { right: ["mrt-row-actions",] },
    },
    muiTableBodyRowProps: { hover: false },
    muiTableHeadCellProps: {
      sx: {
        borderLeft: '1px solid rgba(81, 81, 81, .5)',
        backgroundColor: common.tableHeaderColor
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderLeft: '1px solid rgba(81, 81, 81, .5)',
      },
    },
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        display: 'grid'
      },
    },
    muiTableContainerProps: {
      sx: {
        '&::-webkit-scrollbar': { height: '4px', width: '7px' },
        '&::-webkit-scrollbar-thumb': { backgroundColor: '#D9D9D9', borderRadius: '4px' }
      },
    },
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <MenuItem
        onClick={(e) => {
          handlePopoverOpen(true)
        }
        }

      >
        {t('common:actions.VIEWING_PUBLIC_PROFILE')}
      </MenuItem>,

      <MenuItem
        onClick={() => handlePopoverOpen(false)}

      >
        {t('common:actions.VIEW_PROFILE')}
      </MenuItem>,
      courseInfo?.data.status !== CourseStatus.CLOSED && <MenuItem
        key={2}
        onClick={() => {
          handleDelete(row.original.id as any)
          closeMenu()
        }}
      >
        {t("common:actions.DELETE")}
      </MenuItem>,
    ],
    renderRowActions: ({ row }) => {
      const menuItems = table.options.renderRowActionMenuItems({ row, closeMenu: () => { } });
      return menuItems.length === 0 ? null : (
        <>
          <IconButton onClick={(e) => handleMenuOpen(e, row.id)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEls[row.id]}
            open={Boolean(anchorEls[row.id])}
            onClose={() => handleMenuClose(row.id)}
          >
            {menuItems}
          </Menu>
        </>
      );
    },
    onPaginationChange: setPagination,
    state: { pagination, sorting }, //pass the pagination state to the table
    rowCount: 0,
    onSortingChange: setSorting,
    paginationDisplayMode: "pages",
  });
  return (
    <>
      {messageOpen && (
        <Box sx={{ m: "12px 0px" }}>
          <Message
            type={messageType}
            message={message}
            setMessageOpen={setMessageOpen}
          />
        </Box>
      )}
      <Box sx={{ background: common.white, border: `1px solid ${common.borderSecondary}`, borderRadius: "12px", minHeight: "650px", marginTop: "10px" }}>
        {courseInfo?.data.authors.filter((authors) => authors.authorRole !== AuthorRole.AUTHOR).length > 0 ?
          <MaterialReactTable table={table} />
          : <Box sx={{ display: "flex", flexDirection: "column", margin: "0 auto", marginTop: "250px", width: "435px", height: "175px", alignItems: "center", gap: 2 }}>
            <ManageAccountsOutlinedIcon sx={{ width: "52px", height: "52px", color: "#6D737A" }} />
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>{t("course:coAuthors.ADD_AND_MANAGING_CO_AUTHORS")}</Typography>
              <Typography sx={{ fontSize: "14px", color: "" }}>{t("course:coAuthors.ASSIGN_CO_AUTHORS_TO_EDIT_COURSE")}</Typography>
            </Box>
          </Box>}
      </Box>
    </>
  )
}
