import React, { useMemo } from "react";
import s from "./SearchSuitable.module.scss";
import { useQuery } from "@tanstack/react-query";
import { dictionaryControllerApi, homeControllerApi } from "@services/index";
import { DictionaryCode } from "@interfaces/index";
import { useSpring, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CategoryItem } from "@pages/home/SearchSuitable/CategoryItem/CategoryItem";

const SearchSuitable = () => {
  const { t } = useTranslation(["home"]);
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const styles = useSpring({
    opacity: inView ? 1 : 0.7,
    transform: inView
      ? "translateX(0) scale(1)"
      : "translateX(-2000px) scale(0.7)",
    config: {
      tension: 500,
      friction: 50,
    },
  });

  const { data: topic } = useQuery({
    queryKey: ["findPopularTopics"],
    queryFn: () => homeControllerApi.findPopularTopics(),
  });
  const { data: category } = useQuery({
    queryKey: ["getDictionaryListByCodePublic"],
    queryFn: () =>
      dictionaryControllerApi.getDictionaryListByCodePublic(
        DictionaryCode.CATEGORY
      ),
  });
  const filteredCategory = useMemo(() => {
    if (!topic || !category) return []
    return topic?.map(topicItem => {
      const categoryItem = category?.find(cat => cat.id === topicItem.categoryId)
      return {
        ...topicItem,
        ...categoryItem
      }
    })
  }, [category, topic])

  if(!topic || !category) return null

  return (
    <div ref={ref} className={s.container}>
      <div className={s.title}>
        {t("home:FIND_COURSE")}
      </div>
      <div className={s.wrapper}>
        {
          filteredCategory?.length > 0 
          &&
          filteredCategory.map(category => (
            <CategoryItem category={category}/>
          ))
        }
      </div>
    </div>
  );
};

export default SearchSuitable;
