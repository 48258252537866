import { ResponseFilter } from "@interfaces/filter";
import { DeleteResponse } from "@interfaces/common";
import { ResponseList } from "@interfaces/responseList";
import { IEventCalendar, IEventCalendarFilter } from "@interfaces/eventCalendar";
import API from "./api";

export class EventCalendarControllerApi extends API {
  private readonly url = '/course/calendar/events';

  getEventCalendarList(req: ResponseFilter<IEventCalendarFilter>): Promise<ResponseList<IEventCalendar>> {
    return this.post(`${this.url}/findAll`, req);
  }
  createEventCalendar(body: IEventCalendar): Promise<IEventCalendar> {
    return this.post(`${this.url}/create`, body)
  }
  updateEventCalendar(body: IEventCalendar): Promise<IEventCalendar> {
    return this.put(`${this.url}/update`, body)
  }
  deleteEventCalendar(id: string): Promise<DeleteResponse> {
    return this.delete(`${this.url}/delete/${id}`)
  }
}