import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useTranslation } from "react-i18next"

import { serviceTask } from '@services/task';
import useDeleteConfirmModal from "@hooks/useDeleteConfirmModal";
import CustomSearch from '@ui/Search';
import CustomButton from '@ui/Button';
import AddGroupModal from './AddGroupModal';
import { AuthorRole, CourseStatus, ITaskGroup } from '@interfaces/index';
import EditAllRatioModal from './EditAllRatio';

interface Props {
  courseId: string;
  courseData: any;
}

const GroupList = ({ courseId, courseData }: Props) => {
  const { t } = useTranslation(["common", "course", "enum"]);
  const { common } = useTheme().palette;
  const [taskGroupList, setTaskGroupList] = useState<Array<ITaskGroup>>([]);
  const [taskGroupId, setTaskGroupId] = useState<string | null>(null);
  const [addGroupModalOpen, setAddGroupModalOpen] = useState<boolean>(false);
  const [editAllRatioModalOpen, setEditAllRatioModalOpen] = useState<boolean>(false);
  const [gradingSystemId, setGradingSystemId] = useState<number | null>(null);
  const [groupPercentage, setGroupPercentage] = useState<number>(0);
  const [showTaskType, setShowTaskType] = useState<boolean>(false);
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const isCoAuthor = courseData?.authors.filter((author) => author.authorRole === AuthorRole.COAUTHOR).some((author) => author.userId === courseData?.userId)
  const isClosed = courseData?.status === CourseStatus.CLOSED;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:1440px)');
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (courseData?.gradingSystemId) {
      const id = courseData.gradingSystemId;
      setGradingSystemId(id);
      if (id === 3) setShowTaskType(true);
    }
  }, [courseData?.gradingSystemId]);

  const handleToggleAddGroupModal = (modalState: boolean, id?: string | null) => {
    if (id) setTaskGroupId(id);
    else setTaskGroupId(null);
    setAddGroupModalOpen(modalState);
  }

  const handleToggleEditAllRatioModal = (modalState: boolean) => setEditAllRatioModalOpen(modalState);

  useEffect(() => {
    serviceTask.getTaskGroupList(courseId)
      .then(res => {
        if (res.status === 200) {
          setTaskGroupList(res.data);
          let percentage = 0;
          res.data.forEach((group: ITaskGroup) => {
            percentage += Number(group.percentage);
          });
          setGroupPercentage(percentage);
        }
      })
  }, [courseId]);

  const createUpdateGroup = (group: ITaskGroup) => {
    if (!group.id) {
      serviceTask.createTaskGroup(group, courseId).then(res => {
        if (res.status === 200) {
          group.id = res.data;
          let percentage = groupPercentage + Number(group.percentage);
          setGroupPercentage(percentage)
          setTaskGroupList([...taskGroupList, group]);
          handleToggleAddGroupModal(false);
        }
      })
    } else {
      serviceTask.updateTaskGroup(group, courseId).then(res => {
        if (res.status === 200) {
          const list = [...taskGroupList].filter((x) => x.id !== group.id);
          let percentage = 0;
          list.forEach((group: ITaskGroup) => {
            percentage += Number(group.percentage);
          });
          percentage += Number(group.percentage);
          setGroupPercentage(percentage)
          const index = [...taskGroupList].findIndex((x) => x.id === group.id);
          setTaskGroupList([
            ...taskGroupList.slice(0, index),
            group,
            ...taskGroupList.slice(index + 1),
          ]);
          handleToggleAddGroupModal(false);
        }
      }).catch((err) => {
        if (err.response.data.code === 'OVERALL_PERCENTAGE_ERROR') {
          setMessage(t('course:messages.OVERALL_PERCENTAGE_MUST_BE_100'))
          setMessageOpen(true)
          setTimeout(() => {
            setMessageOpen(false)
          }, 5000)
        }
      })
    }
  }

  const deleteGroup = async (id: string) => {
    const res = await serviceTask.deleteTaskGroup(id || '', courseId);
    if (res.status === 200) {
      const deletedGroupPercentage = taskGroupList.find(group => group.id === id)?.percentage;
      let percentage = groupPercentage - Number(deletedGroupPercentage);
      setGroupPercentage(percentage);
      setTaskGroupList([...taskGroupList].filter((group) => group.id !== id));
    }
  }

  const { openDeleteConfirmModal, DeleteModal } = useDeleteConfirmModal({
    deleteFunc: (groupId) => deleteGroup(groupId),
    modalTitle: t('DELETE_ITEM_CONFIRMATION_TEXT'),
  });

  return (
    <>
      <Grid container spacing={2} sx={{ px: 3 }}>
        <Grid item lg={10.5} xs={12}>
          <CustomSearch
            borderRadius='4px'
            placeholder={t("placeholders.SEARCH")}
          // onChange={searchByName}
          />
        </Grid>
        <Grid item lg={1.5} xs={12} md={4}>
          <CustomButton
            backgroundColor={common.primaryColor}
            fontColor={common.fontWhite}
            borderRadius="6px"
            height="38px"
            onClick={() => handleToggleAddGroupModal(true)}
            disabled={groupPercentage >= 100 || !(isAuthor || isCoAuthor) || isClosed}
          >
            {t("actions.ADD")}
          </CustomButton>
        </Grid>
      </Grid>
      <Box>
        {taskGroupList.length > 0 ? (
          <Box sx={{ mt: '22px', width: '100%', display: 'table', tableLayout: 'fixed', borderTop: `1px solid ${common.borderSecondary}` }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '80px' }}>
                      №
                    </TableCell>
                    <TableCell style={{ width: isSmallScreen ? '80px' : isMediumScreen ? '200px' : '500px' }}>
                      {t("course:task.GROUP")}
                    </TableCell>
                    <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                      {t("course:task.%RATIO")}
                      {taskGroupList.length > 1 && !isClosed &&
                        <EditOutlinedIcon
                          sx={{ ml: 1, cursor: 'pointer', color: common.primaryColor }}
                          onClick={() => handleToggleEditAllRatioModal(true)}
                        />
                      }
                    </TableCell>
                    {showTaskType &&
                      <TableCell>
                        {t("course:task.TYPE_OF_TASK")}
                      </TableCell>
                    }
                    {(courseData?.status === CourseStatus.SAVED || courseData?.status === CourseStatus.WITHDRAWN) &&
                      <TableCell></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {taskGroupList.map((taskGroup: ITaskGroup, index: number) => {
                    return (
                      <TableRow key={taskGroup.id}>
                        <TableCell>
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{ "&:hover": { color: common.primaryColor }, cursor: 'pointer' }}
                          onClick={() => handleToggleAddGroupModal(true, taskGroup.id)}
                        >
                          {taskGroup.name}
                        </TableCell>
                        <TableCell>{taskGroup.percentage}</TableCell>
                        {showTaskType &&
                          <TableCell>{taskGroup.taskType ? t(`enum:${taskGroup.taskType}`) : ''}</TableCell>
                        }
                        {(courseData?.status === CourseStatus.SAVED || courseData?.status === CourseStatus.WITHDRAWN) &&
                          <TableCell>
                            {!isClosed && (isAuthor || isCoAuthor) &&
                              <DeleteOutlineIcon
                                sx={{ cursor: "pointer", color: common.errorColor }}
                                onClick={() => { if (taskGroup.id) openDeleteConfirmModal(taskGroup.id) }}
                              />
                            }
                          </TableCell>
                        }
                      </TableRow>
                    )
                  }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Avatar
                alt="CourseList-default-bg-img"
                src={require(`@assets/images/emptyDataBg.png`)}
                variant="square"
                sx={{
                  height: { xs: "200px", sm: "321px" },
                  width: { xs: "200px", sm: "321px" },
                  mb: "8px",
                }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  width: { xs: "200px", sm: "297px" },
                  height: "62px",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "120%",
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: 'center',
                  mb: '100px'
                }}
              >
                {t("course:task.MAIN_TEXT")}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      {addGroupModalOpen &&
        <AddGroupModal
          modalOpen={addGroupModalOpen}
          handleToggleModal={handleToggleAddGroupModal}
          id={taskGroupId}
          createUpdateGroup={createUpdateGroup}
          gradingSystemId={gradingSystemId}
          groupPercentage={groupPercentage}
          setMessage={setMessage}
          setMessageOpen={setMessageOpen}
          message={message}
          messageOpen={messageOpen}
        />
      }
      {editAllRatioModalOpen &&
        <EditAllRatioModal
          modalOpen={editAllRatioModalOpen}
          handleToggleModal={handleToggleEditAllRatioModal}
          groups={taskGroupList}
          updateTaskGroupList={setTaskGroupList}
        />
      }
      {DeleteModal}
    </>
  )
}

export default GroupList;