import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingSpinner from '@components/LoadingSpinner';
import { ICourse, CourseStatus, AdditionalMaterialTypes } from '@interfaces/index';
import { serviceCourse } from '@services/course';
import { courseInitialDto, courseSettingInitialDto } from '../models';
import PublishedCourse from './PublishedCourse';
import SavedCourse from './SavedCourse';
import { FlowInfoProvider } from './FlowInfoProvider';

export const courseCoverColorList = ["#CBFFDB", "#D5CCE4", "#E4CCCC", "#DFF0FF", "#A4D3FF", "#F9D782"];

const CourseInfo = () => {
  const { courseId } = useParams();
  const [courseInfo, setCourseInfo] = useState<ICourse>(courseInitialDto);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (courseId) {
      serviceCourse.getCourseInfo(courseId).then((res) => {
        if (res.status === 200) {
          setCourseInfo({
            ...courseInitialDto,
            ...res.data,
            coverColor: res.data?.coverColor ? res.data.coverColor : courseCoverColorList[Math.floor(Math.random() * courseCoverColorList.length)],
            promoVideoType: res.data?.promoVideoUrl ? AdditionalMaterialTypes.LINK : AdditionalMaterialTypes.FILE,
            courseSetting: {
              ...courseSettingInitialDto,
              ...res.data.courseSetting,
              accountAttendance: res.data.courseSetting.accountAttendance === null ? courseSettingInitialDto.accountAttendance : res.data.courseSetting.accountAttendance,
              accountAttendanceInControlWork: res.data.courseSetting.accountAttendanceInControlWork === null ? courseSettingInitialDto.accountAttendanceInControlWork : res.data.courseSetting.accountAttendanceInControlWork,
              availablePassesPerCourse: res.data.courseSetting.availablePassesPerCourse === null ? courseSettingInitialDto.availablePassesPerCourse : res.data.courseSetting.availablePassesPerCourse
            }
          });
        }
        setIsLoading(false)
      });
    }
  }, [courseId]);

  if (isLoading) {
    <LoadingSpinner />
  }
  const isClosedAndPublished = courseInfo?.status === CourseStatus.CLOSED && courseInfo?.published;

  return (
    <FlowInfoProvider>
      {courseInfo?.id && (courseInfo?.status === CourseStatus.PUBLISHED || isClosedAndPublished) ? (
        <PublishedCourse
          courseId={courseInfo.id}
          courseInfo={courseInfo}
          setCourseInfo={setCourseInfo}
        />
      ) : (
        courseInfo?.id ?
          <SavedCourse
            courseId={courseInfo.id}
            courseInfo={courseInfo}
            setCourseInfo={setCourseInfo}
          /> : null
      )}
    </FlowInfoProvider>
  );
}

export default CourseInfo;