import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import Box from "@mui/material/Box";
import { AlertColor } from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";

import { serviceCourse } from "@services/course";
import { CourseContext } from "@context/CourseContext";
import UnhiddenHints from "@components/UnhiddenHints";
import Message from "@components/Message";
import { Avatar, Typography } from "@mui/material";
import CustomButton from "@ui/Button";
import AddFlowModal from "./AddFlowModal";
import List from "./List";
import { AuthorRole, CourseStatus, DurationType, IStudyClass } from "@interfaces/index";
import AddFlowForm from "./AddFlowForm";

const FlowList = () => {
  const { t } = useTranslation(["common", "dictionary", "enum", "course", "generalSetting"]);
  const { courseId } = useParams();
  const { common } = useTheme().palette;
  const { courseData } = useContext(CourseContext);
  const [flowList, setFlowList] = useState<Array<IStudyClass>>([]);
  const [addFlowModalOpen, setAddFlowModalOpen] = useState(false);
  const [flowId, setFlowId] = useState<string | undefined | null>();
  const [message, setMessage] = useState<string>("");
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const isAuthor = courseData?.userId === courseData?.createdBy;
  const isCoAuthor = courseData?.authors.filter((author) => author.authorRole === AuthorRole.COAUTHOR).some((author) => author.userId === courseData?.userId)

  useEffect(() => {
    getAllFlows();
    // eslint-disable-next-line 
  }, []);

  function getAllFlows() {
    if (courseId) {
      serviceCourse.getAllStudyClassesByCourseId(courseId)
        .then((res) => {
          if (res.status === 200) setFlowList(res.data);
        });
    }
  }

  const handleToggleFlowModal = (modalState: boolean, message?: string, currFlowId?: string | null) => {
    setFlowId(currFlowId);
    setAddFlowModalOpen(modalState)
    if (message) {
      setMessage(t(message))
      setMessageOpen(true)
      setMessageType('success')
      setTimeout(() => {
        setMessageOpen(false)
      }, 3000)
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <UnhiddenHints text={t('course:hints.FLOWS_PAGE_HINT')} />
      {flowList?.length >= 10 && courseData?.status !== CourseStatus.CLOSED &&
        <UnhiddenHints text={t('course:hints.FLOW_COUNT_LIMIT_HINT')} />
      }
      {flowList?.length ? (
        <>
          {(courseData?.status !== CourseStatus.CLOSED && courseData?.durationType !== DurationType.INDEFINITE) &&
            <Box display="flex" justifyContent="end">
              <CustomButton
                width="150px"
                backgroundColor={common.primaryColor}
                borderRadius="6px"
                fontColor={common.fontWhite}
                disabled={flowList?.length >= 10 || !(isAuthor || isCoAuthor)}
                onClick={() => handleToggleFlowModal(true)}
              >
                {t("common:actions.ADD")}
              </CustomButton>
            </Box>
          }
          <Box
            sx={{
              display: "grid",
              flexDirection: "column",
              gap: 2
            }}
          >
            {messageOpen &&
              <Message
                type={messageType}
                message={message}
                setMessageOpen={setMessageOpen}
              />
            }
            <List
              flowList={flowList}
              setFlowList={setFlowList}
              handleToggleModal={handleToggleFlowModal}
              setMessage={setMessage}
              setMessageOpen={setMessageOpen}
              setMessageType={setMessageType}
            />
          </Box>
        </>
      ) : courseData?.durationType !== DurationType.INDEFINITE ?
        (<Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 3,
            background: common.white,
            p: '56px 16px'
          }}
        >
          <Avatar
            alt="Flows-default-bg-img"
            src={require(`@assets/images/emptyFlowsBg.png`)}
            variant="square"
            sx={{
              height: "200px",
              width: "200px"
            }}
          />
          <Typography sx={{
            fontWeight: 400,
            fontSize: { sm: '16px', xs: '14px' },
            lineHeight: '20px'
          }}>
            {t('course:messages.YOUR_FLOWS_WILL_BE_HERE')}
          </Typography>
          <CustomButton
            backgroundColor={common.primaryColor}
            fontColor={common.fontWhite}
            width="fit-content"
            borderRadius="6px"
            onClick={() => handleToggleFlowModal(true)}
          >
            {t('common:actions.ADD_FLOW')}
          </CustomButton>
        </Box>)
        :
        <Box
          sx={{
            width: "100%",
            p: "24px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            alignItems: "center",
            gap: 2,
            border: `1px solid ${common.borderSecondary}`,
          }}
        >
          <AddFlowForm
            flowId={flowId}
            flowList={flowList}
            getAllFlows={getAllFlows}
            setFlowList={setFlowList}
          />
        </Box>
      }
      {addFlowModalOpen &&
        <AddFlowModal
          flowId={flowId}
          modalOpen={addFlowModalOpen}
          handleToggleModal={handleToggleFlowModal}
          getAllFlows={getAllFlows}
          flowList={flowList}
          setFlowList={setFlowList}
        />
      }
    </Box>
  );
}

export default FlowList;