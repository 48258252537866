import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Select, Box, FormControl, MenuItem, Typography, useTheme } from "@mui/material";
import { Formik, Form as FormikForm } from "formik";
import Clear from "@mui/icons-material/Clear";
import { AlertColor } from '@mui/material/Alert';
import { useTranslation } from "react-i18next";

import { serviceDict } from "@services/dictionary";
import CustomButton from "@ui/Button";
import FormikInput from "@ui/formik/Input";
import FormikCheckbox from "@ui/formik/Checkbox";
import FormikAutocomplete from "@ui/formik/Autocomplete";
import { DictionaryCode } from "@interfaces/index";
import { initialDto, ICategory } from "./models";
import { categoryValidationSchema, subcategoryValidationSchema } from "./validation";
import CropImageUpload from "./CropImageUpload";

interface Props {
  categoryId: string | number | null;
  setMessage: (message: string) => void;
  setMessageOpen: (open: boolean) => void;
  setMessageType: (messageType: AlertColor) => void;
  typeInForm: string;
  setTypeInForm: (type: string) => void;
  dropdownCategorylist: ICategory[];
  setDrawerOpen: (openState: boolean) => void;
  setValidate: Dispatch<SetStateAction<number>>
}

const Form = ({ categoryId, setMessage, setMessageOpen, setMessageType, typeInForm, setTypeInForm, dropdownCategorylist, setDrawerOpen ,setValidate}: Props) => {
  const { common } = useTheme().palette;
  const { t } = useTranslation(["dictionary", "common", "enum"]);
  const categoryDropdownList = dropdownCategorylist.filter((category) => category.parent === null);
  const [initialData, setInitialData] = useState<ICategory>(initialDto);
  const [currentParentId, setCurrentParentId] = useState<string | number | null>(null);

  const style = {
    '& label.Mui-focused': { color: common.primaryColor },
    '& .MuiInput-underline:after': { borderBottomColor: common.primaryColor },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': { borderColor: common.primaryColor },
      '&.Mui-focused fieldset': { borderColor: common.primaryColor }
    }
  };

  useEffect(() => {
    if (categoryId) {
      serviceDict.getDictionary(DictionaryCode.CATEGORY, categoryId).then((res) => {
        if (res.status === 200) {
          setInitialData(res.data);
          if (res.data.isStandard) {
            setCurrentParentId(2) // id категории 'Разработка', если значение стандартное, то оно принадлежит категории 'Разработка'
          } else {
            setCurrentParentId(res.data.parentId);
          }
        }
      });
    }
  }, [categoryId]);

  return (
    <Box sx={{ p: '48px' }}>
      <Formik
        initialValues={initialData}
        enableReinitialize
        validationSchema={typeInForm === 'subcategory' ? subcategoryValidationSchema(t) : categoryValidationSchema(t)}
        onSubmit={(values: ICategory, { setSubmitting }: any) => {
          serviceDict.createDictionary(DictionaryCode.CATEGORY, values).then((res) => {
            setSubmitting(false);
            if (res.status === 200) {
              setMessageOpen(true);
              setMessageType("success");
              setMessage(
                !categoryId
                  ? `${t("common:messages.RECORD_ADDED")}`
                  : `${t("common:messages.CHANGES_SAVED")}`
              );
              setTimeout(() => {
                setMessageOpen(false);
              }, 3000)
            }
          }).catch((err) => {
            if (err.response.status === 400) {
              setMessageOpen(true);
              setMessageType("error");
              err.response.data.message === "ITEM_IS_EXISTS" ?
                setMessage(t("ITEM_IS_EXISTS")) : setMessage(t("common:messages.ERROR"));
              setTimeout(() => {
                setMessageOpen(false);
              }, 10000)
            }
          }).finally(() => {
            setDrawerOpen(false);
            setValidate((p)=>p+1)
          });
        }}
      >
        {({ values, submitForm, isValid, dirty, setFieldValue }) => {
          return (
            <FormikForm>
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: '24px' }}>
                <Typography sx={{ fontSize: 28, paddingBottom: 3 }}>
                  {typeInForm === 'category' ? !categoryId ? t('ADD_CATEGORY') : t('EDIT_CATEGORY') : !initialData.parentId ? t('ADD_SUBCATEGORY') : t('EDIT_SUBCATEGORY')}
                </Typography>
                <Clear sx={{ color: common.errorColor, cursor: "pointer" }} onClick={() => setDrawerOpen(false)} />
              </Box>
              <Box>
                {!categoryId &&
                  <Box sx={{ mb: '12px' }}>
                    <FormControl sx={{ ...style, width: "100%", mr: '8px' }}>
                      <Typography sx={{ mb: '4px' }}>{t("TYPE")}</Typography>
                      <Select value={typeInForm} onChange={(e) => setTypeInForm(e.target.value)} color="primary" size="small">
                        <MenuItem value="category">{t("CATEGORY")}</MenuItem>
                        <MenuItem value="subcategory">{t("SUBCATEGORY")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                }
                <Box sx={{ mb: '12px' }}><FormikInput label={t("NAME_IN_KAZAKH")} name='nameKz' disabled={values.isStandard} isRequired /></Box>
                <Box sx={{ mb: '12px' }}><FormikInput label={t("NAME_IN_RUSSIAN")} name='nameRu' disabled={values.isStandard} isRequired /></Box>
                <Box sx={{ mb: '12px' }}><FormikInput label={t("NAME_IN_ENGLISH")} name='nameEn' disabled={values.isStandard} isRequired /></Box>
                {
                  (typeInForm === 'subcategory' || initialData.parentId) &&
                  <Box sx={{ mt: '12px' }}>
                    <FormikAutocomplete
                      name="parent"
                      options={categoryDropdownList}
                      valueAsObject
                      isRequired
                      label={t("BELONGS_TO_CATEGORY")}
                      onChange={(newValue: any) => {
                        setCurrentParentId(newValue?.id)
                      }}
                      disabled={values.isStandard}
                    />
                  </Box>
                }
              </Box>
              <CropImageUpload
                title={t('course:DRAG_IMAGE_HERE')}
                fileTypeCode="006"
                acceptedExtensions=".jpg, .jpeg, .png"
                handleFileAfterUpload={(fileId: string | null) => setFieldValue('fileId', fileId)}
                bgColor={"white"}
                height={255}
                imgId={values.fileId}
              />
              <Box sx={{ mt: '12px' }}>
                <FormikCheckbox name='isDisabled' label={t("enum:NOT_USED")} labelPlacement='end' />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "end", mt: '12px' }}>
                <CustomButton
                  width="150px"
                  backgroundColor={common.primaryColor}
                  borderRadius="6px"
                  fontColor={common.fontWhite}
                  sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '130%' }}
                  disabled={!(isValid && dirty)}
                  onClick={submitForm}
                >
                  {!categoryId ? `${t("common:actions.ADD")}` : `${t("common:actions.SAVE")}`}
                </CustomButton>
              </Box>
            </FormikForm>
          );
        }}
      </Formik>
    </Box>
  );
};

export default Form;