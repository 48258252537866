import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { serviceFile } from "@services/file"
import { getBase64 } from "@utils/functions"
import cls from "./CategoryItem.module.scss"

export interface ICategory {
  id: number
  nameEn: string
  nameRu: string
  nameKz: string
  coverColor: string
  fileId: string
  sum: number
}

interface CategoryItemProps {
  category: any
}

export const CategoryItem: FC<CategoryItemProps> = ({ category }) => {
    const { t, i18n } = useTranslation(["home"]);
    const [fileUrl, setFileUrl] = useState<string>("");

    useEffect(() => {
        if (category.fileId) {
          serviceFile.getFileMimeTypePublic(category.fileId).then((res) => {
            const contentDisposition = res.headers["content-disposition"];
            const fileName = contentDisposition
              .split(";")[1]
              .split("filename")[1]
              .split("=")[1]
              .trim();
            const file = new File([res.data], fileName);
            getBase64(file, (url) => setFileUrl(url));
          });
        }
      }, [category.fileId]);

    return (
      <div className={cls.parent}>
        <div 
          className={cls.big}
          style={{
            backgroundColor: category?.coverColor,
            backgroundImage: `linear-gradient(182deg, rgba(0, 0, 0, 0.60) 15.99%, rgba(0, 0, 0, 0.00) 110.99%), url(${fileUrl})`,
          }}
        >
        </div>
        <div className={cls.content_text}>
          <div className={cls.head}>{i18n.language === 'ru' ? category.nameRu : i18n.language === 'kz' ? category.nameKz : category.nameEn}</div>
          <div className={cls.text}>{t('home:COURSES')} - {category.sum}</div>
        </div>
      </div>
    )
}
