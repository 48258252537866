import React from 'react'
import { Box, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { serviceReports } from '@services/reports';
import AvatarPicture from '@components/AvatarPicture';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import { useTranslation } from 'react-i18next';

export const CourseList = ({ filter }) => {
  const { common } = useTheme().palette;
  const navigate = useNavigate();
  const { t } = useTranslation(["course", "dictionary", "enum", "user", "common", "aero"]);
  const { data: topCourseList } = useQuery({
    queryKey: ["topCourseList", filter],
    queryFn: () =>
      serviceReports.getTopCourseList(filter),
  });

  return (
    <Box sx={{ background: common.white, border: `1px solid ${common.borderSecondary}`, borderRadius: "12px", height: "460px", minWidth: "460px" }}>
      <Box margin="20px 16px" display="flex" justifyContent="space-between">
        <Box>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>{t("course:REPORT_COURSE")}</Typography>
        </Box>
        <Box>
          {topCourseList?.data?.length > 0 &&
            <Box sx={{
              padding: "5px 12px", width: "130px", "&:hover": {
                background: "#F5F5F5",
                borderRadius: "12px"
              }
            }}>
              <Typography sx={{ color: common.primaryColor, cursor: "pointer" }} onClick={() => navigate('/reports/course')}>{t("course:ALL_LIST")}</Typography>
            </Box>}
        </Box>
      </Box>
      <Divider sx={{ margin: "16px 0px" }} />
      {topCourseList?.data?.length > 0 ? <Table>
        <TableHead>
          <TableRow >
            <Grid container>
              <Grid item xs={9.5}>
                <TableCell sx={{ fontSize: 14, color: "#6D737A", borderBottom: "none" }}>{t("aero:COURSE_NAME")}</TableCell>
              </Grid>
              <Grid item xs={2.5}>
                <TableCell sx={{ fontSize: 14, color: "#6D737A", borderBottom: "none" }}>{t("enum:LISTENERS")}</TableCell>
              </Grid>
            </Grid>
          </TableRow>
        </TableHead>
        <TableBody>
          {topCourseList?.data?.map((course) => {
            return (
              <TableRow key={course.id}>
                <Grid container>
                  <Grid item xs={9.5}>
                    <TableCell sx={{ fontSize: 16, borderBottom: "none" }} size="small">
                      <Box display="flex" gap={1} alignItems="center" mb="8px">
                        {course.coverFileId ? (
                          <AvatarPicture
                            id={course.coverFileId}
                            sxProps={{
                              width: "40px",
                              height: "30px",
                              borderRadius: "2px",
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              backgroundColor: course.coverColor,
                              width: "40px",
                              height: "30px",
                              borderRadius: "2px",
                              flexShrink: 0,
                            }}
                          />
                        )}{course.name.length > 50 ? course.name.substring(0, 50) + " ..." : course.name}
                      </Box></TableCell>
                  </Grid>
                  <Grid item xs={2.5}>
                    <TableCell sx={{ fontSize: 16, borderBottom: "none" }} size="small">{course.listenersCount}</TableCell>
                  </Grid>
                </Grid>
              </TableRow>
            )
          })}
        </TableBody>
      </Table> :
        <Box margin="18% 35%">
          <Box sx={{ background: "#F9FAFB", padding: "8px 12px", borderRadius: "50px", width: "80px", height: "80px", marginLeft: "45px", border: "1px solid #E8E8E8" }} alignItems="center">
            <DataSaverOffOutlinedIcon sx={{ mr: 0.8, color: "#6D737A", margin: "20px 15px" }} />
          </Box>
          <Typography sx={{ color: "#6D737A", marginTop: "16px" }}>{t("course:NO_DATA_AVAILABLE")}</Typography>
        </Box>
      }
    </Box >
  )
}
