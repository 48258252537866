import { ICourse, ICourseSetting } from "interfaces";

export const courseSettingInitialDto: ICourseSetting = {
  branchId: null,
  canExecuteTaskAfterExpired: false,
  canRegWithPromoCode: false,
  canSelfRegistration: false,
  canStudentViewAfterEnd: false,
  canStudentViewBeforeStart: false,
  canTutorEdit: false,
  capacity: null,
  contentAccessExpiration: null,
  gradingAccuracy: 0,
  gradingSystemId: null,
  id: null,
  isProvidedCertificate: false,
  isProvidedRegPeriod: false,
  allowEstimateAfterCompletion: false,
  isPublic: false,
  isSendMailAfterSuccessReg: false,
  isSequentialTopicStudy: true,
  langId: null,
  maxFileSizeCanUploadStudent: 200,
  regStartDate: null,
  regEndDate: null,
  scheduleId: null,
  sendMailAfterSuccessRegText: null,
  startDate: null,
  endDate: null,
  targetAudiences: [],
  subdivisionIds: [],
  eduProgramIds: [],
  zeroMarkForIncompleteTasks: true,
  markForIncompleteTask: null,
  finalGradeDisplayMethod: "AUTOMATIC",
  registrationClosed: false,
  formatType: "SYNC",
  durationType: "PERIOD",
  completedTasksRate: null,
  minLimitForTask: 80,
  hours: null,
  credits: null,
  certificateDisplay: null,
  accountAttendance: true,
  accountAttendanceInControlWork: true,
  availablePassesPerCourse: 80,
};

export const courseInitialDto: ICourse = {
  id: null,
  name: null,
  shortDescription: null,
  description: null,
  typeId: null,
  typeCode: null,
  credits: null,
  promoVideoType: null,
  promoVideoUrl: null,
  promoVideoId: null,
  promoVideoName: null,
  countRegWithPromoCode: null,
  status: null,
  authors: null,
  authorInfo: null,
  tutorInfo: null,
  coverFileId: null,
  coverColor: "#CBFFDB",
  createdAt: null,
  createdBy: null,
  updatedAt: null,
  courseMembers: [],
  groups: [],
  courseSetting: courseSettingInitialDto,
  externalId: null,
  studentCount: null,
  allStudentCount: null,
  activeStudentCount: null,
  published: false,
};
